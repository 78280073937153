.breadcrumb {
  font-size: 1rem;
  font-family: $font-family-monospace;
  background-color: $transparent;
  padding: 0 0 $breadcrumb-padding-y $breadcrumb-padding-x;

  >li {
    +li::before {
      padding-right: .6rem;
      padding-left: .6rem;
    }
  }

  .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
  }
}

/* Custom scss for page headers style */
.breadcrumbs-right {
  .breadcrumb {
    float: right;
  }
}

.breadcrumbs-top {
  .breadcrumb {
    margin: 0;
    padding: 0;
  }
}

.breadcrumb-new {
  .content-header-title {
    padding: 6px 24px 8px 0;
    margin: 0 24px 0 0;
    border-right: 1px solid $border-color;
  }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .breadcrumb {
    min-height: auto;
  }
}

.breadcrumb-item a {
  color: #28242b;
}

.breadcrumb-item a:hover {
  color: #28242b;
}