@media (min-width: 2500px) {
  .container-xxl {
    max-width: 1890px !important;
  }
}

@media (min-width: 2000px) {
  .container-xxl {
    max-width: 1770px !important;
  }
}

@media (min-width: 1921px) {
  .container-xxl {
    max-width: 1615px;
  }



  .container-fluid {
    &.navbar-wrapper {
      padding: 0 5%;
    }
  }

  .p-dataview {
    .p-dataview-content {
      .product-item-container {
        .project-details-link {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 2660px) {
  .first-step-button .cancel-btn {
    margin-left: 14px;
  }
}

@media (max-width: 1920px) {
  .sub-title {
    .columlet {
      .columlet-btn {
        h6 {
          margin-bottom: 0;

          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  /* .product-item-container {
    flex: 0 0 33.33%;
    max-width: 33.33% !important;
  } */
  .p-grid {
    .col-lg-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
  }
}

@media(max-width : 1760px) {
  // .container-xxl {
  //   max-width: 1130px;
  // }

  .p-dataview .p-dataview-header .page-title.title span.p-input-icon-left input[type=search] {
    width: 450px;
  }

  .btn {
    padding: 0.75rem 1.1rem;
  }
}

@media (max-width: 1600px) {
  .sub-title {
    .checkedClass {
      form {
        padding: 0px 37px 46px;
      }
    }
  }

  .container-xxl {
    max-width: 1170px;
    margin-top: 20px;
  }

  .model-hub {
    .model-card {
      padding: 20px 20px 28px;
      width: 100%;

      h4 {
        &.header {
          font-size: 18px;
        }
      }

      .model-info {

        p,
        h4 {
          width: 100%;
        }
      }
    }


  }

  // .model-hub .model-card h4.header {
  //   font-size: 18px;
  // }

  // .model-hub .model-card .model-info p {
  //   display: inline-block;
  //   width: 100%;
  // }

  // .model-hub .model-card .model-info h4 {
  //   display: inline-block;
  //   width: 100%;
  // }

  // .model-hub .model-card {
  //   width: 100%;
  // }

  .model-card {
    .model-info {
      h4 {
        width: 50%;
      }

      p {
        width: 50%;
      }
    }
  }

  .sample-data {
    .sampleLength {
      display: flex;
      width: 600px;
      overflow-x: auto;
    }
  }
}

@media (max-width: 1530px) {
  .page-main {
    font-size: 16px;
    color: #2c303b;
    margin-bottom: 20px;
    max-width: 1898px;
    min-height: 100%;
    margin: auto;
  }

  // .container-xxl {
  //   max-width: 1000px;
  // }

  body.vertical-layout {
    &.vertical-menu-modern {
      &.menu-expanded {
        .content {
          margin-left: 180px !important;
        }

        .main-menu {
          width: 180px !important;
        }
      }
    }
  }

  .main-menu {
    &.menu-light {
      .navigation {
        li {
          a {
            padding: 4px 10px !important;
          }
        }
      }
    }
  }

  .p-dataview {
    .p-dataview-header {
      .page-title {
        &.title {
          h1 {
            font-size: 24px;
            margin: 0px;
          }
        }
      }
    }

    .p-dataview-content {
      .product-item-container {
        .product-list-item {
          .team-profile {
            span {
              &.team-logo {
                width: 26px;
                height: 26px;
                line-height: 24px;

                &:nth-child(2) {
                  line-height: 24px;
                }

                &:nth-child(3) {
                  line-height: 24px;
                }
              }

              &.remain-team-logo {
                width: 26px;
                height: 26px;
              }
            }

            .add-user-project {
              width: 26px;
              height: 26px;
            }
          }

          .product-list-detail .product-name h2 {
            font-size: 16px;
          }

          .allprojectcard {
            .header-item {
              &.dropdown-toggle {
                padding: 2px 5px;
              }
            }
          }
        }
      }
    }
  }

  .page-main {
    &.pages {
      .page-title {
        h1 {
          font-size: 24px;
        }
      }
    }
  }

  .setup-job .page-content h2 {
    font-size: 18px;
  }

  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        padding: 15px 20px;
      }
    }
  }

  p {
    font-size: 15px !important;
  }

  .app-content {
    .login-box {
      .login {
        max-width: 500px;
        min-width: 450px;
      }
    }
  }

  .app-content .login-box .login .card-content .card-body .login-btn {
    height: 52px;
  }

  .p-dialog-content {
    padding: 10px 20px 60px !important;
  }

  .message-area {
    .message-input-block {
      width: 38rem !important;
    }
  }

  .jobs-dropdown .btns-block {
    width: 38rem !important;
  }

  .suggestion-btns {
    .btns-block {
      width: 38rem !important;
    }
  }

  .chat-msg-block {
    width: 38rem !important;
  }

  .chat-msg-btns {
    .chat-logo-block {
      p {
        font-size: 22px !important;
      }
    }
  }

  .suggestion-btns {
    .btns-block {
      .btn-chat {
        span {
          font-size: 14px !important;
        }

        span+span {
          font-size: 12px !important;
        }
      }
    }
  }

  #frame {
    .chat-logo {
      font-size: 22px !important;
    }
  }

  .custom-chat {
    .customizer-toggle {
      left: -48px;
    }
  }

  .customizer {
    .customizer-toggle {
      left: -48px;
    }
  }
}

@media (max-width: 1440px) {
  .page-main {
    font-size: 16px;
    color: #2c303b;
    margin-bottom: 20px;
    max-width: 1898px;
    min-height: 100%;
    margin: auto;
  }

  .sample-data {
    .sampleLength {
      display: flex;
      width: 315px;
      overflow-x: auto;
    }
  }
}

@media (max-width: 1368px) {
  .page-main {
    font-size: 16px;
    color: #2c303b;
    margin-bottom: 20px;
    max-width: 1898px;
    min-height: 100%;
    margin: auto;

    &.pages {
      padding: 0px 37px;
    }
  }

  .edit-user {
    i {
      margin-left: 10px;
    }
  }

  .p-dataview {
    .p-dataview-header {
      .page-title.title span.p-input-icon-left input[type=search] {
        width: 350px;
        height: 46px;
      }
    }
  }

  .page-main {
    &.pages {
      .personal-details {
        padding: 40px 8px 0px;

        h4 {
          &.label-title {
            display: inline-block !important;
            width: 50% !important;
            margin-bottom: 35px;
          }
        }
      }
    }
  }

  .model-card {
    h4 {
      &.header {
        font-size: 16px;
      }
    }

    .model-info {
      h4 {
        width: 42%;
        font-size: 12px;
      }

      p {
        width: 58%;
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 1280px) {
  .dynamic-form {
    .btn-small {
      margin-left: 18px;
      border-radius: 20px !important;
      width: 96px;
    }
  }

  .p-dataview {
    .p-dataview-header {
      .page-title {
        &.title {
          span {
            &.p-input-icon-left {
              input[type="search"] {
                width: 411px;
              }
            }
          }
        }
      }
    }
  }

  .p-dialog {
    min-width: 1000px;
    min-height: 668px;
    max-width: 1000px;

    .p-dialog-content {
      padding: 10px 100px 60px !important;
    }
  }

  .page-main {
    &.pages {
      padding: 0px 33px;
    }
  }

  .product-item-container {
    flex: 0 0 50%;
    max-width: 50% !important;
  }

  .message-area {
    .message-input-block {
      width: 35rem !important;
    }
  }

  .jobs-dropdown .btns-block {
    width: 35rem !important;
  }

  .suggestion-btns {
    .btns-block {
      width: 35rem !important;
    }
  }

  .chat-msg-block {
    width: 35rem !important;
  }
}

@media (max-width: 1200px) {
  .p-grid {
    .col-lg-4 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .p-dataview .p-dataview-header .page-title.title span.p-input-icon-left input[type=search] {
    width: 275px;
  }

  .setup-job {
    width: 100%;
  }

  .message-area {
    .message-input-block {
      width: 38rem !important;
    }
  }

  .suggestion-btns {
    .btns-block {
      width: 38rem !important;
    }
  }

  .jobs-dropdown .btns-block {
    width: 38rem !important;
  }

  .chat-msg-block {
    width: 38rem !important;
  }

  .page-main {
    &.pages {
      .page-title {
        h1 {
          font-size: 22px;
        }
      }
    }
  }

  .p-dataview {
    .p-dataview-header {
      .page-title {
        &.title {
          h1 {
            font-size: 22px;
          }
        }
      }
    }
  }

  .workflows-page {
    .card-img-top {
      max-height: 130px;
      max-width: 55px;
      margin: 10px 0 0 14px;
      opacity: 0.6;
    }

    .card .solutions-head {
      height: 70px;
    }
  }

  .edit-user i {
    font-size: 18px;
  }

  .page-main.pages .first-step-button {
    width: calc(100% + 29px);
  }

  .liveChat .p-sidebar {
    width: 60vw !important;
  }
}



@media (max-width: 1024px) {

  // .p-grid {
  //   .col-lg-4 {
  //     flex: 0 0 50%;
  //     max-width: 50%;
  //   }
  // }

  .p-dataview {
    .p-dataview-content {
      .product-item-container {
        .job-status {
          padding-right: 0px;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .test-popup {
    width: 100%;
    margin: 0 auto;
  }

  .show-semantic {
    padding: 29px 22px;
  }

  .second-step {
    margin-right: 53px;
  }



  .columlet {
    width: 100%;
  }

  .dependent-field {
    p {
      &.dependent-filed-pills {
        background: rgba(255, 101, 1, 0.1490196078);
        padding: 10px 23px;
        border-radius: 20px;
        display: inline-flex;
        flex-wrap: wrap;
        font-size: 14px !important;
        align-items: center;
      }
    }
  }

  .confirm-job #custom-target {
    width: 100%;
  }

  .p-dialog {
    min-width: 674px;
    min-height: 552px;
    max-width: 1000px;
  }

  .dynamic-form {
    .btn-small {
      margin-left: 18px;
      border-radius: 20px !important;
      width: 85px;
    }
  }

  .page-main {
    &.box {
      .page-title {
        h1 {
          font-size: 26px;
          font-weight: 400;
        }
      }
    }


    .page-data {
      h5 {
        margin-bottom: 20px;
      }

      h6 {
        margin-bottom: 0;
      }
    }
  }

  .bottom-end-btn {
    width: 431px;
    position: fixed;
    bottom: 28px;
    right: 42px;
  }

  .user-title {
    font-size: 24px !important;
    font-weight: 400 !important;
  }
}

@media (max-width: 991px) {
  .user-title h2 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }

  .add-project-popup-data {
    padding: 0 15px 20px;
  }

  .page-main .page-data h2 {
    margin-bottom: 20px;
  }

  .custom-synthesis-content {
    margin-top: 30px;
  }

  .btn-small {
    font-size: 14px !important;
  }

  .btn-warning {
    font-size: 14px;
  }

  .dynamic-form {
    .btn-small {
      margin-left: 18px;
      border-radius: 20px !important;
      width: 96px;
    }
  }

  .p-dataview {
    .p-dataview-header {
      .page-title {
        &.title {
          h1 {
            font-size: 24px;
          }

          span {
            &.p-input-icon-left {
              input[type=search] {
                width: 239px;
                height: 41px;
              }
            }
          }
        }
      }
    }
  }


  .page-main {
    &.box {
      .page-title {
        h1 {
          font-size: 26px;
          font-weight: 400;
        }
      }
    }

  }

  .confirm-job {
    .showdata-box {
      padding: 12px 27px;

      &.sub-title {
        margin-left: 0px;
      }
    }

    .customer {
      ul {
        li {
          padding: 5px 10px;
        }
      }
    }
  }

  .nav-menu {
    display: block;
  }

  .project-list,
  .new-list {
    display: none;
  }

  .btn-small {
    width: 120px;
    font-size: 12px;
  }

  .fields-col {
    .semantic-dependent {
      padding: 8px 26px 15px 7px;
      width: 100%;
    }
  }

  .liveChat .p-sidebar {
    width: 100vw !important;
  }

  // .page-main.pages .project-details-heading .companyLogo {
  //   width: 80px;
  //   height: 80px;
  // }

  .page-main .page-data .team {
    width: 65px;
    height: 65px;

    span {
      font-size: 22px;
    }
  }
}

@media (max-width: 800px) {
  .fields-col {
    .semantic-dependent {
      padding: 25px 26px 15px 8px;
      width: 100%;
    }
  }

  .p-dataview {
    .p-dataview-header {
      .page-title {
        &.title {
          h1 {
            font-size: 24px;
          }

          span {
            &.p-input-icon-left {
              input[type=search] {
                // width: 239px;
                width: 100%;
                height: 41px;

              }
            }

            >i {
              top: 50% !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }

    .p-dataview-content {
      .product-item-container {
        .project-details-link {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    bottom: 40px;
    right: 0px;
  }

  .assign-semantic {
    .customer-add {
      padding: 16px 28px;
    }
  }

  .sample-data {
    .sampleLength {
      display: flex;
      width: 208px;
      overflow-x: auto;
    }
  }

  .sub-title {
    .checkedClass form {
      padding: 0px 18px 46px;
    }
  }

  .dynamic-form {
    .btn-small {
      margin-left: 0px;
      border-radius: 20px !important;
      width: 96px;
    }
  }

  .add-semantic-dependent {
    height: 460px;
  }

  .custom-synthesis-content {
    margin-top: 30px;
  }

  .p-datatable-scrollable {
    .p-datatable-wrapper {
      overflow: scroll !important;
    }
  }

  .confirm-data-title {
    h3 {
      padding-bottom: 26px;
      font-size: 24px;
      color: #0c1d37;
    }

    .report-card {
      h6 {
        font-size: 20px;
        text-transform: capitalize;
      }
    }
  }



  .page-main {
    &.box {
      .page-title {
        h1 {
          font-size: 26px;
          font-weight: 400;
        }
      }
    }

    &.pages {
      padding: 0 10px;
    }
  }

  .btn-small {
    width: 120px;
    font-size: 12px;
  }

  .mob-hide {
    display: none;
  }

  .hide-content-mob {
    display: none;
  }

  .view-content-mob {
    // display: block;
    height: 100vh;
    width: 100%;
    // display: block;
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 768px) {

  .p-grid .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100% !important;
  }

  .setup-job {
    width: 653px;
    // background: #faeee6;
    padding: 40px 68px;
    border-radius: 16px;
    box-shadow: 3px 1px 10px rgba(0, 0, 0, 0.2);
  }

  .add-project-popup-data {
    padding: 0 85px 20px;
  }

  .first-step-button {
    .cancel-btn {
      margin-left: 373px;
    }
  }

  .setup-info {
    padding-left: 0;
    padding-right: 0;
  }

  .save-pattern {
    .btn-secondary {
      border: 1px solid #8c8c8c !important;
      background-color: transparent !important;
      border-radius: 20px;
      font-size: 16px;
      color: #3b4555 !important;
      font-weight: 500;
    }
  }

  .confirm-job {
    .sub-title {
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  .app-content {
    .login-box {
      .login {
        &.register {
          min-height: 750px;
          max-height: 100%;
          max-width: 399px;
          min-width: 424px;

          .card-content {
            padding: 60px 40px;
          }
        }

        .card-content {
          // padding: 60px 129px;

        }
      }
    }
  }

  .page-main {
    &.box {
      .page-title {
        h1 {
          font-size: 26px;
          font-weight: 400;
        }
      }
    }

    &.pages {
      .edit-user {
        i {
          line-height: 2;
          margin-left: 10px;
        }
      }

      .first-step-button {
        width: 653px;
      }
    }
  }

  .user-title {
    h2 {
      font-size: 26px !important;
      font-weight: 400 !important;
    }

    p {
      font-size: 14px !important;
    }
  }

  p {
    font-size: 14px !important;
  }

  form {
    label {
      color: black !important;
      font-size: 14px !important;
    }
  }

  .setup-job {
    .page-content {

      h3,
      h2 {
        font-size: 20px;
        font-weight: 400;
        color: #28242b;
        padding-top: 0px;
      }
    }
  }

  .text-line {
    font-size: 13px;
  }

  .circle {
    margin-top: 0px !important;

    .circle__item {
      width: 109px;
      height: 99px;

      .circle__spinner {
        width: 109px;
        height: 99px;
      }
    }
  }

  .p-datatable-scrollable {
    .p-datatable-wrapper {
      overflow: scroll !important;
    }
  }

  .p-dialog {
    min-width: 100%;
    min-height: 100%;
    max-width: 1000px;

    .p-dialog-content {
      padding: 10px 55px 60px !important;
    }
  }

  .second-step {
    margin-right: 0px;
    margin-top: 10px;
    justify-content: center !important;
  }

  .custom-synthesis-content {
    margin-top: 50px;
  }

  .page-main {
    &.pages {
      .personal-details {
        h4.label-title {
          width: 100% !important;
          margin-bottom: 25px;
        }
      }
    }
  }

  .sm-mob-hide {
    display: none;
  }

  // .hide-content-mob {
  //   display: none;
  // }

  // .view-content-mob {
  //   // display: block;
  //   height: 100vh;
  //   width: 100%;
  //   // display: block;
  //   // text-align: center;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  // }


  // .suggestion-btns .btns-block {
  //     width: 32rem !important;
  // }

  // .message-area .message-input-block {
  //   width: 32rem !important;
  // }

  .message-area {
    .message-input-block {
      width: 32rem !important;
    }
  }

  .suggestion-btns {
    .btns-block {
      width: 32rem !important;
    }
  }

  .jobs-dropdown .btns-block {
    width: 32rem !important;
  }

  .chat-msg-block {
    width: 32rem !important;
  }

}

@media (max-width: 600px) {
  .setup-job {
    width: 100%;
    // background: #faeee6;
    padding: 31px 35px;
    border-radius: 16px;
    box-shadow: 3px 1px 10px rgba(0, 0, 0, 0.2);
  }

  .first-step-button {
    .cancel-btn {
      margin-left: 110px;
    }
  }

  .p-dialog {
    min-width: 100%;
    min-height: 100%;
    max-width: 1000px;
  }

  .add-project-popup {
    width: 100%;
    margin: 0 auto;
  }

  .test-popup {
    width: 100%;
    margin: 0 auto;
  }

  .close-btn {
    position: absolute;
    bottom: 40px;
    right: 0px;
  }

  .page-main {
    &.pages {
      // padding: 95px 33px;
    }
  }
}

@media (max-width: 500px) {
  .btn-small {
    width: 130px;
  }

  .app-content {
    .login-box {
      .login {
        background: white;
        border-radius: 8px;
        max-width: 624px;
        min-width: 394px;
        // min-height: 558px;
        // max-height: 600px;
        width: 100%;

        &.register {
          min-height: 750px;
          max-height: 100%;
          max-width: 399px;
          min-width: 383px;

          .card-content {
            padding: 60px 30px;
          }
        }

        .card-content {
          padding: 60px 64px;

          .card-body {
            .login-btn {
              width: 100%;
              border-radius: 27px !important;
              height: 44px;
              margin: 0 auto;
              font-size: 16px;
              margin-top: 18px;
            }
          }
        }
      }
    }

    .card-title {
      img {
        height: 40px !important;
      }
    }
  }

  .mb-3 {
    margin-bottom: 25px !important;
  }

  .page-main {
    &.pages {
      // padding: 102px 16px;
    }

    .title {
      h5 {
        font-size: 25px;
        padding-top: 5px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 440px) {
  .sample-data {
    .sampleLength {
      display: flex;
      width: 225px;
      overflow-x: auto;
    }
  }

  .edit-profile-popup {
    width: 100%;
    margin: 0 auto;
  }

  .sub-title {
    .checkedClass {
      form {
        padding: 0px 20px 46px;
      }
    }
  }

  .page-main {
    &.pages {
      // padding: 102px 16px;
    }

    .title {
      h5 {
        font-size: 25px;
        padding-top: 5px;
        font-weight: 400;
      }
    }
  }

  .btn-small {
    width: 130px;
  }
}

@media (max-width: 360px) {
  .page-main {
    &.box {
      padding: 82px 5px !important;
    }

    &.pages {
      padding: 102px 16px;
    }

    .title {
      h5 {
        font-size: 25px;
        padding-top: 5px;
        font-weight: 400;
      }
    }
  }

  .btn-small {
    width: 110px;
  }
}

@media (max-width: 320px) {}

// height responsive for the chat loader //

// @media only screen and (max-height: 800px) {
//   #frame {
//     .content-chat {
//       ul {
//         .thread:nth-last-child(1) {
//           margin-bottom: 28vh !important;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-height: 800px) {
  #frame {
    .content-chat {
      ul {
        .thread:nth-last-child(1) {
          // margin-bottom: 28vh !important;
          padding-bottom: 28vh !important;
        }
      }
    }
  }
}

@media only screen and (max-height: 724px) {
  #frame {
    .content-chat {
      ul {
        .thread:nth-last-child(1) {
          // margin-bottom: 28vh !important;
          padding-bottom: 28vh !important;
        }
      }
    }
  }
}

@media only screen and (max-height: 668px) {
  #frame {
    .content-chat {
      ul {
        .thread:nth-last-child(1) {
          // margin-bottom: 30vh !important;
          padding-bottom: 30vh !important;
        }
      }
    }
  }
}

@media only screen and (max-height: 628px) {
  #frame {
    .content-chat {
      ul {
        .thread:nth-last-child(1) {
          // margin-bottom: 32vh !important;
          padding-bottom: 32vh !important;
        }
      }
    }
  }
}

@media only screen and (max-height: 612px) {
  #frame {
    .content-chat {
      ul {
        .thread:nth-last-child(1) {
          // margin-bottom: 34vh !important;
          padding-bottom: 34vh !important;
        }
      }
    }
  }
}