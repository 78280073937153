/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css"; */

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "assets/sass/main.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


:host ::ng-deep .email-fixed-header {
  min-height: calc(100% - 207px) !important;
  margin-top: 0 !important;
  overflow-y: hidden !important;
}

::ng-deep ngb-modal-backdrop {
  z-index: 1050 !important;
}

:host ::ng-deep .ngb-dp-header {
  background-color: var(--light) !important;
}