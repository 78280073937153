div#chat {
    padding: 0 0 30px;
}

.p-sidebar {
    .p-sidebar-header {
        padding: 1.25rem;
        position: absolute;
        z-index: 999;
        right: 0px;
    }

    .p-sidebar-content {
        padding: 0px;
        overflow-x: hidden;
    }
}

#frame {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    background: $white;


    ::-webkit-scrollbar {
        width: 0;
    }

    * {
        scrollbar-width: none;
    }



    @media screen and (max-width: 360px) {
        width: 100%;
        height: 100vh;
    }

    .chat-logo {
        font-weight: 700;
        font-size: 26px;
        color: rgba($color: $black, $alpha: 0.7);
        line-height: 60px;
    }

    .typing-animation {
        overflow: hidden;
        white-space: pre;
        animation: typing 1s steps(10, end);
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    #sidepanel {
        width: 30%;
        height: 100%;
        background: #dd8b47;
        color: $white;
        overflow: hidden;
        position: relative;
        display: inline-block;

        @media screen and (max-width: 735px) {
            width: 58px;
            min-width: 58px;
        }

        #profile {
            width: 80%;
            margin: 5px auto;

            @media screen and (max-width: 735px) {
                width: 100%;
                margin: 0 auto;
                padding: 5px 0 0 0;
                background: #32465a;
            }

            &.expanded {
                .wrap {
                    height: 210px;
                    line-height: initial;

                    p {
                        margin-top: 20px;
                    }

                    i.expand-button {
                        -moz-transform: scaleY(-1);
                        -o-transform: scaleY(-1);
                        -webkit-transform: scaleY(-1);
                        transform: scaleY(-1);
                        filter: FlipH;
                        -ms-filter: "FlipH";
                    }
                }
            }

            .wrap {
                height: 60px;
                line-height: 60px;
                overflow: hidden;
                transition: 0.3s height ease;

                a {
                    color: $white;
                    font-size: 20px;
                    font-weight: 200;
                }

                @media screen and (max-width: 735px) {
                    height: 55px;
                }

                &.expanded {
                    img {
                        width: 40px;
                        margin-left: 0;
                    }
                }

                img.online {
                    border: 2px solid #2ecc71;
                }

                img.away {
                    border: 2px solid #f1c40f;
                }

                img.busy {
                    border: 2px solid #e74c3c;
                }

                img.offline {
                    border: 2px solid #95a5a6;
                }

                p {
                    float: left;
                    margin-left: 15px;

                    @media screen and (max-width: 735px) {
                        display: none;
                    }
                }

                i.expand-button {
                    float: right;
                    margin-top: 23px;
                    font-size: 0.8em;
                    cursor: pointer;
                    color: #435f7a;

                    @media screen and (max-width: 735px) {
                        display: none;
                    }
                }

                #status-options {
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    width: 150px;
                    margin: 70px 0 0 0;
                    border-radius: 6px;
                    z-index: 99;
                    line-height: initial;
                    background: #435f7a;
                    transition: 0.3s all ease;

                    @media screen and (max-width: 735px) {
                        width: 58px;
                        margin-top: 57px;
                    }

                    &.active {
                        opacity: 1;
                        visibility: visible;
                        margin: 75px 0 0 0;

                        @media screen and (max-width: 735px) {
                            margin-top: 62px;
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 8px solid #435f7a;
                        margin: -8px 0 0 24px;

                        @media screen and (max-width: 735px) {
                            margin-left: 23px;
                        }
                    }

                    ul {
                        overflow: hidden;
                        border-radius: 6px;
                    }

                    ul li {
                        padding: 15px 0 30px 18px;
                        display: block;
                        cursor: pointer;

                        @media screen and (max-width: 735px) {
                            padding: 15px 0 35px 22px;
                        }

                        &:hover {
                            background: #496886;
                        }

                        span.status-circle {
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            margin: 5px 0 0 0;

                            @media screen and (max-width: 735px) {
                                width: 14px;
                                height: 14px;
                            }
                        }

                        &.active {
                            span.status-circle:before {
                                border: 1px solid #435f7a;
                            }
                        }

                        span.status-circle:before {
                            content: "";
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            margin: -3px 0 0 -3px;
                            background: transparent;
                            border-radius: 50%;
                            z-index: 0;

                            @media screen and (max-width: 735px) {
                                height: 18px;
                                width: 18px;
                            }
                        }

                        p {
                            padding-left: 12px;

                            @media screen and (max-width: 735px) {
                                display: none;
                            }
                        }
                    }
                }
            }

            #expanded {
                padding: 100px 0 0 0;
                display: block;
                line-height: initial !important;

                label {
                    float: left;
                    clear: both;
                    margin: 0 8px 5px 0;
                    padding: 5px 0;
                }

                input {
                    border: none;
                    margin-bottom: 6px;
                    background: #32465a;
                    border-radius: 3px;
                    color: #f5f5f5;
                    padding: 7px;
                    width: calc(100% - 43px);

                    &:focus {
                        outline: none;
                        background: #435f7a;
                    }
                }
            }
        }


        #contacts {
            height: calc(100% - 177px);
            overflow-y: scroll;
            overflow-x: hidden;

            @media screen and (max-width: 735px) {
                height: calc(100% - 149px);
                overflow-y: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &.expanded {
                height: calc(100% - 334px);
            }

            &::-webkit-scrollbar {
                width: 8px;
                background: #0073ff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #0073ff;
            }

            ul {
                margin: 0;
                padding: 0;

                li.contact {
                    position: relative;
                    padding: 5px 0 5px 0;
                    font-size: 0.9em;
                    cursor: pointer;
                    list-style: none;

                    @media screen and (max-width: 735px) {
                        padding: 6px 0 46px 8px;
                    }

                    &:hover {
                        background: $transparent;
                        color: $black;
                    }

                    &.active {
                        color: $black;

                        span.contact-status {
                            border: 2px solid #32465a !important;
                        }
                    }

                    .wrap {
                        width: 88%;
                        margin: 0 auto;
                        position: relative;

                        @media screen and (max-width: 735px) {
                            width: 100%;
                        }

                        span {
                            position: absolute;
                            left: 0;
                            margin: -2px 0 0 -2px;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            border: 2px solid #2c3e50;
                            background: #95a5a6;
                        }

                        span.online {
                            background: #2ecc71;
                        }

                        span.away {
                            background: #f1c40f;
                        }

                        span.busy {
                            background: #e74c3c;
                        }

                        img {
                            width: 40px;
                            border-radius: 50%;
                            float: left;
                            margin-right: 10px;

                            @media screen and (max-width: 735px) {
                                margin-right: 0px;
                            }
                        }

                        &.expanded {
                            img {
                                margin-right: 0;
                            }
                        }

                        .meta {

                            @media screen and (max-width: 735px) {
                                display: none;
                            }

                            .name {
                                font-weight: 600;
                            }

                            .preview {
                                margin: 5px 0 0 0;
                                padding: 0 0 1px;
                                font-weight: 400;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                transition: 1s all ease;

                                span {
                                    position: initial;
                                    border-radius: initial;
                                    background: none;
                                    border: none;
                                    padding: 0 2px 0 0;
                                    margin: 0 0 0 1px;
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        #bottom-bar {
            position: absolute;
            width: 100%;
            bottom: 0;

            button {
                float: left;
                border: none;
                width: 50%;
                padding: 10px 0;
                background: #32465a;
                color: #f5f5f5;
                cursor: pointer;
                font-size: 0.85em;
                font-family: "proxima-nova", "Source Sans Pro", sans-serif;

                @media screen and (max-width: 735px) {
                    float: none;
                    width: 100%;
                    padding: 15px 0;
                }

                &:focus {
                    outline: none;
                }

                &:nth-child(1) {
                    border-right: 1px solid #2c3e50;

                    @media screen and (max-width: 735px) {
                        border-right: none;
                        border-bottom: 1px solid #2c3e50;
                    }
                }

                &:hover {
                    background: #435f7a;
                }

                i {
                    margin-right: 3px;
                    font-size: 1em;

                    @media screen and (max-width: 735px) {
                        font-size: 1.3em;
                    }
                }

                @media screen and (max-width: 735px) {
                    span {
                        display: none;
                    }
                }
            }
        }
    }

    .content-chat {
        width: 100%;
        height: 100%;
        position: relative;
        margin: 0;
        padding: 0 0px 0 0;

        @media screen and (max-width: 735px) {
            // width: calc(100% - 58px);
            min-width: 300px !important;
        }

        .contact-profile {
            width: 100%;
            height: 60px;
            line-height: 60px;
            background-color: $nav-bg;
            padding: 0 0 0 20px;

            img {
                width: 40px;
                border-radius: 50%;
                float: left;
                margin: 9px 12px 0 9px;
            }

            p {
                float: left;
            }

            .social-media {

                i {
                    margin-left: 14px;
                    cursor: pointer;
                    color: #fff;
                    font-size: 30px;
                    margin-right: 30px;
                    opacity: 0.5;
                    line-height: 56px;

                    &:nth-last-child(1) {
                        margin-right: 20px;
                    }

                    &:hover {
                        color: #435f7a;
                    }
                }
            }
        }

        .messages {
            height: 100%;
            min-height: calc(100% - 153px);
            max-height: calc(100% - 153px);

            .navbar-nav {
                flex-direction: unset;

                .nav-link {
                    font-size: 16px;
                }
            }


            .dropdown {
                .dropdown-menu {
                    .dropdown-item {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: 1s all ease;
                        padding: 5px 20px;
                    }
                }
            }

            .show {
                >.dropdown-menu {
                    max-width: 500px;
                    max-height: 14vw;
                    overflow-y: scroll;
                    scroll-behavior: smooth;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #ccc;
                        border-radius: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: #f1f1f1;
                    }
                }
            }

            .live-chat-block {
                .ps-content {
                    padding: 20px 16%;
                }
            }


            .chat-msg {
                white-space: pre-line;
                padding-left: 40px;
            }

            .chat-msg-btns {
                height: 100%;
                position: relative;

                .chat-logo-block {
                    text-align: center;
                    margin: 20px auto;

                    p {
                        font-size: 26px !important;
                        color: #000;
                    }

                    .chat-logo {
                        width: 80px;
                        height: fit-contents;
                        margin: 0 auto;
                        border-radius: 50px;
                        border: solid 0px rgba($color: $primary, $alpha: 0.3);
                        line-height: 70px;

                        img {
                            width: 100%;
                            margin: auto;
                        }
                    }
                }

            }

            span {
                &.user-logo {
                    // margin-right: 17px;
                    // margin-top: 5px;
                    border-radius: 50px;
                    width: 28px;
                    height: 28px;
                    text-align: center;
                    font-size: 13px;
                    font-weight: bold !important;
                    display: inline-block;
                    line-height: 26px;
                    background: $btn-gray;
                    color: rgba($color: $black, $alpha: 0.7);
                    border: solid 1px $btn-gray;
                }
            }

            .live-chat-logo {
                img {
                    margin-right: 17px;
                    margin-top: 3px;
                    width: 28px;
                    height: 28px;
                    border-radius: 0;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                display: block;
                clear: both;
                margin: 1.25em;
                font-size: 1rem;
                line-height: 1.75;

                .answer-msg {
                    width: 100%;
                }

                .typing::after {
                    content: '|';
                    /* Add a blinking cursor or any other indicator if desired */
                    display: inline-block;
                    animation: blinkCursor 0.7s infinite;
                    /* Example of a blinking cursor animation */
                }

                .blinking-cursor {
                    display: inline-block;
                    animation: blinkCursor 0.7s infinite;
                    /* Example of a blinking cursor animation */
                }

                @keyframes blinkCursor {

                    0%,
                    100% {
                        opacity: 0;
                    }

                    50% {
                        opacity: 1;
                    }
                }

                // &:nth-last-child(1) {
                //     margin-bottom: 200px;
                //     // margin-bottom: 25vh;
                // }

                &.sent img {
                    margin: 6px 8px 0 0;
                }

                &.sent p {
                    background: $transparent;
                    color: #444;
                }

                &.replies img {
                    float: right;
                    margin: 6px 0 0 8px;
                }

                &.replies p {
                    color: $black;
                }

                img {
                    // width: 500px;
                    // border-radius: 50%;
                    // float: left;
                    // max-width: 700px;
                    // min-width: 400px;
                    // margin-left: 54px;
                    // max-height: 400px;
                    // object-fit: contain;
                    width: 100%;
                    max-height: 400px;
                }

                h3 {
                    display: block;
                    padding: 2px 12px;
                    border-radius: 20px;
                    line-height: 130%;
                    font-weight: 500;
                    font-size: 20px !important;
                    margin: 0px;
                    width: 88%;
                }
            }
        }
    }

    .message-input {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 99;
        max-height: 350px;
        background-color: $white;
        margin: auto;
        left: 0;
        right: 0;
        padding-bottom: 30px;

        .btns-block {
            padding: 10px 0 0;
            // position: absolute;
            width: 47rem;
            margin: auto;

            .p-dropdown {
                margin: 0px 0px 20px 0px;

            }

            .p-dropdown-items-wrapper {
                max-height: 200px !important;
                /* Adjust the value as needed */
                overflow-y: auto !important;
            }

            .btn-chat {
                display: inline-block;
                border: solid 0px $primary;
                background-color: $nav-bg;
                color: rgba($color: $black, $alpha: 0.7);
                border-radius: 8px;
                margin: 0 0px 20px 0px;
                text-align: left;
                transition: 0.8s;
                position: relative;
                width: 100%;

                span {
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 3px;

                    +span {
                        font-size: 14px;
                        font-weight: normal;
                        color: $gray-666;
                    }
                }

                &:hover {
                    background-color: rgba($color: $black, $alpha: 0.2);
                    transition: 0.8s;

                    &:before {
                        content: "\f062";
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        display: block;
                        position: absolute;
                        top: 18px;
                        right: 5px;
                        width: 26px;
                        height: 26px;
                        background-color: $btn-gray;
                        color: rgba($color: $black, $alpha: 0.7);
                        font-size: 14px;
                        border-radius: 4px;
                        text-align: center;
                        line-height: 25px;
                        opacity: 0.8;
                    }
                }
            }
        }

        .message-block {
            padding: 10px 0 0;
            width: 47rem;
            margin: auto;

            .p-dropdown {
                margin-bottom: 20px;
            }

            .message-input-holder {
                width: 100%;
                border: solid 1px #c5c5d2;
                border-radius: 5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 52px;
                padding: 0.5rem 1rem;


                p-fileupload {
                    .p-button {
                        &.p-fileupload-choose {
                            background: transparent;
                            color: #3b4555;
                            height: 22px;
                            width: 22px;
                            box-shadow: none;
                            padding: 0px;
                            border: 0px;

                            .pi-plus {
                                &:before {
                                    content: "\e90e";
                                    font-weight: bold;
                                    font-size: 16px;
                                    color: $brand-color;
                                }
                            }
                        }

                        &.p-fileupload-choose-selected {
                            border: 0px;
                            box-shadow: none;

                            .pi-plus {
                                &:before {
                                    content: "\e958" !important;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                grammarly-extension {
                    top: 10px !important;
                }

                textarea {
                    resize: none;
                    border: none;
                    width: calc(100% - 40px);
                    padding: 15px;
                    color: $black;
                    background: transparent;
                    max-height: 200px;
                    height: 52px;
                    overflow: hidden;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }

                button {
                    border: 1px $transparent solid;
                    padding: 0px 0px;
                    cursor: pointer;
                    color: $white;
                    font-size: 16px;
                    background: $brand-color;
                    border-radius: 5rem;
                    height: 32px;
                    width: 32px;
                    bottom: 0.7rem;
                    opacity: 0.4;
                    transition: 0.5s;

                    &.btn-enable {
                        opacity: 1;
                    }

                    @media screen and (max-width: 735px) {
                        padding: 16px 0;
                    }

                    &:hover {
                        color: $white;
                        border: solid 1px $white;
                        background: $brand-color;
                    }

                    &:focus {
                        outline: none;
                    }
                }

            }
        }

        // .message-input-holder {
        //     width: 100%;
        //     z-index: 99;
        //     max-height: 200px;
        //     height: 52px;
        //     background-color: $white;
        //     border-radius: 5rem;
        //     border: solid 1px #c5c5d2;
        //     max-width: 48rem;
        //     margin: auto;
        //     position: relative;

        //     /* &.typing {
        //         height: 100%;
        //     } */

        //     grammarly-extension {
        //         top: 10px !important;
        //     }

        //     textarea {
        //         resize: none;
        //         border: none;
        //         width: calc(100% - 40px);
        //         padding: 1rem 2.4rem 1rem 2.4rem;
        //         color: $black;
        //         background: transparent;
        //         max-height: 200px;
        //         height: 52px;

        //         &:focus {
        //             outline: none;
        //         }

        //         &::placeholder {
        //             font-size: 16px;
        //             padding-left: 20px;
        //         }
        //     }

        //     input {
        //         resize: none;
        //         border: none;
        //         width: calc(100% - 90px);
        //         padding: 10px 10px 10px 10px;
        //         color: #000;
        //         background: transparent;

        //         @media screen and (max-width: 735px) {
        //             padding: 15px 32px 16px 8px;
        //         }

        //         &:focus {
        //             outline: none;
        //         }
        //     }

        //     .attachment {
        //         position: absolute;
        //         right: 60px;
        //         z-index: 4;
        //         margin-top: 10px;
        //         font-size: 2em;
        //         color: $black;
        //         opacity: 0.4;
        //         cursor: pointer;

        //         @media screen and (max-width: 735px) {
        //             margin-top: 17px;
        //             right: 65px;
        //         }

        //         &:hover {
        //             opacity: 1;
        //         }
        //     }

        //     p-fileupload {
        //         position: absolute;
        //         left: 10px;
        //         top: 14px;

        //         .p-button {
        //             &.p-fileupload-choose {
        //                 background: transparent;
        //                 color: #3b4555;
        //                 font-weight: 500;
        //                 width: fit-content;
        //                 height: fit-content;
        //                 border-radius: 100%;
        //                 padding: 8px 8px;
        //                 border: 1px solid #bf2d10;
        //             }

        //             &.p-fileupload-choose-selected {
        //                 border: 0px;
        //                 box-shadow: none;

        //                 .pi-plus {
        //                     &:before {
        //                         content: "\e958";
        //                         font-size: 12px;
        //                     }
        //                 }
        //             }

        //             .p-button-icon-left {
        //                 right: 2px;
        //                 font-weight: bold;
        //                 font-size: 10px;
        //                 color: #bf2d10;
        //             }
        //         }
        //     }

        //     button {
        //         border: 1px $transparent solid;
        //         padding: 0px 0px;
        //         cursor: pointer;
        //         color: $white;
        //         font-size: 16px;
        //         background: #bf2d10;
        //         border-radius: 5rem;
        //         height: 32px;
        //         width: 32px;
        //         position: absolute;
        //         // right: 1rem;
        //         bottom: 0.7rem;
        //         opacity: 0.4;
        //         transition: 0.5s;

        //         &.btn-enable {
        //             opacity: 1;
        //         }

        //         @media screen and (max-width: 735px) {
        //             padding: 16px 0;
        //         }

        //         &:hover {
        //             color: $white;
        //             border: solid 1px $white;
        //             background: $brand-color;
        //         }

        //         &:focus {
        //             outline: none;
        //         }
        //     }
        // }
    }
}

.typing-demo {
    height: 90ch;
    animation: typing 2s steps(90), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 0px solid;
    overflow-wrap: break-word;
    font-size: 14px;
}

@keyframes typing {
    from {
        height: 0;
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

.new-chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-size: 1.1rem;

    .dropdown-user {
        .dropdown-toggle::after {
            font-size: 10px;
        }

        .dropdown-menu {
            // height: 40vh;
            overflow: auto;
            max-height: 40vh;
            min-height: 12vh;
            width: 22vw;
            left: 10px !important;

            .dropdown-item {
                .ft-trash-2 {
                    cursor: pointer;
                    padding: 5px;
                    transition: 0.4s;

                    &:hover {
                        background-color: #d24d4d;
                        color: white;
                        border-radius: 100%;
                    }
                }
            }
        }
    }
}

.chat-msg-btns {
    height: 100%;
    position: relative;

    .chat-logo-block {
        text-align: center;
        margin: 15px auto;

        p {
            font-size: 26px !important;
            color: #000;
        }

        .chat-logo {
            width: 80px;
            height: fit-contents;
            margin: 0 auto;
            border-radius: 50px;
            border: solid 0px rgba($color: $primary, $alpha: 0.3);
            line-height: 70px;

            img {
                width: 100%;
                margin: auto;
            }
        }
    }

}

/* Hide scrollbar for all elements */
// ::-webkit-scrollbar {
//     width: 0;
// }

// * {
//     scrollbar-width: none;
// }

.chat-msg-block {
    height: 85vh;
    overflow: auto;
    // margin: 0px 145px;
    width: 47rem;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;

    ul .thread:nth-last-child(1) {
        // margin-bottom: 20vh !important;
        padding-bottom: 15vh !important;
    }

    .chat-info {
        font-size: 26px !important;
        color: #000;
        text-align: center;
        display: flex;
        flex: auto;
        justify-content: center;
    }


    ul {
        .user-logo {
            border-radius: 50px;
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: 18px;
            font-weight: bold !important;
            display: inline-block;
            line-height: 38px;
            background: rgb(232, 232, 227);
            color: rgba(0, 0, 0, 0.7);
            border: solid 1px rgb(232, 232, 227);
        }

        .chat-msg {
            white-space: pre-line;
            padding-left: 54px;
            font-size: 1.10rem;
            line-height: 24px;
        }
    }
}

.jobs-dropdown {
    position: absolute;
    bottom: 135px;
    width: 100%;
    height: 50px;

    .btns-block {
        padding: 10px 0 0;
        // position: absolute;
        width: 47rem;
        margin: auto;

        .p-dropdown {
            margin: 0px 0px 20px 0px;

            .p-dropdown-panel {
                .p-dropdown-items {
                    .p-dropdown-item {
                        margin: 0px !important;
                    }
                }
            }

            .p-dropdown-items-wrapper {
                max-height: 200px !important;
                overflow-y: auto !important;
            }
        }
    }
}

.suggestion-btns {
    position: absolute;
    bottom: 300px;
    width: 100%;
    height: 50px;

    .btns-block {
        padding: 10px 0 0;
        // position: absolute;
        width: 47rem;
        margin: auto;

        // .p-dropdown {
        //     margin: 0px 0px 20px 0px;

        // }

        // .p-dropdown-items-wrapper {
        //     max-height: 200px !important;
        //     /* Adjust the value as needed */
        //     overflow-y: auto !important;
        // }

        .btn-chat {
            display: inline-block;
            border: solid 0px $primary;
            background-color: $nav-bg;
            color: rgba($color: $black, $alpha: 0.7);
            border-radius: 8px;
            margin: 0 0px 20px 0px;
            text-align: left;
            transition: 0.8s;
            position: relative;
            width: 100%;

            span {
                display: block;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 3px;

                +span {
                    font-size: 14px;
                    font-weight: normal;
                    color: $gray-666;
                }
            }

            &:hover {
                background-color: rgba($color: $black, $alpha: 0.2);
                transition: 0.8s;

                &:before {
                    content: "\f062";
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    display: block;
                    position: absolute;
                    top: 18px;
                    right: 5px;
                    width: 26px;
                    height: 26px;
                    background-color: $btn-gray;
                    color: rgba($color: $black, $alpha: 0.7);
                    font-size: 14px;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 25px;
                    opacity: 0.8;
                }
            }
        }
    }
}

.message-area {
    position: absolute;
    bottom: 70px;
    width: 100%;
    height: 50px;


    &.train-chatbot {
        // bottom: 120px;
        bottom: 40px;
    }

    .message-input-block {
        // padding: 7px;
        // background-color: #f3f3ee;
        // margin: 0px 145px;
        // border-radius: 5rem;
        padding: 7px;
        background-color: #f3f3ee;
        margin: 0px 145px;
        border-radius: 5rem;
        width: 47rem;
        margin: auto;

        .message-input-holder {
            width: 100%;
            border: solid 1px #c5c5d2;
            border-radius: 5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 52px;
            padding: 0.5rem 1rem;
            background-color: white;


            p-fileupload {
                .p-button {
                    &.p-fileupload-choose {
                        background: transparent;
                        color: #3b4555;
                        height: 18px;
                        width: 20px;
                        box-shadow: none;
                        padding: 1px;
                        border: 0px;
                        // border: 2px solid #979797;
                        background-image: url('../../../images/svg/upload-file.svg');
                        background-repeat: no-repeat;
                        background-position: 4px 0px;

                        // .pi-plus {
                        //     &:before {
                        //         content: "\e90e";
                        //         font-weight: bold;
                        //         font-size: 16px;
                        //         color: $brand-color;
                        //     }
                        // }

                        // .p-button-icon-left {
                        //     position: absolute;
                        //     right: 3px;
                        //     top: 1px;
                        //     width: 10px;
                        // }

                        .p-icon-wrapper {
                            display: none;
                        }
                    }

                    &.p-fileupload-choose-selected {
                        border: 0px;
                        box-shadow: none;
                        background-image: url('../../../images/svg/uploaded-file.svg');
                        background-repeat: no-repeat;
                        background-position: 4px 0px;

                        // .pi-plus {
                        //     &:before {
                        //         content: "\e958" !important;
                        //         font-size: 16px;
                        //     }
                        // }
                    }
                }
            }

            grammarly-extension {
                top: 10px !important;
            }

            textarea {
                resize: none;
                border: none;
                width: calc(100% - 40px);
                padding: 15px;
                color: $black;
                background: transparent;
                max-height: 200px;
                height: 52px;
                overflow: auto;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-size: 14px;
                    padding-left: 5px;
                }
            }

            button {
                border: 1px $transparent solid;
                padding: 0px 0px;
                cursor: pointer;
                color: $white;
                font-size: 16px;
                background: $brand-color;
                border-radius: 5rem;
                height: 32px;
                width: 32px;
                bottom: 0.7rem;
                opacity: 0.4;
                transition: 0.5s;

                &.btn-enable {
                    opacity: 1;
                }

                @media screen and (max-width: 735px) {
                    padding: 16px 0;
                }

                &:hover {
                    color: $white;
                    border: solid 1px $white;
                    background: $brand-color;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.dropdown-item:active {
    background: rgba($color: $brand-dark-blue, $alpha: 1.0);
}

.liveChat {
    .p-sidebar {
        width: 50vw;
    }
}