// Core variables and mixins
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../variables/bootstrap-variables";
@import "../../../bootstrap/variables";

@import "../../variables/components-variables";

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";

// Vertical Overlay Menu
//=========================
.vertical-overlay-menu {

	//Content expanded
	.content {
		@include main-menu-width(0);
	}

	//Navbar expanded
	.navbar {
		.navbar-header {
			@include menu-navbar-width($overlay-menu-width);
		}

		&.navbar-brand-center {
			.navbar-container {
				@include main-menu-width(0);
			}

			.navbar-header {
				@include menu-navbar-width(auto);
			}
		}
	}

	// Main menu expanded
	.main-menu {
		opacity: 0;
		transform: translate3d(0, 0, 0);
		transition: width .25s, opacity .25s, transform .25s;
		width: $overlay-menu-width;
		left: -($overlay-menu-width);

		// opacity: 1;
		// transform: translate3d(200px, 0, 0);
		// transition: width 0.25s, opacity 0.25s, transform 0.25s;


		.navigation {
			.navigation-header {

				.la-ellipsis-h,
				.nav-menu-icon {
					// display: none;
				}
			}

			>li {
				>a {
					>i {
						font-size: 1.6rem;
						margin-right: 12px;
						float: left;

						&:before {
							transition: 200ms ease all;
						}
					}
				}
			}

			li {
				@include vertical-menu-has-sub-arrow(1rem);
			}
		}

		.main-menu-footer {
			bottom: 55px;
		}

		.main-menu-footer {
			width: $overlay-menu-width;
		}
	}

	&.menu-open {
		.main-menu {
			opacity: 1 !important;
			transform: translate3d($overlay-menu-width, 0, 0) !important;
			transition: width .25s, opacity .25s, transform .25s !important;
		}
	}

	&.menu-hide {
		.main-menu {
			@extend .main-menu;
		}
	}

	// Flipped menu open
	&.menu-flipped {

		// Main menu hidden on flipped
		.main-menu {
			right: -($overlay-menu-width);
			left: inherit;
		}

		//Navbar flipped
		.navbar {
			.navbar-container {
				@include vertical-flipped-menu($overlay-menu-width);
			}

			.navbar-header {
				float: right;
			}
		}

		&.menu-open {
			.main-menu {
				transform: translate3d(-$overlay-menu-width, 0, 0);
			}
		}
	}

	// For Fixed-footer
	footer {
		&.footer {
			&.fixed-bottom {
				z-index: 999;
			}
		}
	}

	// Boxed Layout
	&.boxed-layout {
		position: relative;

		.main-menu {
			&.menu-fixed {
				position: absolute;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.vertical-overlay-menu {
		.main-menu {
			.main-menu-footer {
				bottom: 0px;
			}
		}

		//Box-layout
		&[data-menu="vertical-compact-menu"] {
			&.boxed-layout {
				&.menu-open {
					.main-menu {
						transform: translate3d($compact-menu-width, 0, 0);
					}
				}
			}
		}
	}
}


@media only screen and (max-width: 992px) and (min-width: 768px) {
	.vertical-overlay-menu {
		#navbar-mobile {
			.search-input.open {
				.input {
					border-bottom: 1px solid $white;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.vertical-overlay-menu {
		.header-navbar {
			.navbar-header {
				.mobile-menu {
					a.menu-toggle {
						padding-top: 8px;
					}
				}

				li.nav-item.d-md-none {
					a.open-navbar-container {
						// padding-top: 1rem;
					}
				}
			}
		}
	}
}