// Google Color Palette defined: http://www.google.com/design/spec/style/color.html
@import "../../bootstrap/functions";

@import "../variables/bootstrap-variables";
@import "../../bootstrap/variables";

$white: (
   "base": #ffffff
);

$black: (
   "base": #000000
);

$primary: (
   "lighten-5": lighten(theme-color("primary"), 25%),
   "lighten-4": lighten(theme-color("primary"), 20%),
   "lighten-3": lighten(theme-color("primary"), 15%),
   "lighten-2": lighten(theme-color("primary"), 10%),
   "lighten-1": lighten(theme-color("primary"), 5%),
   "base": theme-color("primary"),
   "darken-1": darken(theme-color("primary"), 5%),
   "darken-2": darken(theme-color("primary"), 10%),
   "darken-3": darken(theme-color("primary"), 15%),
   "darken-4": darken(theme-color("primary"), 20%),
   "accent-1": #efefff,
   "accent-2": #dbdbff,
   "accent-3": #bcbfff,
   "accent-4": #a2a6ff
);

$secondary: (
   "lighten-5": lighten(theme-color("secondary"), 25%),
   "lighten-4": lighten(theme-color("secondary"), 20%),
   "lighten-3": lighten(theme-color("secondary"), 15%),
   "lighten-2": lighten(theme-color("secondary"), 10%),
   "lighten-1": lighten(theme-color("secondary"), 5%),
   "base": theme-color("secondary"),
   "darken-1": darken(theme-color("secondary"), 5%),
   "darken-2": darken(theme-color("secondary"), 10%),
   "darken-3": darken(theme-color("secondary"), 15%),
   "darken-4": darken(theme-color("secondary"), 20%),
);

$success-color: (
   "lighten-5": lighten(theme-color("success"), 25%),
   "lighten-4": lighten(theme-color("success"), 20%),
   "lighten-3": lighten(theme-color("success"), 15%),
   "lighten-2": lighten(theme-color("success"), 10%),
   "lighten-1": lighten(theme-color("success"), 5%),
   "base": theme-color("success"),
   "darken-1": darken(theme-color("success"), 5%),
   "darken-2": darken(theme-color("success"), 10%),
   "darken-3": darken(theme-color("success"), 15%),
   "darken-4": darken(theme-color("success"), 20%),
   "accent-1": #e1fff0,
   "accent-2": #aeffd5,
   "accent-3": #7bffbb,
   "accent-4": #62ffae
);

$info: (
   "lighten-5": lighten(theme-color("info"), 25%),
   "lighten-4": lighten(theme-color("info"), 20%),
   "lighten-3": lighten(theme-color("info"), 15%),
   "lighten-2": lighten(theme-color("info"), 10%),
   "lighten-1": lighten(theme-color("info"), 5%),
   "base": theme-color("info"),
   "darken-1": darken(theme-color("info"), 5%),
   "darken-2": darken(theme-color("info"), 10%),
   "darken-3": darken(theme-color("info"), 15%),
   "darken-4": darken(theme-color("info"), 20%),
   "accent-1": #eff5ff,
   "accent-2": #dfecff,
   "accent-3": #accfff,
   "accent-4": #92c0ff
);
$warning: (
   "lighten-5": lighten(theme-color("warning"), 25%),
   "lighten-4": lighten(theme-color("warning"), 20%),
   "lighten-3": lighten(theme-color("warning"), 15%),
   "lighten-2": lighten(theme-color("warning"), 10%),
   "lighten-1": lighten(theme-color("warning"), 5%),
   "base": theme-color("warning"),
   "darken-1": darken(theme-color("warning"), 5%),
   "darken-2": darken(theme-color("warning"), 10%),
   "darken-3": darken(theme-color("warning"), 15%),
   "darken-4": darken(theme-color("warning"), 20%),
   "accent-1": #fff5ef,
   "accent-2": #ffe5d8,
   "accent-3": #ffddd0,
   "accent-4": #ffcab7
);

$danger-color: (
   "lighten-5": lighten(theme-color("danger"), 25%),
   "lighten-4": lighten(theme-color("danger"), 20%),
   "lighten-3": lighten(theme-color("danger"), 15%),
   "lighten-2": lighten(theme-color("danger"), 10%),
   "lighten-1": lighten(theme-color("danger"), 5%),
   "base": theme-color("danger"),
   "darken-1": darken(theme-color("danger"), 5%),
   "darken-2": darken(theme-color("danger"), 10%),
   "darken-3": darken(theme-color("danger"), 15%),
   "darken-4": darken(theme-color("danger"), 20%),
   "accent-1": #ffeef1,
   "accent-2": #ffd6db,
   "accent-3": #ffd0d3,
   "accent-4": #ffb7bc
);

$light: (
   "base": #e0e0e0,
   "lighten-1": #bdbdbd,
   "darken-1": #9e9e9e
);

$dark: (
   "base": #424242,
   "lighten-1": #616161,
   "darken-1": #212121
);

$red: (
   "lighten-5": #ffebee,
   "lighten-4": #ffcdd2,
   "lighten-3": #ef9a9a,
   "lighten-2": #e57373,
   "lighten-1": #ef5350,
   "base": #f44336,
   "darken-1": #e53935,
   "darken-2": #d32f2f,
   "darken-3": #c62828,
   "darken-4": #b71c1c,
   "accent-1": #ff8a80,
   "accent-2": #ff5252,
   "accent-3": #ff1744,
   "accent-4": #d50000
);

$pink: (
   "lighten-5": #fce4ec,
   "lighten-4": #f8bbd0,
   "lighten-3": #f48fb1,
   "lighten-2": #f06292,
   "lighten-1": #ec407a,
   "base": #e91e63,
   "darken-1": #d81b60,
   "darken-2": #c2185b,
   "darken-3": #ad1457,
   "darken-4": #880e4f,
   "accent-1": #ff80ab,
   "accent-2": #ff4081,
   "accent-3": #f50057,
   "accent-4": #c51162
);

$purple: (
   "lighten-5": #f3e5f5,
   "lighten-4": #e1bee7,
   "lighten-3": #ce93d8,
   "lighten-2": #ba68c8,
   "lighten-1": #ab47bc,
   "base": #9c27b0,
   "darken-1": #8e24aa,
   "darken-2": #7b1fa2,
   "darken-3": #6a1b9a,
   "darken-4": #4a148c,
   "accent-1": #ea80fc,
   "accent-2": #e040fb,
   "accent-3": #d500f9,
   "accent-4": #dd00ff
);

$blue: (
   "lighten-5": #e3f2fd,
   "lighten-4": #bbdefb,
   "lighten-3": #90caf9,
   "lighten-2": #64b5f6,
   "lighten-1": #42a5f5,
   "base": #2196f3,
   "darken-1": #1e88e5,
   "darken-2": #1976d2,
   "darken-3": #1565c0,
   "darken-4": #0d47a1,
   "accent-1": #82b1ff,
   "accent-2": #448aff,
   "accent-3": #2979ff,
   "accent-4": #2962ff
);

$cyan: (
   "lighten-5": #e0f7fa,
   "lighten-4": #b2ebf2,
   "lighten-3": #80deea,
   "lighten-2": #4dd0e1,
   "lighten-1": #26c6da,
   "base": #00bcd4,
   "darken-1": #00acc1,
   "darken-2": #0097a7,
   "darken-3": #00838f,
   "darken-4": #006064,
   "accent-1": #84ffff,
   "accent-2": #18ffff,
   "accent-3": #00e5ff,
   "accent-4": #00b8d4
);

$teal: (
   "lighten-5": #e0f2f1,
   "lighten-4": #b2dfdb,
   "lighten-3": #80cbc4,
   "lighten-2": #4db6ac,
   "lighten-1": #26a69a,
   "base": #009688,
   "darken-1": #00897b,
   "darken-2": #00796b,
   "darken-3": #00695c,
   "darken-4": #004d40,
   "accent-1": #a7ffeb,
   "accent-2": #64ffda,
   "accent-3": #1de9b6,
   "accent-4": #00bfa5
);

$yellow: (
   "lighten-5": #fffde7,
   "lighten-4": #fff9c4,
   "lighten-3": #fff59d,
   "lighten-2": #fff176,
   "lighten-1": #ffee58,
   "base": #ffeb3b,
   "darken-1": #fdd835,
   "darken-2": #fbc02d,
   "darken-3": #f9a825,
   "darken-4": #f57f17,
   "accent-1": #ffff8d,
   "accent-2": #ffff00,
   "accent-3": #ffea00,
   "accent-4": #ffd600
);

$amber: (
   "lighten-5": #fff8e1,
   "lighten-4": #ffecb3,
   "lighten-3": #ffe082,
   "lighten-2": #ffd54f,
   "lighten-1": #ffca28,
   "base": #ffc107,
   "darken-1": #ffb300,
   "darken-2": #ffa000,
   "darken-3": #ff8f00,
   "darken-4": #ff6f00,
   "accent-1": #ffe57f,
   "accent-2": #ffd740,
   "accent-3": #ffc400,
   "accent-4": #ffab00
);

$blue-grey: (
   "lighten-5": #eceff1,
   "lighten-4": #cfd8dc,
   "lighten-3": #b0bec5,
   "lighten-2": #3f494e,
   "lighten-1": #78909c,
   "base": #607d8b,
   "darken-1": #546e7a,
   "darken-2": #455a64,
   "darken-3": #37474f,
   "darken-4": #263238
);

$grey-blue: (
   "lighten-5": #eceff1,
   "lighten-4": #cfd8dc,
   "lighten-3": #b0bec5,
   "lighten-2": #6f85ad,
   "lighten-1": #78909c,
   "base": #1b2942,
   "darken-1": #546e7a,
   "darken-2": #2c303b,
   "darken-3": #37474f,
   "darken-4": #263238
);

$shades: (
   "black": #000000,
   "white": #ffffff,
   "transparent": transparent
);

$colors: (
   "white": $white,
   "black": $black,
   "primary": $primary,
   "secondary": $secondary,
   "success": $success-color,
   "info": $info,
   "warning": $warning,
   "danger": $danger-color,
   "light": $light,
   "dark": $dark,
   "red": $red,
   "pink": $pink,
   "purple": $purple,
   "blue": $blue,
   "cyan": $cyan,
   "teal": $teal,
   "yellow": $yellow,
   "amber": $amber,
   "blue-grey": $blue-grey,
   "grey-blue": $grey-blue,
   "shades": $shades
);