form {
  label {
    color: #3b4555 !important;
    font-size: 16px !important;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-control {
    border: 1px solid $border-color;
    color: darken(#ababab, 70%);
    border-radius: 8px !important;

    &::-webkit-input-placeholder {
      color: darken(#ababab, 10%);
    }

    &:-moz-placeholder {
      color: darken(#ababab, 100%);
    }

    &::-moz-placeholder {
      color: darken(#ababab, 100%);
    }

    &:-ms-input-placeholder {
      color: darken(#ababab, 100%);
    }
  }

  .form-section {
    color: $menu-dark-bg;
    line-height: 3rem;
    margin-bottom: 20px;
    border-bottom: 1px solid $menu-dark-bg;

    i {
      font-size: 20px;
      margin-left: 5px;
      margin-right: 10px;
    }
  }


  .form-actions {
    border-top: 1px solid darken($body-bg, 10%);
    padding: 20px 0;
    margin-top: 20px;

    &.filled {
      background-color: $body-bg;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.top {
      border-top: 0;
      border-bottom: 1px solid darken($body-bg, 10%);
      margin-top: 0;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      .buttons-group {
        float: left !important;
        margin-bottom: 10px;
      }
    }
  }

  &.form-horizontal {
    .form-group {
      @include media-breakpoint-up(sm) {
        .label-control {
          text-align: right;
        }
      }
    }
  }

  &.row-separator {
    .form-group {
      margin: 0;
      border-bottom: 1px solid darken($body-bg, 3%);

      .label-control {
        padding-top: 1.5rem;
      }

      >div {
        padding: 1.2rem;
        padding-right: 0;
      }

      &.last {
        border-bottom: 0;
      }
    }
  }

  &.form-bordered {
    .form-group {
      margin: 0;
      border-bottom: 1px solid darken($body-bg, 3%);

      .label-control {
        padding-top: 1.5rem;
      }

      >div {
        padding: 1.2rem;
        padding-right: 0;
        border-left: 1px solid darken($body-bg, 3%);
      }

      &.last {
        border-bottom: 0;
      }
    }
  }

  &.striped-rows {
    .form-group {
      margin: 0;

      .label-control {
        padding-top: 1.5rem;
      }

      >div {
        padding: 1.2rem;
        padding-right: 0;
      }

      &:nth-child(even) {
        background-color: $body-bg;
      }
    }
  }

  &.striped-labels {
    .form-group {
      margin: 0;

      .label-control {
        padding-top: 1.5rem;
      }

      >div {
        padding: 1.2rem;
        padding-right: 0;
        background-color: white;
      }

      &:nth-child(even) {
        background-color: $body-bg;
      }
    }
  }

  .custom-control-indicator {
    width: 1.1rem;
    height: 1.075rem;
    line-height: 1.05rem;
    top: 0.15rem;
    left: 0;

    +.custom-control-description {
      margin-left: 1rem;
    }
  }
}

select.form-control {
  padding-top: 0;
  padding-bottom: 0;
}

/*Bootstrap Enhance */
.custom-checkbox {
  margin-bottom: 0;

  .custom-control-input {
    &:focus {
      outline: -webkit-focus-ring-color auto 5px;
    }

    &:disabled:checked {
      ~.custom-control-label::before {
        background-color: rgba(30, 159, 242, 0.5);
      }
    }
  }
}

.custom-checkbox input:disabled:checked~.custom-control-indicator {
  opacity: 0.5;
}

.custom-checkbox input:disabled~.custom-control-indicator {
  cursor: not-allowed;
}

.custom-control-input>input:focus~.custom-control-indicator {
  padding: 0.51rem;
}

.custom-control-indicator {
  width: 1.1rem;
  height: 1.075rem;
  line-height: 1.05rem;
  top: 0.15rem;

  +.custom-control-description {
    margin-left: 1rem;
  }
}

.form-control {

  &:disabled,
  &[readonly] {
    background-color: transparent;
    cursor: not-allowed;
  }
}

.input-group-addon {
  background-color: #eceff1;
  border-color: #babfc7;
}

.input-group-text {
  padding: 0.4rem 1rem;
}

.custom-control {
  min-height: auto;
}

// Skin-Square Buttons
.checkbox-input-group,
.radio-input-group {
  .input-group {

    .skin.skin-square,
    .skin-polaris.skin {
      height: 16px;
      width: 22px;

      .icheckbox_square-red {
        top: -3px;
      }

      .iradio_polaris {
        top: -7px;
      }
    }
  }
}

// Group Input with Switchery
.switchery-input-group {
  .input-group {
    .input-group-text {
      padding: 0.2rem 1rem;
    }
  }
}

//Custom Checkbox
.custom-control-label::before {
  width: 1.1rem;
  height: 1.1rem;
}

.custom-control-label::after {
  width: 1.1rem;
}