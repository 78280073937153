$base-font-size: 14px;
$body-direction: ltr; // Default ltr, change it to rtl for Right To Left support.

//	------------------------------
//	  Colors
//	------------------------------
$text-color: #3b4555;
$transparent: transparent;
$gray-666: #666666;
$primary: #0073ff;
$primary-liter: #f2d1bc;
$btn-gray:rgb(232 232 227);
$border-color: rgb(215 215 206); //	------------------------------
$main-menu-hover-bg: #3e2611;
$main-menu-active-bg: $primary;
$btn-hover: #0073ff;
$gray-dark: #333333;
$brand-gray:#f3f3ef;
$nav-bg: #f9f9f7;
$brand-blue1:#0073ff;
$brand-blue01:#3D348B;
$brand-blue2:#0073ff;
$brand-pink1:#f056b9;
$brand-pink2:#ffa6df;
$brand-green1: #28c3ba;
$brand-green2: #78f5de;
$brand-orange: #ff7a00;
$brnad-red: #fe0000;
$brand-dark-blue: #005ccc;
$font-color:#202124;
//	  Buttons
//	------------------------------
$btn-border-radius-square: 0;
$btn-border-radius-round: 2rem;

//	------------------------------
//	  Navbar
//	------------------------------
$navbar-height: 48px;
$navbar-light-bg: $white;
$navbar-dark-bg: #2c303b;
$navbar-header-width: 200px;

//	------------------------------
//	  Main Menu
//	------------------------------

//main menu light
$menu-light-bg: $brand-color;
$menu-light-color: $white;

//main menu dark
$menu-dark-bg: #2c303b;
$menu-dark-color: #dcdcdc;

$menu-padding: 5px 18px;
$menu-second-level-padding: 8px 18px 8px 54px;
$menu-third-level-padding: 8px 18px 8px 64px;
$menu-forth-level-padding: 8px 18px 8px 74px;

// vertical menu
$menu-expanded-width: 200px;
$menu-collapsed-width: 60px;

// vertical overlay menu
$overlay-menu-width: 200px;

// vertical compact menu
$compact-menu-width: 120px;

// vertical mm menu
$mm-expanded-width: 200px;
$mm-collapsed-width: 200px;

// vertical menu
$content-menu-expanded-width: 200px;
$content-menu-collapsed-width: 70px;

// menu search
$menu-search-height: 70px;

//Main menu footer
$menu-footer-height: 15px;
$menu-footer-color: $white;
$menu-footer-color-bg: #2c303b;
$menu-footer-link-bg: rgb(33, 41, 46);
$menu-footer-link-hover-bg: rgb(30, 36, 39);

//Footer color
$footer-color-light-bg: $white;
$footer-color-dark-bg: #2c303b;

//	------------------------------
//	  Sideber
//	-------------------------------
$sidebar-width: 300px;
//	-------------------------------
//	  Avatar
//	-------------------------------

$avatar-size: 30px;
$avatar-status-size: 8px;

$avatar-size-lg: 144px;
$avatar-status-size-lg: 20px;

$avatar-size-md: 40px;
$avatar-status-size-md: 10px;

$avatar-size-sm: 32px;
$avatar-status-size-sm: 8px;

$avatar-size-xs: 24px;
$avatar-status-size-xs: 7px;

$avatar-size-50: 50px;
$avatar-status-size-50: 10px;

$avatar-size-100: 100px;
$avatar-status-size-100: 20px;

$avatar-online-color: $success;
$avatar-off-color: $gray-100;
$avatar-busy-color: $danger;
$avatar-away-color: $warning;

//	-------------------------------
//	Progress
//	-------------------------------
$progress-size-xl: 2rem;
$progress-size-lg: 1.5rem;
$progress-size-md: 1rem;
$progress-size-sm: 0.5rem;
$progress-size-xs: 0.25rem;

//	-------------------------------
//	Form
//	-------------------------------

// $input-height, $input-height-lg, $input-height-sm are in variables
$font-size-xs: 0.75rem;
$font-size-xl: ($font-size-base + 0.5);
$line-height-xl: 1.7;
$line-height-xs: 1.5;
$input-padding-y-xl: 0.5rem !default;
$input-padding-x-xl: 0.5rem !default;

$input-padding-y-xs: 0.2rem !default;
$input-padding-x-xs: 0.275rem !default;

$border-radius-xl: 0.35rem !default;
$border-radius-xs: 0.12rem !default;
$input-border-radius-xl: $border-radius-xl;
$input-border-radius-xs: $border-radius-xs;

$input-height-xl: (($font-size-xl * $line-height-xl) + ($input-padding-y-xl * 2)) !default;
$input-height-xs: (($font-size-xs * $line-height-xs) + ($input-padding-y-xs * 2)) !default;
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

//	-------------------------------
//	Table
//	-------------------------------
$table_border_color: #e3ebf3;