.customizer {
  width: 600px;
  right: -600px;
  padding: 0;
  background-color: #fff;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

  // &.open {
  //   right: 0;
  // }

  .customizer-content {
    position: relative;
    height: 100%;

    .custom-control {
      .custom-control-label {
        top: 0;
      }
    }
  }

  a.customizer-toggle {
    border-radius: 15px 0 0 15px;
    background-color: $btn-gray;
    color: $font-color;
    text-align: center;
    padding: 0.8em 1.2em;
    font-size: 1em;
    font-weight: 500;
    line-height: 36px;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    border: 1px solid rgba($color: $black, $alpha: 0.1);

    &:hover {
      // box-shadow: inset -15px 13px 30px rgba(27, 132, 125, .3), inset 20px -55px 30px rgba(27, 132, 125, .3);
      color: $font-color;
    }
  }

  a.customizer-close {
    color: #000;
  }

  .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10;
  }

  .customizer-toggle {
    position: absolute;
    top: 35%;
    width: 54px;
    height: 50px;
    left: -54px;
    text-align: center;
    line-height: 56px;
    cursor: pointer;
  }

  .color-options {
    a {
      white-space: pre;
    }
  }

  .customizer-sidebar-options .active {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
  }
}

.custom-chat {
  width: 50%;
  right: -50%;
  padding: 0;
  background-color: #fff;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

  // &.open {
  //   right: 0;
  // }

  .customizer-content {
    position: relative;
    height: 100%;

    .custom-control {
      .custom-control-label {
        top: 0;
      }
    }
  }

  a.customizer-toggle {
    border-radius: 15px 0 0 15px;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(90deg, $brand-blue1, $brand-blue2);
    color: $white;
    text-align: center;
    padding: 0.8em 1.2em;
    font-size: 1em;
    font-weight: 500;
    line-height: 2.7em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    box-shadow: inset 0 0 rgba(10, 24, 55, 0), inset 0 0 rgba(10, 24, 55, 0);
    border: 0;

    &:hover {
      box-shadow: inset -15px 13px 30px rgba(10, 24, 55, .3), inset 20px -55px 30px rgba(10, 24, 55, .3);
      color: $white;
    }
  }

  a.customizer-close {
    color: rgba($color: $black, $alpha: 0.7);
  }

  .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10;
  }

  .customizer-toggle {
    position: absolute;
    top: calc(40% + 20px);
    width: 54px;
    height: 50px;
    left: -54px;
    text-align: center;
    line-height: 56px;
    cursor: pointer;
  }

  .color-options {
    a {
      white-space: pre;
    }
  }

  .customizer-sidebar-options .active {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
  }
}

.custom-synthesis {
  h4 {
    font-size: 24px !important;
    color: $text-color;
    padding: 0px 0px 0px 10px;
    margin-bottom: 9px !important;
  }
}

.custom-synthesis-content {
  .digit {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    border-color: $border-color;
    border-width: 1px;
    border-style: solid;
    outline: none !important;
    padding-bottom: 2px;
  }

  #digitsContainer {
    .prev-next {
      margin-left: 74px;

      a {
        font-size: 14px;
        color: $text-color;
      }
    }
  }

  .add-new {
    a {
      color: $primary;
    }
  }

  #token {
    font-size: 13px;
    color: $text-color;
    margin-top: 10px !important;
  }

  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    border: 4px solid #a9a9a9;
    border-top-color: #a9a9a9;
    border-left-color: #a9a9a9;
    background: #a9a9a9;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .radio-label {
    color: #a9a9a9;
  }
}

.dependent-field {
  .dependent-filed-pills {
    background: #f1f1f1;
    padding: 3px 10px;
    font-size: 13px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 3px;

    .field-delete {
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }
}

.third-step-columnlets {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8d8282;
    border-radius: 20px;
  }
}

.synthesized-text {
  p {
    color: #60b555;
    font-size: 14px !important;
  }
}

.after-before-table {
  .p-datatable-tbody {
    border: 1px solid $border-color;
    border-radius: 0px 0px 10px 10px;
  }
}

.after-data {
  color: #34a853;
}

.p-datatable .p-datatable-tbody {

  .before-table,
  .after-table {
    >tr {
      background: $white;
      color: #495057;
      transition: box-shadow 0.2s;

      >td {
        background: #fff;
        padding: 0.5rem 3rem;
        cursor: pointer;
      }
    }
  }
}

.semantic-group {
  font-size: 16px;
  margin: 0 0 10px;

  i {
    line-height: 1.6;
  }

  .group-label {
    padding-left: 11px;
    color: black !important;
  }

  .value {
    p {
      font-size: 16px !important;
    }
  }
}

.show-const {
  margin-left: -5px;

  .apply-const {
    font-size: 16px;
    color: black;
  }

  span {
    i {
      font-size: 24px;

      &.p-dropdown-clear-icon {
        font-size: 14px;
      }
    }
  }
}

.clicked-columnlets {
  color: rgb(100, 99, 99) !important;
}

.synthesized-running-text {
  p {
    font-size: 14px !important;
    color: $text-color;
  }

  .dependent-field {
    p {
      font-size: 16px !important;
      color: $text-color;
    }
  }
}

.confirm-data-content {
  p {
    color: #908c94 !important;
  }
}

.confirm-data-title {
  h2 {
    font-size: 30px;
  }
}

.sub-title {
  margin-left: 18px;

  h4 {
    font-size: 30px;
    font-weight: 400;
    color: $text-color;
  }

  p {
    font-size: 12px;
    display: block;
    color: $text-color;
  }

  .page-content {
    h3 {
      font-size: 18px;
      font-weight: bold;
      color: $text-color;
    }

    p {
      font-size: 12px;
      color: $text-color;
    }

    .dialogdata {
      font-size: 15px !important;
      color: $text-color;
      margin: 0px;
    }

    label {
      font-size: 12px;
      color: $text-color;
    }

    input {
      height: 40px !important;
    }

    .mendatory {
      color: $primary;
    }

    .test {
      i {
        color: $primary;
        font-size: 16px;
      }

      a {
        color: $text-color;
      }
    }
  }
}

#email-desc {
  display: none;
}

.visually-hidden {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.check-bias-popup {
  iframe {
    width: 100%;
    height: 500px;
  }

  .bottom-end-btn {
    width: 515px;
    margin: 0 auto;
  }

  .new-connection {
    a {
      font-size: 16px;
    }
  }

  .check-bias-btns {
    .p-drop {
      min-width: 200px;
      max-width: 200px;
    }

    .btn-secondary {
      border: 0 !important;
      color: $primary;
      margin-left: 5px;
      transition: 0.5s;
      background-color: transparent;

      em {
        font-size: 1.5rem;
        font-weight: 900;
      }

      &:hover {
        background: transparent;
        color: $black;
        border: 1px !important;
      }
    }
  }

  .p-datatable {
    table {
      border: solid 1px #dfdfdf;
      border-collapse: collapse;
    }
  }
}

.check-popup {
  width: 80% !important;
  height: 700px;
}

.correct-bias {
  padding: 0 60px;
}

.setup-job {
  width: 80%;
  background: $white;
  padding: 32px 40px;
  border-radius: 8px;
  // border: solid 1px $border-color;
  //box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 15px 0px #00000026;

  .page-content {
    h3 {
      font-size: 24px;
      font-weight: 400;
      color: $text-color;
      padding-left: 0;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      color: $text-color;
      padding-left: 0;
    }

    p {
      font-size: 14px !important;
      color: #666;
      margin-bottom: 0px;

      &.doc-msg {
        span {
          color: #0073ff;
        }
      }
    }

    label {
      font-size: 14px;
      color: $text-color;
      font-family: $font-family-base;

      &.label-llm {
        line-height: 20px;

        .labelName {
          display: block;

          &.sub {
            font-size: 12px;
            color: #828282;
          }
        }
      }
    }

    input {
      height: 40px;
      border-radius: 6px;
    }

    .selectConnection {
      .p-dropdown {
        width: 309px !important;
      }
    }
  }

  .mendatory {
    color: $primary;
  }

  .p-dialog {
    .p-dialog-content {
      padding: 35px 180px 100px !important;
    }
  }

  .new-connection {
    padding-top: 14px;
    color: $text-color;

    a {
      font-size: 16px;
      font-family: $font-family-base;
      color: $text-color;

      &:hover {
        color: $primary;
      }
    }
  }
}

.clearfix {
  font-size: 14px !important;
  color: #000 !important;
}

.job-details {
  .border_bottom {
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0px;
    }
  }

  .job-detail-breadcrumb {
    position: absolute;
    left: -142px;
  }

  .row-data {
    padding: 46px 0px 16px;
    margin-right: 1px;
    border-bottom: 1px solid $border-color;
  }

  .row-block {
    :last-child {
      border-bottom: 0px;
    }
  }
}

.d {}

.p-button {
  &.p-fileupload-choose {
    // background-color: $white;
    // color: $font-color;
    text-align: center;
    border-radius: 50em;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2em;
    background-color: rgba($color: $brand-dark-blue, $alpha: 1.0);
    color: white;
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    // transition: color .5s cubic-bezier(.25, .46, .45, .94), box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    // box-shadow: inset 0 0 rgba(0, 162, 255, 0), inset 0 0 rgba(0, 115, 255, 0), 0 10px 10px -5px rgba(0, 0, 0, .05);
    // border: 1px solid rgba($color: $brand-blue1, $alpha: 0.2);

    &:hover {
      box-shadow: inset -15px 13px 30px 0 $brand-blue2, inset 20px -55px 30px 0 $brand-blue01, 0 10px 10px -5px rgba(0, 0, 0, .05);
      color: $white;

      i {
        color: $white;
      }
    }

    &:focus {
      border-color: $brand-blue1 !important;
      // color: $font-color !important;
    }

    &:not(.p-disabled):hover {
      // box-shadow: inset -15px 13px 30px 0 $brand-blue2, inset 20px -55px 30px 0 $brand-blue01, 0 10px 10px -5px rgba(0, 0, 0, .05);
      // color: $white;
      box-shadow: inset -15px 13px 30px rgb(255, 255, 255), inset 20px -55px 30px rgb(255, 255, 255);
      color: #202124;
      border: 1px solid #0073ff;
    }

    .p-button-icon-left {
      font-size: 10px;
    }
  }

  .p-button-icon-left {
    position: absolute;
    margin: 0px 10px;
    right: 0px;
    top: 11px;
    width: 13px;
  }

  .p-button-label {
    font-weight: 400;
    margin-left: -12px;
  }
}

.find-org {
  .p-dropdown-panel {
    .p-dropdown-items-wrapper {
      max-height: 96px !important;
    }
  }

  .p-dropdown-items-wrapper {
    overflow: auto;
    height: 97px !important;
  }
}

.rule-row {
  height: 100px;
  overflow: scroll;
}

.team {
  position: relative;
  display: inline-block;

  .edit {
    padding-top: 7px;
    padding-right: 7px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .edit a {
    color: #000;
  }

  .dropdown {
    .dropdown-toggle {
      &::after {
        display: none !important;
      }
    }

    a {
      .pi-ellipsis-v {
        font-size: 13px !important;
        font-weight: 900 !important;
        line-height: 2.4 !important;
        color: #0c1d37 !important;

        &:hover {
          color: #ababab !important;
        }

        &:focus {
          border: none;
        }
      }
    }

    .approve {
      i {
        line-height: 1.4;
        color: green;
      }
    }

    .decline {
      i {
        line-height: 1.4;
        color: red;
      }
    }
  }
}

aw-wizard-navigation-bar {
  &.horizontal {
    ul {
      &.steps-indicator {
        .large-filled-symbols {
          ul {
            &.steps-indicator {
              padding: 74px 0 0px 0 !important;
            }
          }
        }

        >li {
          a {
            .label {
              display: none;
            }
          }
        }
      }
    }

    &.large-filled-symbols {
      ul {
        &.steps-indicator {
          padding: 0px 0 0px 0;
          margin: 60px 0 10px;
          max-width: 300px;

          li {
            .step-indicator {
              left: 0;
            }

            &:not(:last-child) {
              &:after {
                left: calc(40px + 0px);
                width: calc(100% - 40px);
                top: -30px;
              }
            }
          }
        }
      }
    }
  }
}

.p-inputtext {
  border: 1px solid rgb(215, 215, 206);
  border-radius: 8px;
}

.page-content {
  .selectConnection {
    .p-dropdown {
      width: 309px !important;
    }
  }
}

.existing-user {
  font-size: 16px;
}

.projectLevel {
  .breadcrumb-wrapper {
    padding: 35px 0px 0px 291px !important;
  }
}

.fixed-pattern {
  font-size: 24px;
  font-weight: 200;
  margin-right: 4px;
}

.end-pattern {
  font-size: 24px;
  font-weight: 200;
  margin-left: 4px;
}

.dynamic-form {
  .p-dropdown {
    .p-dropdown-label {
      line-height: 1.2 !important;
    }
  }
}

h2 {
  &.sub-title {
    font-size: 22px;
    color: $text-color;
    margin: 0 0 10px;
  }
}

.sub-title {
  p {
    font-size: 16px;
    display: block;
    color: $text-color;
  }

  .p-checkbox {
    .p-checkbox-box {
      border-radius: 0px !important;
      border: 1px solid $primary;
      width: 17px !important;
      height: 17px !important;
      background: #fff !important;
      box-shadow: none !important;

      .p-checkbox-icon {
        color: $primary !important;
        font-weight: 700;
        font-size: 12px;
      }

      .p-highlight {
        &:hover {
          border: 1px solid $primary !important;
        }
      }

      .p-focus {
        box-shadow: none !important;
      }
    }
  }

  .dynamic-form {
    .p-dropdown {
      .p-dropdown-label {
        line-height: 16px;
      }
    }
  }

  .p-field-checkbox {
    label {
      margin: 0px 50px 0px 8px;
    }
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: $primary !important;
    background-color: $primary !important;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $primary !important;
    background-color: $primary !important;
    box-shadow: none !important;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: $primary !important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $primary !important;
  }

  .columlet {
    background-color: $white;
    border: solid 1px $border-color;
    border-radius: 16px;

    .columlet-btn {
      height: 52px;
      border-bottom: 1px solid #d5d5d5;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $white;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #8d8282;
        border-radius: 20px;
      }

      h6 {
        padding: 18px 12px;
        cursor: pointer;
        min-width: 111px;
        text-align: center;
        color: $font-color;
        background-color: $btn-gray;
        height: 51px;
        margin-right: 2px;
        font-weight: 500;

        &:first-child {
          padding: 18px 12px;
          cursor: pointer;
          min-width: 111px;
          text-align: center;
          color: $brand-blue1 !important;
          background-color: $btn-gray;
          height: 51px;
          border-radius: 15px 0px 0px;
          margin-right: 2px;
          font-weight: 500;
        }
      }
    }

    .confirm-synthesis {
      padding: 22px 25px 25px;

      label {
        font-weight: 400;
        font-size: 16px;
        color: $text-color;
      }
    }
  }

  .checkedClass {
    form {
      padding: 0px 52px 45px;
    }
  }

  .highlighted {
    form {
      padding: 0px !important;
    }
  }

  .set-job {
    label {
      font-weight: 400;
      font-family: $font-family-base;
      font-size: 16px;
      color: $text-color;
    }

    ul {
      padding: 0;
      list-style: none;
    }

    ul {
      padding: 0;
      list-style: none;
    }
  }
}

.report-card {
  border-bottom: 1px solid $border-color;
  margin-bottom: 40px;
  padding-bottom: 40px;

  &:last-child {
    border-bottom: none;
  }

  h3 {
    font-size: 24px;
    text-transform: capitalize;
  }

  .report-image {
    width: 900px;
    margin: 0 auto;

    iframe {
      width: 100%;
      height: 500px;
    }

    img {
      width: 900px;
    }
  }
}

.projectnamelabel {
  text-wrap: nowrap;
}

.ptn-btn {
  margin-top: 33px;
  margin-bottom: 40px !important;
}

.add-ptn {
  font-size: 20px;
  font-weight: 400;
}

.field_length {
  text-align: center;
  margin-bottom: 20px;

  h3 {
    font-size: 16px;
    font-weight: normal;
  }
}

.pattern-name {
  margin-top: 10px;
}

.add-semantic-dependent {
  height: 440px;

  .fields-block {
    border: 1px solid $border-color;
    padding: 0;
    border-radius: 8px;
  }

  .table-data {
    list-style-type: none;

    li {
      padding: 6px 0px 6px 49px;
      margin-top: 8px;
      cursor: pointer;
      font-size: 14px;

      i {
        float: right;
        margin-top: 6px;
      }
    }

    .table-name {
      display: flex;

      i {
        line-height: 1.2;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
      }

      h3 {
        font-size: 16px;
        font-weight: normal;
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }

      &:hover {
        color: $primary;
      }
    }
  }

  .highlighted {
    background-color: #faf6f3;

  }

  .customer-add {
    background: $brand-color;
    color: white;
    padding: 10px 16px;
    position: sticky;
    top: 0;
    border-radius: 8px 8px 0px 0px;
  }

  .customer ul li {
    padding: 5px 10px 5px 53px;
  }

  .customer {
    border: 1px solid $border-color;
    border-radius: 8px;

    span {
      color: $text-color;
      font-weight: bold;
    }
  }

  .semantic {
    // max-width: 29%;
    margin: 0 20px;
    height: 511px;
    background-color: white;
    padding: 0px 15px;
    border-radius: 8px;
  }

  .customer-add-table {
    background: $brand-color;
    background-image: linear-gradient(20deg, #3D348B, #0073ff);
    color: white;
    font-family: $font-family-base;
    font-size: 16px;
    padding: 16px 26px;
    margin: -1px !important;
    border-radius: 8px 8px 0px 0px;
    background-image: (20deg, $brand-blue01, $brand-blue2);
  }

  .semantic-dependent {
    width: 100%;
    padding: 14px 20px 20px 14px;
    height: 396px;
  }
}

.rule-data-tablename {
  padding: 11px 22px;
}

.rule-handler {
  overflow-x: scroll;
}

.att-container {
  height: 700px;
  overflow: scroll;
}

.views {
  margin-left: -36px;
}

.save-pattern {
  .btn-secondary {
    border: 1px solid #8c8c8c !important;
    background-color: transparent !important;
    border-radius: 20px;
    font-size: 16px;
    color: $text-color !important;
    font-weight: 500;

    &:hover {
      border-color: #ff9149;
      color: #ff9149;
    }
  }
}

.phi-data {
  h3 {
    font-size: 16px !important;
    font-weight: 400;
  }

  p {
    font-size: 12px !important;
  }

  .phi-box {
    background: $white;
    padding: 0px 15px 26px;
    border-radius: 8px;
    border: solid 1px $border-color;

    .phi-table-name {
      background: $nav-bg;
      border-radius: 10px 10px 0px 0px;
      padding: 10px 0px;
      margin-bottom: 12px;
      color: $font-color;
    }

    .phi-table-data {
      font-size: 16px;
      font-weight: 500;
    }

    .phi-table-value {
      span {
        font-size: 12px;
      }
    }
  }
}

.assign-semantic {
  .table-box {
    min-height: 441px;
    background: white;
    padding: 0;
    position: relative;
    border-radius: 6px 6px 0px 6px;
    z-index: 1;
    box-shadow: none;
    border: solid 1px rgba($color: $brand-blue1, $alpha: 0.5);
    /* border-bottom: 1px solid $primary-liter; */
  }

  .table-data {
    list-style-type: none;
    padding-left: 0.5rem;

    li {
      padding: 6px 0px 6px 31px;
      margin-top: 8px;
      font-size: 14px;

      &:hover {
        color: $primary;
      }

      i {
        float: right;
        line-height: 1.5;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        margin-right: 8px;

        &.ft-eye {
          float: right;
          line-height: 1.8;
          font-size: 14px;
          font-weight: normal;
          cursor: pointer;
          margin-right: 8px;
        }
      }
    }

    .table-name {
      display: flex;

      &.semantic-table {
        i {
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
          margin-right: 5px;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .tabular-data {
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .highlighted {
    background-color: #f1f1f1;
  }

  .customer-add {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(20deg, $brand-blue01, $brand-blue2);
    color: white;
    font-family: $font-family-base;
    font-size: 16px;
    padding: 16px 43px;
    border-radius: 6px 6px 0px 0px;

    span {
      color: white !important;
      cursor: pointer;
    }
  }

  .customer ul li {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    border-radius: 4px;

    .ftuser {
      color: $brand-color;
      padding: 0px 5px 0px 0px;
    }

    .showIcon {
      padding: 0px 0px 0px 24px;
    }
  }

  .customer span {
    color: $text-color;
    font-weight: bold;
  }

  .semantic {
    margin: 0 20px;
    height: 511px;
    background-color: white;
    padding: 0px 15px;
    border-radius: 8px;
  }

  @keyframes dot-keyframes {
    0% {
      opacity: 0.4;
      transform: scale(1, 1);
    }

    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }

    100% {
      opacity: 0.4;
      transform: scale(1, 1);
    }
  }

  .loading-dots {
    text-align: center;
    width: 100%;

    &--dot {
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: #ee0d08;
      border-radius: 10px;
      display: inline-block;
      height: 10px;
      width: 10px;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }

  // .p-dialog {
  //   max-height: 560px;
  //   height: 560px !important;
  // }
}

.first-screen-btn {
  width: auto !important;
}

.disablelabel {
  pointer-events: none;
}

.p-component {
  font-family: $font-family-base;
}

.p-dataview {
  .p-dataview-loading {
    .p-component-overlay {
      background-color: transparent;
      transition-duration: 0.2s;
      top: 150px;
      z-index: 9998 !important;
    }
  }

  .p-dataview-header {
    background: none;
    border-width: 0px;
    font-weight: 700;
    padding: 0rem 0;
    margin-bottom: 50px;

    .page-title {
      &.title {
        h1 {
          font-size: 26px;

          .project-count {
            font-size: 16px;
            color: #adb5bd;
            display: inline-block;
            padding-top: 6px;
            padding-left: 5px;
          }
        }

        border-bottom: 0px;

        .search-box-container {
          &.joblist {
            input[type="search"] {
              width: 579px;
              padding: 16px 45px 16px 145px;
              height: 50px;
              border-radius: 30px;
              border: 1px solid $border-color;
              outline: none !important;
              box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

              &:hover {
                box-shadow: 0px 1px 5px rgba(255, 101, 1, 0.35) !important;
              }

              &:focus {
                box-shadow: 0px 1px 5px rgba(255, 101, 1, 0.35) !important;
              }
            }
          }

          .p-dropdown {
            position: absolute !important;
            z-index: 1;
            padding: 3px 10px;
            height: 50px !important;
            border-radius: 40px 0px 0px 40px !important;
            border: 1px solid $border-color !important;

            &.p-focus {
              box-shadow: none !important;
            }

            .p-dropdown-label {
              padding-right: 0px;
            }
          }
        }

        span {
          &.p-input-icon-left {
            >i {
              right: 26px;
              color: #adb5bd;
              top: 44%;
              font-size: 20px;
              left: auto;
            }

            input[type="search"] {
              width: 579px;
              padding: 16px 45px 16px 25px;
              height: 50px;
              border-radius: 30px;
              border: 1px solid $border-color;
              outline: none !important;
              box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

              &:hover {
                box-shadow: 0px 1px 5px rgba($color: $border-color, $alpha: 0.4) !important;
              }

              &:focus {
                box-shadow: 0px 1px 5px rgba($color: $border-color, $alpha: 0.4) !important;
              }
            }
          }
        }
      }
    }
  }
}

.toast-message {
  z-index: 10000 !important;
}

.mobile-menu {
  margin-top: 23px;
}

.previous-data {
  margin-bottom: 17px;
  margin-top: 13px;

  h4 {
    font-size: 16px !important;
    font-weight: 500;
  }

  span {
    font-size: 15px !important;
    margin-right: 63px;
    margin-left: 10px;
  }
}

.refresh-btn {
  background-color: $white;
  color: $font-color;
  text-align: center;
  border-radius: 50em;
  padding: 0.7em 1.4em;
  font-size: .94em;
  font-weight: 500;
  line-height: 1.2em;
  transition: color .5s cubic-bezier(.25, .46, .45, .94), box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  box-shadow: inset 0 0 rgba(0, 162, 255, 0), inset 0 0 rgba(0, 115, 255, 0), 0 10px 10px -5px rgba(0, 0, 0, .05);
  border: 0;

  &:hover,
  &:active {
    box-shadow: inset -15px 13px 30px 0 $brand-blue2, inset 20px -55px 30px 0 $brand-blue01, 0 10px 10px -5px rgba(0, 0, 0, .05) !important;
    color: $white !important;

    i {
      color: $white;
    }
  }
}

.p-dataview {
  .p-dataview-content {
    background: none;
    color: #495057;
    border: 0 none;
    padding: 0;


    .product-item-container {
      margin-bottom: 25px;

      &:nth-child(2n-1) {
        margin-left: 0px;
        margin-bottom: 25px;
      }

      .product-list-item {
        padding: 19px 19px;
        background: $white;
        border-radius: 1.2em;
        min-height: 100px;
        /* box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1); */
        border: 1px solid rgba(0, 0, 0, 0.1);

        &:hover {
          box-shadow: 0 0 10px rgba(204, 204, 204, 1);
          transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        .product-list-detail {

          .product-name {
            h2 {
              font-size: 1.25em;
              font-weight: 500;
              line-height: 1.24em;
              transition: 0.3s;

              &:hover {
                color: $primary;
              }
            }
          }

          h3 {
            font-size: 16px;
            font-weight: 500;

            .job-status {
              background: #f7e89c;
              padding: 5px 16px;
              border-radius: 8px;
              font-size: 16px;
            }
          }
        }

        .allprojectcard {
          position: absolute;
          top: -13px;
          right: 11px;

          .header-item {
            font-size: 24px;
            border-radius: 50px;
            border: 1px solid transparent;

            &:hover {
              border: 1px solid $primary;
            }

            &.dropdown-toggle {
              &:after {
                display: none;
              }

              padding: 6px 10px;
            }
          }
        }

        .btn-view-job {
          border: 0px solid $brand-blue1;
          background-color: $btn-gray;
          color: $font-color;
          text-align: center;
          border-radius: 50em;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.2em;
          transition: color .4s cubic-bezier(.25, .46, .45, .94), box-shadow .4s cubic-bezier(.25, .46, .45, .94);
          /*  box-shadow: inset 0 0 rgba(0, 162, 255, 0), inset 0 0 rgba(0, 115, 255, 0), 0 10px 10px -5px rgba(0, 0, 0, .05); */
          padding: 0.4em 0.7em;

          &:hover {
            box-shadow: inset -15px 13px 30px 0 $brand-blue2, inset 20px -55px 30px 0 $brand-dark-blue, 0 10px 10px -5px rgba(0, 0, 0, .05);
            color: $white;
          }
        }

        .team-profile {
          &.activity {
            .team-logo {
              border-radius: 50px;
              width: 27px;
              height: 26px;
              text-align: center;
              font-size: 13px;
              font-weight: bold !important;
              display: inline-block;
              line-height: 27px;
            }
          }

          margin-top: 3px;

          span {
            &.team-logo {
              border-radius: 50px;
              width: 28px;
              height: 28px;
              text-align: center;
              font-size: 13px;
              font-weight: bold !important;
              display: inline-block;
              line-height: 25px;

              &:nth-child(1) {
                background: $white;
                color: rgba($color: $gray-300, $alpha: 1.0);
                border: 1px solid rgba($color: $gray-300, $alpha: 1.0);
                z-index: 3;
                text-transform: capitalize;
              }

              &:nth-child(2) {
                background: $white;
                color: rgba($color: $gray-300, $alpha: 1.0);
                margin-left: -5px;
                z-index: 2;
                border: 1px solid rgba($color: $gray-300, $alpha: 1.0);
                line-height: 25px;
                text-transform: capitalize;
              }

              &:nth-child(3) {
                // background: #e94235;
                // color: white;
                margin-left: -5px;
                background: $white;
                color: rgba($color: $gray-300, $alpha: 1.0);
                z-index: 1;
                // border: 1px solid white;
                border: 1px solid rgba($color: $gray-300, $alpha: 1.0);
                line-height: 25px;
                text-transform: capitalize;
              }
            }

            &.remain-team-logo {
              width: 28px;
              height: 28px;
              text-align: center;
              font-size: 12px;
              font-weight: bold !important;
              display: inline-block;
              line-height: 25px;
              // background: #707070;
              // color: $white;
              background: $white;
              color: rgba($color: $gray-300, $alpha: 1.0);
              border: 1px solid rgba($color: $gray-300, $alpha: 1.0);
              border-radius: 50px;
              margin-left: -5px;
            }
          }

          .add-user-project {
            border: 1px solid $text-color;
            border-style: dashed;
            padding: 0;
            border-radius: 50px;
            margin-left: 4px;
            width: 28px;
            height: 28px;
            text-align: center;
            color: $primary;

            &:hover {
              border: 1px solid $primary;
              border-style: dashed;
              color: $gray-666;
            }
          }
        }
      }

      .chart-image {
        width: 83px;
        height: 97px;
        margin-left: -14px;

        canvas {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .job-status {
        padding-top: 15px;
        padding-bottom: 15px;

        p {
          margin-bottom: 0 !important;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }

          .complete {
            background: $brand-green1;
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 9px;
          }

          .pending {
            background: $brand-orange;
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 9px;
          }
        }
      }

      .project-details-link {

        &.job-datails {
          text-align: right;
        }

        a {
          font-size: 14px;
          color: $text-color;
          text-decoration: underline;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .p-dataview-emptymessage {
    padding: 6.25rem 1.25rem;
    text-align: center;
    font-size: 16px;
  }
}

.project-job-list {
  .p-dataview {
    .p-dataview-content {

      .product-item-container {


        .product-list-item {
          border-radius: 16px;

          .product-list-detail {
            .activity {
              em {
                &.fa {
                  font-size: 20px;
                  cursor: pointer;
                  vertical-align: middle;
                }
              }

              .job-status-complete {
                color: $brand-green1;
              }

              .job-status-error {
                color: $brnad-red;
              }

              .job-status-running {
                color: $brand-blue1;
                animation: 1.75s linear infinite spinner-border;
              }

              .job-status-pending {
                color: $brand-orange;
              }

              .time {
                font-size: 12px;
                margin-left: 5px;
                vertical-align: middle;
              }
            }

            .project-details-link {

              a,
              span {
                text-decoration: none;
                margin-left: 10px;

              }

              a {
                font-size: 16px;
                vertical-align: middle;

                .ft-refresh-cw {
                  color: $brand-blue1;
                  vertical-align: text-top;
                }

                .ft-edit {
                  color: $brand-blue1;
                  vertical-align: text-top;
                }

                .ft-trash-2 {
                  color: $brnad-red;
                  vertical-align: text-top;
                }
              }

              span {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 100px;
                color: rgba($color: $black, $alpha: 0.5);
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                border: solid 1px rgba($color: $black, $alpha: 0.5);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

.p-tooltip * {
  font-size: 10px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.space {
  width: 4px;
}

.confirm-synthesis-data {
  .confirm-data-content {
    p {
      &.mismatch {
        color: $primary !important;
      }
    }
  }
}

.p-grid {
  &.p-nogutter {
    &.grid.grid-nogutter {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
  }
}

.prec {
  top: 30px;
  position: relative;
  font-size: 30px;
}

.circle {
  position: relative;
  top: 5px;
  left: 5px;
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin: 0 auto;
}

.customtooltip {
  .p-tooltip-text {
    background: lightgray;
    color: $text-color;
  }
}

.loader-job {
  display: inline-block;
  margin-left: 3px;
  margin-right: -4px;

  .loading-spinner {
    position: relative;
    margin: 0 auto;
    width: 10px;
    height: 10px;
  }

  .loading-spinner:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .loading-spinner__circle-svg {
    animation: loading-spinner-rotate 1.28973s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .loading-spinner__circle-stroke {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation:
      loading-spinner-dash 2s ease-in-out infinite,
      loading-spinner-color 8s ease-in-out infinite;
    stroke-linecap: round;
    stroke-width: 4px;
  }

  @keyframes loading-spinner-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loading-spinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes loading-spinner-color {

    100%,
    0% {
      stroke: $white;
    }

    40% {
      stroke: $white;
    }

    66% {
      stroke: $white;
    }

    80%,
    90% {
      stroke: $white;
    }
  }
}

.active-border {
  position: relative;
  text-align: center;
  width: 110px;
  height: 110px;
  border-radius: 100%;

  background-color: #39b4cc;
  background-image: linear-gradient(91deg, transparent 50%, #a2ecfb 50%),
    linear-gradient(90deg, #a2ecfb 50%, transparent 50%);
}

.file-test-pop {
  .p-dialog-content {
    padding: 10px 60px 60px !important;

    .file-test {
      padding: 30px;
      border-radius: 6px;
      margin-bottom: 12px;
      border: solid 1px rgb(215, 215, 206);

      .table-col-box {
        background: $btn-gray;
        padding: 5px 10px;
        display: inline-block;
        margin-bottom: 13px;
        border-radius: 10px !important;
        font-size: 16px;
        color: $text-color;
      }

      .test-data {
        h3 {
          font-size: 14px;
          color: $text-color;
          font-weight: 400;
          text-transform: uppercase;
        }

        p {
          font-size: 14px !important;
          color: $text-color;
          font-weight: 400;
          line-height: 1;
          word-break: break-all;

          &.mismatch {
            color: $primary;
          }
        }
      }
    }

    .data-head {
      padding: 30px;

      h3 {
        font-size: 14px;
        color: $text-color;
        font-weight: 400;
        text-transform: uppercase;
      }

      p {
        font-size: 14px !important;
        color: $text-color !important;
        font-weight: 400;
        line-height: 1.2;
        word-break: break-all;
      }
    }

    .file-test-heading {
      font-size: 16px;
      font-weight: 400;
      color: $text-color;
    }

    p {
      font-size: 14px !important;
    }
  }
}

#orgDropdown {
  height: 29px;
  margin-left: 36px;

  .p-dropdown {
    height: 29px !important;

    .p-dropdown-label {
      line-height: 0.4;
    }
  }
}

.confirm-job {
  .highlighted {
    background-color: #faf6f3;
  }

  .customer {
    background: white;
  }

  .customer-add {
    background: $brand-color;
    color: white;
    font-family: $font-family-base;
    font-size: 16px;
    padding: 16px 29px;
    border-radius: 9px 0px 0px 0px;
  }

  .bold-label {
    font-weight: 500 !important;
  }

  .customer ul li {
    padding: 5px 10px;
  }

  .customer span {
    color: $text-color;
  }

  .semantic {
    margin: 0 20px;
    height: 511px;
  }

  .selected-field {
    height: auto;
    z-index: 1;
    border-radius: 6px 0px 0px 6px;
    box-shadow: none;
    border: 1px solid $primary-liter;
    border-top: 0;
    border-right: 0;
  }

  .toast-container-box {
    input {
      width: 149px;
    }
  }

  #custom-target {
    position: relative;
    width: 360px;
    height: 199px;
    top: -4px;
  }

  .position-absolute {
    position: absolute !important;
  }

  .showdata-box {
    background: $white;
    padding: 20px 70px 30px;
    box-shadow: none;
    border-radius: 0 16px 16px 0px;
    border: 1px solid $primary-liter;
  }

  .model-type {
    padding: 30px 80px 30px 80px;

    // .header-type {
    //   font-size: 20px;
    //   font-weight: 700;
    // }

    .field-radiobutton {
      display: flex;
      align-items: center;

      label {
        margin: 0px;
        padding: 10px;
        // font-weight: 500;
        // font-size: 18px;
      }
    }

    .btn-discription {
      padding-left: 32px;
      margin-bottom: 6px;
      font-size: 14px !important;
    }
  }
}

p {
  font-size: 16px !important;
  font-family: $font-family-base;
}

.confirm-synthesis-data {
  .sample-data-state {
    /* padding: 18px 30px; */
    padding: 20px;

    h3 {
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      padding: 0 15px;
      margin-bottom: 16px;
      margin-top: 20px;
    }

    .synthesized-running-text {
      p {
        font-size: 16px !important;
        color: $text-color !important;
      }

      .dependent-field {
        p {
          font-size: 16px !important;
          color: $text-color !important;
        }
      }
    }
  }

  .sample-result {
    background: $white;
    box-shadow: none;
    border-radius: 6px;
    padding: 20px;
    border: solid 1px rgb(215, 215, 206);

    .confirm-data-content {
      p {
        font-size: 16px !important;
        color: $text-color;

        &.name {
          text-transform: uppercase;
        }
      }
    }

    .dependent-title {
      font-size: 16px !important;
      margin-bottom: 10px;
      font-weight: 500;
      padding: 0px 16px;
    }

    .table-col {
      margin-bottom: 15px;

      .table-col-box {
        background: $btn-gray;
        border-radius: 8px;
        font-size: 16px;
        color: $text-color;
        padding: 2px 6px 4px;
      }
    }
  }

  .confirm-data {
    h2 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .confirm-data-content {
    margin-bottom: 30px;
    align-items: baseline;

    .sythesizedData {
      align-items: baseline;
    }

    p {
      font-size: 16px !important;
      font-weight: normal;
      color: $text-color !important;
      margin: 0px !important;

      &.name {
        text-transform: uppercase;
      }
    }
  }

  .before-after {
    line-height: 1;

    .toggle {
      display: inline-block;
      width: 50px;
      cursor: pointer;
      margin: 0 10px;
    }

    .toggle-input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;

      &:active {
        +.toggle-track {
          .toggle-control {
            right: 14px;
          }
        }
      }

      &:checked {
        +.toggle-track {
          transition: box-shadow 180ms ease-out;
          box-shadow: inset 0 0 0 20px $primary;

          .toggle-control {
            right: 0;
            left: 20px;
          }
        }
      }

      &:checked:active {
        +.toggle-track {
          .toggle-control {
            left: 14px;
            right: 0;
          }
        }
      }
    }

    .toggle-track {
      position: relative;
      background-color: $brand-color;
      height: 30px;
      border-radius: 16px;
      transition: box-shadow 200ms ease-in;
      box-shadow:
        inset 0 0 0 0px $primary,
        inset 0 0 0 2px $brand-color;
    }

    .toggle-control {
      position: absolute;
      top: 0;
      left: 0;
      right: 20px;
      height: 26px;
      margin: 2px;
      background-color: white;
      border-radius: 26px;
      box-shadow:
        0 0 1px 1px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.2);
      transition: 200ms cubic-bezier(0.65, 1.15, 0.75, 1.25);
    }
  }

  .test-table {
    overflow-x: auto;

  }

  .test-table::-webkit-scrollbar {
    height: 8px;
    cursor: pointer;

  }

  .test-table::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 8px;
    cursor: pointer;
  }
}

.semantic-constrains {
  .customer-add {
    background: $brand-color;
    color: white;
    padding: 10px 16px;
    position: sticky;
    top: 0;
    border-radius: 8px 8px 0px 0px;
  }

  .address-add {
    background: #dadada;
    padding: 7px 16px;
    position: sticky;
    top: 40px;
  }

  .semantic-type {
    padding: 42px;
    border-radius: 8px;
  }

  .address-data {
    .address-col {
      max-height: 403px;
      overflow-x: hidden;
      overflow-y: scroll;
      list-style: none;
      width: 284px;
    }
  }

  input.apple-switch {
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 31px;
    height: 17px;
    background-color: #fff;
    border: 1px solid #d9dadc;
    border-radius: 50px;
    margin-left: 10px;
    margin-bottom: 8px;
  }

  input.apple-switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 14px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #dadada;
  }

  input.apple-switch:checked {
    box-shadow: inset 14px 0 0 0 $brand-color;
    border-color: $brand-color;
  }

  input.apple-switch:checked:after {
    left: 6px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
    display: none;
  }
}

.profile-table {
  margin-bottom: 30px;
  border: 1px solid $border-color;
  border-radius: 6px;
  padding: 0;
}

.user-title {
  font-size: 30px !important;
  font-weight: 400 !important;

  h2 {
    font-size: 30px !important;
    font-weight: 400 !important;
  }

  &.configur-db {
    padding-left: 16px;
  }
}

.download-link {
  color: $primary !important;

  i {
    margin-right: 6px;
  }
}

/* .user-input {
  width: 401px;
  margin: 20px auto 30px;
} */

.edit-user {
  i {
    line-height: 30px;
    margin-left: 10px;
    font-size: 20px;
    color: #0c1d37;

    &:hover {
      color: $primary;
    }
  }
}

.edit-profile-popup {
  margin: 0 auto;
}

.occurence {
  height: 400px;
  max-height: 400px;
  min-height: 400px;

  .semantic-type {
    border-radius: 8px;
    background: white;

    .customer-add {
      background: $brand-color;
      color: white;
      padding: 10px 30px;
      position: sticky;
      top: 0;
      border-radius: 8px 0px 0px 0px;
    }

    .customer-destination {
      background: $brand-color;
      color: white;
      padding: 10px 20px;
      position: sticky;
      top: 0;
      border-radius: 8px 8px 0px 0px;
    }

    .table-data {
      padding: 15px 5px;

      .title {
        color: #a8aabc;
        margin-left: 40px;
        float: right;
      }
    }
  }
}

.form-group {
  margin-bottom: 1rem;
  margin-top: 0;

  .test {
    line-height: 3.2;

    span {
      font-size: 16px;
    }
  }
}

.limit {
  .count-limit {
    /* padding-left: 8px;
    margin-bottom: 22px; */
  }

  p {
    font-size: 14px;

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .limit-label {
    &.bias-label {
      label {
        &:hover {
          font-size: 16px !important;
          color: #ff5a00 !important;
          font-family: $font-family-base;
        }
      }

      i {
        color: #ff5a00;
      }

      p {
        font-size: 14px !important;
      }
    }

    label {
      font-size: 16px !important;
      color: $text-color !important;
      font-family: $font-family-base;
    }
  }
}

.horizontal {
  .large-filled-symbols {
    ul {
      &.steps-indicator {
        li {
          &.current {
            .step-indicator {
              color: #fff !important;
              border: 2px solid $primary !important;
              background-color: $primary !important;
              line-height: 45px;
              font-size: 18px;
              font-weight: 600;
            }
          }

          &.done {
            .step-indicator {
              color: white !important;
              border: 2px solid $brand-color !important;
              background-color: $brand-color !important;
              line-height: 45px;
            }
          }

          .step-indicator {
            color: #999 !important;
            font-size: 19px;
            text-align: center;
            border: 2px solid $border-color !important;
            background-color: #fff !important;
            line-height: 38px !important;
            font-size: 18px;
            font-weight: 600;
            height: 40px !important;
            width: 40px !important;
          }
        }
      }
    }
  }
}

.horizontal {
  .large-filled-symbols {
    &ul {
      &.steps-indicator {
        li {
          .p-inputnumber {
            width: 295px;
          }
        }
      }
    }
  }
}

.perfect-scrollbar {
  max-height: 441px;
  min-height: 441px;

  &.afterTokenize {
    max-height: 1000px;
    /*  min-height: 1000px; */
  }
}

.p-dropdown {
  text-align: left;

  &.allDropdown {
    .p-dropdown-header {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}

.select-field-ul {
  list-style: none;
  padding: 0 22px;
}

.p-checkbox {
  .p-checkbox-box {
    // border: 2px solid #dec2ab;
    background: $white;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 6px;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
  }
}

.text-italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.variable-feilds {
  .form-group {
    .delimiter {
      .form-control {
        width: 88% !important;
      }
    }
  }
}

.skip-link {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip-link:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
}

.constraints {
  h6 {
    font-size: 20px;
    font-weight: 500;
  }
}

.text-warp {
  word-break: break-all;
}

.p-dropdown {
  cursor: pointer !important;
  position: relative !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  display: flex !important;
  border: 1px solid $border-color;
  height: 40px !important;
  border-radius: 8px !important;
  max-width: 300px;
}

.p-multiselect {
  cursor: pointer !important;
  position: relative !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  display: flex !important;
  border: 1px solid $border-color;
  height: 40px !important;
  border-radius: 8px !important;
  max-width: 300px;
}

.app-content {
  .login-box {
    .box-shadow-2 {
      border-radius: 16px;
    }

    .login {
      background: white;
      border-radius: 16px;
      max-width: 550px;
      min-width: 500px;
      max-height: 600px;
      width: 100%;

      &.register {
        max-width: 800px;
        min-width: 800px;

        .card-content {
          padding: 60px 100px;
        }
      }

      .card-content {
        padding: 60px 80px;
      }

      .card-content {
        .card-body {
          h1 {
            &.log-head {
              font-size: 28px;
              font-weight: 500;
              text-align: center;
              font-size: 32px;
              font-weight: 500;
            }
          }

          .login-btn {
            width: 100%;
            border-radius: 27px !important;
            height: 56px;
            font-size: 18px;
            margin-top: 18px;
            /* color: $white; */

            /* &:hover {
              color: $white;
            }

            &:focus {
              color: $white;
            } */

            &.outline {
              border: 1px solid #6b6f82;
            }
          }
        }

        .form-horizontal {
          .form-group {
            .p-fluid {
              .p-inputtext {
                border-radius: 8px;
                height: 40px;
                box-shadow: none;
              }

              .p-element {
                .pi {
                  position: absolute !important;
                  top: 41px !important;
                  right: 10px;
                }

                .p-input-icon-right>i {
                  margin-top: -1.95rem;
                }
              }

              .password-field {
                .eye-icon {
                  position: absolute;
                  top: 22px;
                  right: 13px;

                  &:hover {
                    color: $text-color !important;
                  }
                }
              }
            }
          }
        }

        .next-btn {
          margin-top: 160px;
        }
      }

      .cont-btn {
        position: absolute;
        bottom: 53px;
        right: 126px;
      }
    }
  }

  .create-id {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    a {
      color: #0c1d37;
      text-decoration: underline;
      font-weight: 400;

      &:hover {
        color: $primary;
      }
    }
  }
}

.icon-container {
  position: absolute;
  top: 39px;
  right: 60px;
}

.rule-icon-container {
  position: absolute;
  top: 39px;
  right: 90px;
}

.change-pwd {
  .password-field {
    .eye-icon {
      position: absolute;
      top: 22px;
      right: 13px;

      &:hover {
        color: $text-color !important;
      }
    }
  }
}

.p-dropdown .p-dropdown-label {
  color: #6c757d;
  line-height: 1.3;
}

.btn-small {
  font-size: 16px;
}

.toast-pop {
  position: absolute;
  right: 0;
  top: -20px;
  z-index: 99999;
  background: $white;
}

.p-inputwrapper {
  .p-inputnumber-input {
    width: 100%;
    // border: 1px solid #dec2ab !important;
    border: 1px solid rgb(215, 215, 206);
    height: 40px !important;
    border-radius: 8px;

  }
}

/* .add-project-popup {
  padding: 0 50px;
  margin: 0 auto;
} */

.add-project-popup-data {
  padding: 0 0 20px;
  margin: 0 auto;

  .popup-title {
    margin: 0 auto 28px;
  }

  .mb-3 {
    margin-bottom: 2rem !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pointer {
  cursor: pointer;

  &:hover {
    h2 {
      color: $primary;
    }
  }
}

.add-job-componant {
  .tab-content .tab-pane {
    padding-top: 20px;
  }

  .alert {
    padding: 8px;
    margin-bottom: 8px;
  }

  .form-control.is-invalid {
    border-color: #cacfe7;
    background-image: none !important;
  }

  :host ::ng-deep .block-ui-wrapper {
    background: rgba(255, 249, 249, 0.5) !important;
  }

  :host ::ng-deep .wizard-steps.vertical {
    width: 90%;
    margin: 0 auto;
    max-width: 1050px;
    min-height: 100vh;
  }

  :host ::ng-deep .vertical .large-filled-symbols {
    width: 10;
  }

  @media screen and (max-width: 480px) {
    .wizard-steps.vertical {
      width: 80% !important;
    }
  }

  @media screen and (max-width: 480px) {
    .vertical .large-filled-symbols {
      width: 20% !important;
    }
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li .step-indicator {
    color: #999;
    font-size: 19px;
    text-align: center;
    border: 2px solid $border-color;
    background-color: #fff;
    line-height: 45px;
    font-size: 18px;
    font-weight: 500;
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li.editing:after {
    color: #e6e6e6;
    border: 1px solid $border-color !important;
    background-color: #fff;
    line-height: 45px;
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li.editing1:after {
    color: $brand-color;
    border: 2px solid $border-color !important;
    background-color: #fff;
    line-height: 45px;
  }

  :host ::ng-deep.horizontal.large-filled-symbols ul.steps-indicator li.done .step-indicator {
    color: #ffff;
    border: 2px solid $brand-color;
    background-color: $brand-color;
    line-height: 45px;
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):before {
    background-color: $border-color;
    content: "";
    position: absolute;
    height: 2px !important;
    width: calc(100% - 50px);
    top: -25px;
    left: calc(50% + 25px);
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li.done:before {
    background-color: $border-color;
    content: "";
    position: absolute;
    height: 2px !important;
    width: calc(100% - 50px);
    top: -25px;
    left: calc(50% + 25px);
  }

  :host ::ng-deep :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.done:not(:last-child):before {
    background-color: #f4f5fa;
    height: 10px;
    line-height: 45px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.done:not(:last-child):before {
    background-color: $brand-color;
    height: 5px;
    line-height: 45px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li .step-indicator {
    color: #999;
    font-size: 18px;
    border: 2px solid $brand-color;
    background-color: #fff;
    line-height: 45px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.editing:after {
    color: #666ee8;
    border: 2px solid $brand-color !important;
    background-color: #fff;
    line-height: 45px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.current .step-indicator {
    color: #666ee8;
    border: 2px solid $brand-color;
    background-color: #fff;
    line-height: 45px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.done .step-indicator {
    color: #fff;
    border: 2px solid $brand-color;
    background-color: $brand-color;
    line-height: 45px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):before {
    background-color: #f4f5fa;
    height: 10px;
    line-height: 10px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):after {
    background-color: #666ee8;
    content: "";
    position: absolute;
    left: -25px;
    top: 50px;
    height: 10px;
    width: 1px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.current:after {
    color: #666ee8;
    border: 1px solid #f4f5fa;
    background-color: #fff;
    line-height: 40px;
  }

  :host ::ng-deep .vertical.large-filled-symbols ul.steps-indicator li.editing:after {
    color: #e6e6e6;
    border: 1px solid #e6e6e6 !important;
    background-color: #fff;
    line-height: 40px;
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li.done.step:after {
    background-color: #666ee8;
    border-color: #666ee8;
    color: #fff;
    line-height: 40px;
  }

  :host ::ng-deep .horizontal ul.steps-indicator li .step-indicator {
    position: relative;
    margin: 0px;
    text-align: center;
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator {
    color: #fff;
    border: 2px solid $brand-color;
    background-color: $brand-color;
    line-height: 45px;
    cursor: pointer;
  }

  :host ::ng-deep .btn-light:not(:disabled):not(.disabled):active {
    color: unset !important;
    background-color: unset !important;
    border-color: #d3d9df !important;
  }

  :host ::ng-deep .btn-light:hover:not(.disabled):active {
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
  }

  :host ::ng-deep .btn-light {
    color: unset !important;
    background-color: unset !important;
    border-color: unset !important;
  }

  :host ::ng-deep .bg-primary {
    background-color: #007bff !important;
  }

  :host ::ng-deep .text-white {
    color: #fff !important;
  }

  :host ::ng-deep .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  :host ::ng-deep .custom-day:active {
    color: #6d7183 !important;
    background-color: #fff !important;
    border-block-color: rgb(2, 117, 216) !important;
  }

  .bg-light {
    background-color: #f8f9fa !important;
  }

  :host ::ng-deep .hidden {
    display: block !important;
  }

  .ngb-dp-weekday {
    color: #17a2b8;
  }

  .ngb-dp-week-number,
  .ngb-dp-weekday {
    line-height: 2rem;
    text-align: center;
    font-style: italic;
  }

  .ngb-datepicker-month-view {
    pointer-events: auto;
  }

  :host ::ng-deep .ngb-dp-header {
    background-color: var(--light) !important;
  }

  :host ::ng-deep .ngb-dp-weekdays {
    background-color: var(--light);
  }

  :host ::ng-deep .ngb-dp-month-name {
    background-color: var(--light);
  }

  .small {
    font-size: 80%;
    font-weight: 400;
  }

  .ngb-dp-day {
    cursor: pointer !important;
  }

  .ngb-dp-month {
    pointer-events: none;
  }

  .btn-light:hover {
    color: #212529 !important;
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
  }

  .ngb-datepicker-month-view {
    pointer-events: auto;
  }

  .ngb-dp-header {
    border-bottom: 0;
    border-radius: 0.25rem 0.25rem 0 0;
    padding-top: 0.25rem;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 2rem;
    height: 2rem;
  }

  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  .custom-day.focused {
    background-color: #e6e6e6;
  }

  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }

  :host ::ng-deep .block-ui-wrapper {
    background: rgba(255, 249, 249, 0.5) !important;
  }

  :host ::ng-deep .horizontal.large-filled-symbols ul.steps-indicator li.current .step-indicator {
    color: #fff;
    border: 2px solid $primary;
    background-color: $primary;
    line-height: 45px;
    font-size: 18px;
    font-weight: 500;
  }

  :host ::ng-deep .large-filled-symbols ul.steps-indicator {
    padding: 20px 0 0px 0;
    width: 20% !important;
    margin: 20px 398px 0px;
  }

  :host::ng-deep.p-dropdown {
    height: 35px;
  }

  .displaynone {
    visibility: hidden;
  }

  :host::ng-deep .p-dropdown-label {
    margin-top: -4px;
    line-height: 1.9;
  }

  :host::ng-deep.btn-raised {
    border-radius: 20px;
  }

  .card-body {
    padding: 20px 250px !important;
  }

  .semantic {
    margin: 0 20px;
    height: 511px;
  }

  .customer-add {
    background: $brand-color;
    color: white;
    padding: 10px 16px;
    position: sticky;
    top: 0;
    border-radius: 8px 8px 0px 0px;
  }

  .address-add {
    background: #dadada;
    padding: 7px 16px;
    position: sticky;
    top: 40px;
  }

  .customer ul li {
    padding: 5px 10px;
  }

  .customer span {
    color: $text-color;
    font-weight: bold;
  }

  :host ::ng-deep.horizontal .wizard-steps {
    min-height: 100vh;
    margin: 0 auto;
    max-width: 1050px;
    min-height: 100vh;
  }

  .sub-title p {
    font-size: 12px;
    display: block;
    color: $text-color;
    margin-top: -10px !important;
  }

  .page-content h3 {
    font-size: 18px;
    font-weight: bold;
    color: $text-color;
  }

  .page-content p {
    font-size: 12px;
    color: $text-color;
  }

  .page-content label {
    font-size: 12px;
    color: $text-color;
  }

  :host::ng-deep.span.p-dialog-header-close-icon.ng-tns-c167-5.pi.pi-times {
    font-size: 25px;
  }

  .page-content input {
    height: 35px;
  }

  .btn-secondary:hover {
    box-shadow: 0px 0px 17px -3px rgba(107, 111, 130, 0.7);
  }

  .btn-secondary:focus {
    border-color: #6b6f82 !important;
    background-color: #6b6f82 !important;
    box-shadow: none !important;
  }

  :host::ng-deep.p-dialog-header-icon {
    height: 3rem !important;
    width: 3rem !important;
  }

  :host::ng-deep.p-dialog-header-icon .pi-times {
    font-size: 30px !important;
  }

  :host::ng-deep.p-dialog-header-icon:enabled:hover {
    background: none !important;
  }

  :host::ng-deep.p-dialog-header-icon:focus {
    box-shadow: none !important;
  }

  :host::ng-deep.back-btn {
    border-color: #6b6f82 !important;
  }

  .back-btn:hover {
    color: $primary;
  }

  .back-btn:focus {
    border-color: #6b6f82 !important;
    box-shadow: none !important;
  }

  :host::ng-deep.address-col ul {
    list-style: none !important;
    padding: 0 !important;
  }

  .highlighted {
    background-color: #faf6f3;
  }

  .selected-field {
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    border-radius: 8px;
  }

  @media (min-width: 320px) and (max-width: 520px) {
    :host ::ng-deep.horizontal .wizard-steps {
      padding: 0px 8px !important;
    }

    :host ::ng-deep .large-filled-symbols ul.steps-indicator {
      width: 100% !important;
      margin: 6px 0px 0px;
    }

    :host ::ng-deep .horizontal {
      padding-top: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    :host ::ng-deep.horizontal .wizard-steps {
      padding: 10px 56px !important;
    }

    :host ::ng-deep .large-filled-symbols ul.steps-indicator {
      width: 38% !important;
      margin: 20px 34px 0px;
    }
  }

  @media (min-width: 992px) and (max-width: 1400px) {
    :host ::ng-deep .large-filled-symbols ul.steps-indicator {
      margin: 20px 254px 0px;
    }

    :host::ng-deep.p-dropdown {
      width: 240px;
      height: 35px;
    }
  }

  @media (max-width: 1440px) {
    :host ::ng-deep.horizontal .wizard-steps {
      padding: 20px 282px !important;
    }

    :host ::ng-deep .large-filled-symbols ul.steps-indicator {
      margin: 20px 254px 0px;
    }
  }
}

.btn-warning {
  background-color: rgba($color: $brand-dark-blue, $alpha: 1.0);
  /* background-image: linear-gradient(20deg, $brand-blue01, $brand-blue2); */
  color: $white;
  text-align: center;
  border-radius: 50em;
  padding: 0.8em 1.6em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2em;
  transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  box-shadow: none;
  border: 1px transparent solid;

  &:hover {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    color: $font-color;
    border: 1px solid $brand-blue2;
    background-color: $white;
  }

  &:focus {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    color: $font-color;
    border: 1px solid $brand-blue2;
    background-color: $white;
  }

  &:active {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    color: $font-color !important;
    border: 1px solid $brand-blue2 !important;
    background-color: $white !important;
  }

  &:disabled {
    color: $gray-666;
  }

  .spinner {
    margin: 0px 5px 0px 0px;
    width: 1em;
    height: 1em;
    border: 0.1em solid rgba(0, 0, 0, 0.1);
    border-left-color: $white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.btn-primary {
  background-color: rgba($color: $brand-dark-blue, $alpha: 1.0);
  /* background-image: linear-gradient(20deg, $brand-blue01, $brand-blue2); */
  color: $white;
  text-align: center;
  border-radius: 50em;
  padding: 0.8em 1.6em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2em;
  transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  box-shadow: none;
  border: 1px transparent solid;

  &:hover {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    color: $font-color;
    border: 1px solid $brand-blue2;
  }

  &:focus {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    border: 1px solid $brand-blue2;
  }

  &:disabled {
    color: #000;

    .spinner {
      border-left-color: #0e0e0e !important;
    }
  }

  // em {
  //   &.las {
  //     font-size: 20px;
  //   }
  // }

  .spinner {
    margin: 0px 5px 0px 0px;
    width: 1em;
    height: 1em;
    border: 0.1em solid rgba(0, 0, 0, 0.1);
    border-left-color: $white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.chat-dropdown {
  width: 243px;

  &.job-selection {
    p-dropdown {
      display: flex;
      justify-content: end;
      width: 100%;

      .p-dropdown {
        &.p-component {
          width: 100%;
        }
      }
    }
  }
}

.btn-outline-amber {
  background-color: rgba($color: $brand-dark-blue, $alpha: 1.0);
  color: $white;
  text-align: center;
  border-radius: 50em;
  padding: 0.8em 1.6em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2em;
  transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  box-shadow: none;
  border: 1px transparent solid;

  &:hover {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    color: $font-color;
    border: 1px solid $brand-blue2;
  }

  &:focus {
    box-shadow: inset -15px 13px 30px rgba(255, 255, 255, 1), inset 20px -55px 30px rgba(255, 255, 255, 1);
    color: $font-color;
    border: 1px solid $brand-blue2;
  }

  em {
    &.las {
      font-size: 20px;
    }
  }
}

.btn-secondary {
  background-color: $brand-gray;
  color: $font-color;
  text-align: center;
  border-radius: 50em;
  padding: 0.7em 1.4em;
  font-size: .94em;
  font-weight: 500;
  line-height: 1.2em;
  transition: color .5s cubic-bezier(.25, .46, .45, .94), box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  border: 1px solid rgba($color: $black, $alpha: 0.1);

  &:hover {
    background-color: rgba($color: $brand-dark-blue, $alpha: 1.0);
    color: $white;
    border-color: rgba($color: $brand-dark-blue, $alpha: 1.0);

    i {
      color: $white;
    }
  }

  &:focus {
    border-color: $brand-blue1 !important;
    color: $font-color !important;
  }

  .spinner {
    margin: 0px 5px 0px 0px;
    width: 1em;
    height: 1em;
    border: 0.1em solid rgba(0, 0, 0, 0.1);
    border-left-color: $text-color;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  // em {
  //   &.las {
  //     font-size: 20px;
  //   }
  // }
}

.add-job-btn {
  i {
    margin-right: 5px;
  }
}

.count-row-limit {
  color: $text-color !important;
  font-size: 16px !important;
  font-weight: 500;
  font-family: $font-family-base;
}

.btn-secondary-noborder {
  border: 0px !important;
  background-color: transparent !important;
  border-radius: 20px;
  color: $text-color !important;
  font-weight: 500;
  font-size: 16px;

  i {
    color: $primary;
  }
}

#drpdAllProjectCard {
  .dropdown-menu {
    opacity: 1;
    display: block;
    left: 22px !important;

    .dropdown-item {
      &:active {
        color: #fff;
        text-decoration: none;
        background-color: $brand-color;
      }
    }
  }
}

.dropdown-menu {
  z-index: 1108;
}

.p-datatable {
  .p-datatable-header {
    background: transparent;
    color: #343a40;
    border: 0px solid #dee2e6;
    padding: 40px 0px 20px;
    font-weight: 700;
    justify-content: end;
    display: flex;

    .p-multiselect {
      height: 40px;
      line-height: 24px;
      min-width: 200px;
      line-height: 1.2;
      border: 1px solid #dec2ab;

      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      .p-multiselect-panel {
        .p-multiselect-header {
          .p-checkbox {
            display: none;
          }

          .p-multiselect-close {
            display: none;
          }

          .p-multiselect-filter-container .p-inputtext {
            height: 40px;
          }
        }
      }
    }

    .p-dropdown {
      height: 45px;
      width: 200px;
      line-height: 1.3;
      box-shadow: none !important;

      .p-dropdown-items-wrapper {
        height: 97px !important;
      }

      .p-dropdown-items {
        height: 97px;
      }
    }

    .filter {
      display: flex;

      h6 {
        margin-bottom: 0;
        line-height: 3;
        color: $text-color;
      }

      .by {
        width: 15px;
      }

      a {
        color: #ababab;
        margin: 12px 26px;
        font-weight: normal;

        &:hover {
          color: $text-color;
        }
      }
    }

    a {
      line-height: 3;
      margin-right: 21px;
      color: #ababab;
    }
  }

  .p-datatable-thead>tr>th {
    background: #dee2e6;
    color: $font-color;
    padding: 0.9rem 3rem;
  }

  .p-datatable-thead>tr>th {
    &:first-child {
      border-radius: 6px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 6px 0px 0px;
    }
  }

  .p-datatable-tbody>tr>td {
    background: $white;
    padding: 0.8rem 3rem;
    word-break: break-all;

    .allprojectcard {
      .btn {
        font-size: 24px;
        border: none;

        .ft-arrow-right {
          color: $primary;
        }

        &.dropdown-toggle::after {
          content: none;
        }
      }

      .dropdown {
        .dropdown-menu {
          .dropdown-item {
            padding: 9px 20px;
            width: 100%;
            height: 40px;
          }
        }
      }
    }
  }

  &.prime-table {
    .p-datatable-loading-overlay {
      bottom: 0;
      height: calc(100% - 120px);
      background-color: transparent;
      top: unset;
    }
  }
}

.dropdown-toggle {
  &.btn {
    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }

    &:focus-visible {
      border: none;
      outline: transparent;
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input::placeholder {
  font-weight: normal;
  color: #6c757d;
}

.project-title {
  padding-top: 5px;
}

li {
  &.nav-item {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px !important;
    }
  }
}

.btn {
  border-radius: 30rem;
  padding: 0.75rem 2rem;
  font-weight: normal;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.ft-refresh-cw {
  &:hover {
    color: $primary;
  }
}

#rotate-icon {
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.global-serch {
  width: 208px;
  padding-left: 37px;
  border: 1px solid #dec2ab;
  border-radius: 6px;
}

.page-main {
  font-size: 16px;
  color: #2c303b;
  margin-bottom: 20px;
  min-height: 100%;
  margin: auto;

  &.pages {
    .detail-label {
      width: 33.33%;
    }

    .datail-info {
      width: 33.33%;
    }

    .page-title {
      margin-bottom: 25px;

      h1 {
        font-size: 26px;
        color: $text-color;
        font-weight: 500;
      }

      p {
        font-size: 16px;
        display: block;
        color: #666;
        margin-top: 0px !important;

        a {
          color: #666;
        }
      }
    }

    .first-step-button {
      width: calc(80% + 29px);
    }

    .personal-details {
      padding: 50px 8px 40px;
      border-bottom: 2px solid #d0d0d0;

      h4 {
        &.label-title {
          display: inline-block !important;
          width: 28%;
          text-transform: inherit;

          &:nth-child(4) {
            width: 16%;
          }

          i {
            font-size: 18px;
            margin-right: 6px;
            vertical-align: text-top;
          }

          &.change-password {
            color: $brand-blue1;
            // text-transform: capitalize;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.pages {
    .project-details-block {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      background: white;
      border-radius: 8px;
      padding: 14px 16px;

      h2 {
        font-size: 24px;
        font-weight: normal;

        span {
          font-weight: 200;
          font-size: 16px;
        }
      }

      .chart-image {
        width: 83px;
        height: 97px;
        margin-left: -7px;

        canvas {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }

    .project-details-team-block {
      padding-top: 46px;
      padding-bottom: 33px;

      .role-management {
        flex-wrap: wrap;
      }

      h2 {
        font-size: 22px;
        font-weight: 400;

        span {
          font-size: 16px;
          color: #adb5bd;
        }

        i {
          font-size: 18px;
          font-weight: normal;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      /* .team {
        img {
          width: 75px;
          height: 75px;
          object-fit: cover;
          margin-bottom: 10px;
        }

        h6 {
          font-weight: 500;
        }

        p {
          font-weight: normal;
          font-size: 14px !important;
        }
      } */
    }

    .project-details-heading {
      margin-bottom: 18px;

      .companyLogo {
        border: 1px solid #0A183726;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        background: #0a183712;

        img {
          padding: 0px;
        }

        .pi-user {
          font-size: 3.5rem;
          color: #979da5;

        }
      }

      .header-item {
        font-size: 24px;
        border-radius: 50px;

        &:hover {
          border: 1px solid $primary;
        }

        &.dropdown-toggle {
          &:after {
            display: none;
          }

          padding: 6px 10px;
        }
      }

      h1 {
        font-size: 26px;
        color: $text-color;
      }
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: $text-color;
  }

  h4 {
    font-size: 28px;
    color: $text-color;
  }

  .tabular-data {
    font-size: 28px;
    color: $text-color;
  }

  p {
    /* font-size: 12px; */
    display: block;
    color: $text-color;
    margin-bottom: 0px;
  }

  .title {
    margin-bottom: 25px;
    /* border-bottom: 1px solid $border-color; */

    h5 {
      font-size: 30px;
      padding-top: 5px;
      font-weight: 400;

      i {
        font-size: 18px;
        margin-left: 10px;
        font-weight: normal;
        cursor: pointer;
      }
    }

    img {
      width: 50px;
      height: 50px;
      padding: 0px 8px 10px 0px;
      margin-left: -3px;
    }
  }

  .page-data-card {
    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    .project-details-block {
      height: 200px;

      .btn-view-job {
        border: 1px solid $primary;
        color: $text-color;
        border-radius: 20px;
        font-size: 13px;
        width: 100px;
        height: 30px;
        padding: 0;

        &:hover {
          background: $primary;
          color: #332f2a;
        }
      }

      .best-result {
        margin-top: 20px;

        a {
          color: $primary;

          span {
            display: block;
            margin-bottom: 5px !important;
            font-size: 14px !important;
          }
        }
      }

      .job-status {
        padding-top: 16px;

        p {
          .complete {
            background: $brand-color;
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 9px;
          }

          .pending {
            background: $primary;
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 9px;
          }

          margin-bottom: 0 !important;
        }
      }
    }
  }

  .refresh {
    font-size: 24px;
    font-weight: 400;

    i {
      font-size: 18px;
      font-weight: normal;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .page-data {
    border: 0px solid $border-color;
    background: $white;
    border-radius: 16px;
    padding: 30px 0px;

    .job-data {
      border-bottom: solid 1px $gray-100;
      margin-bottom: 30px;

      &:last-child {
        border-bottom: 0;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      word-wrap: break-word;
      margin-bottom: 30px;

      a {
        color: $text-color;
        text-decoration: underline;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 400;

      i {
        font-size: 18px;
        font-weight: normal;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    &.myteam {
      padding: 40px 40px;
      // border: solid 1px rgb(215, 215, 206);
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
      height: fit-content;
    }

    .team {
      padding: 5px;
      // text-align: center;
      // width: 50px;
      // height: 50px;
      width: 75px;
      height: 75px;
      // margin: 0 auto;
      border-radius: 100%;
      border: solid 1px $gray-666;
      margin-right: 8px;
      color: $gray-666;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border: solid 1px $primary;
        color: $primary;
      }

      .dropdown-toggle {
        &:after {
          content: none;
        }
      }

      span {
        font-size: 28px;
        font-weight: normal;
        // line-height: 38px;
        // display: block;
      }

      img {
        width: 75px;
        height: 75px;
        object-fit: cover;
        margin-bottom: 10px;
      }

      p {
        font-weight: normal;
        font-size: 14px;
      }
    }

    p {
      &.username {
        font-weight: 500;
      }

      &.userrole {
        font-size: 14px !important;
      }
    }

    .project {
      background: $white;
      border-radius: 8px;
      margin-bottom: 30px;
      padding: 0;
      box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.08);

      .project-row {
        padding: 30px 15px;
        text-align: center;

        .project-name {
          font-size: 14px;

          &:hover {
            color: $primary;
          }
        }
      }

      .divider {
        height: 1px;
        background: #dadada;
      }
    }

    .project-table {
      margin-bottom: 30px;

      .p-datatable {
        .p-datatable-thead>tr>th {
          background: $btn-gray;
          color: $font-color;
          font-weight: normal;
          font-size: 16px;
          text-transform: uppercase;
        }
      }

      .p-datatable {
        .p-datatable-tbody>tr>td {
          cursor: pointer;

          &:first-child {
            font-weight: normal;
            font-size: 14px;
            text-transform: uppercase;
          }
        }
      }

      h6 {
        margin: 0px !important;
      }

      .table-data {
        .profileEditproject {
          &:hover {
            color: #ea4335;
          }
        }
      }
    }
  }

  .billing {
    .title {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    .toggle-btn {
      p {
        padding-top: 13px;
        padding-left: 10px;
        font-size: 14px;
        color: $text-color;
      }
    }

    h3 {
      color: #5772ff;
      font-size: 26px !important;
    }

    h1 {
      font-size: 30px;

      &.main-head {
        border-bottom: 1px solid #efe2d7;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
    }

    span {
      font-size: 15px;
      font-weight: 500;
    }

    .toggle-checkbox {
      appearance: none;
      outline: none;
      width: 80px;
      height: 32px;
      position: relative;
      background: #ededed;
      border-radius: 40px;
      border: 1px solid $border-color;
      cursor: pointer;
      margin: 7px 0px 0px 9px;
    }

    .toggle-checkbox:checked {
      background: #ededed;
    }

    .toggle-checkbox::before {
      content: "";
      position: absolute;
      width: 27px;
      height: 26px;
      top: 0.2rem;
      left: 0.14rem;
      border-radius: 50%;
      background: $primary;
      transition: 0.2s;
    }

    .toggle-checkbox:checked::before {
      left: 3.45rem;
    }

    .check-ic {
      padding: 5px 5px;
      background-color: #eef1ff;
      height: 35px;
      border-radius: 30px;
      color: #5772ff;

      i {
        font-size: 24px;
      }
    }

    .billing-info {
      padding: 8px 0px 0px 8px;
      font-size: 15px;
    }

    .bill-info {
      font-size: 15px;
      color: #ababab;
    }

    .box-shadow-2 {
      border-radius: 10px;
    }

    .login-box {
      .login {
        padding: 36px 58px !important;
        background: white;
        border-radius: 10px;

        .form-horizontal {
          padding: 0px 12px;
        }
      }
    }

    .bill-head {
      color: $primary;
    }

    .bill-info-or {
      padding-top: 14px;
      font-size: 15px;
      color: #ababab;
    }

    .check-ic-or {
      background-color: #ffefe5;
      color: $primary;
      padding: 5px 5px;
      height: 35px;
      border-radius: 30px;

      i {
        font-size: 24px;
      }
    }

    .info {
      h5 {
        font-size: 18px;
      }

      p {
        margin: 0px !important;
        padding: 5px;
        font-size: 15px;

        i {
          font-size: 15px;
        }
      }

      .row {
        padding-bottom: 20px;
      }

      .checbox-main {
        font-size: 15px;
        color: black;
        padding: 10px 0px;

        label {
          padding-left: 20px;
          color: $text-color;
        }

        .p-checkbox {
          .p-checkbox-box {
            box-shadow: none !important;
            border: 1px solid $border-color;
            background: $white;
            width: 16px;
            height: 16px;
            color: #495057;
            border-radius: 0px !important;

            .p-checkbox-icon {
              color: #ee0d08;
              font-size: 12px;
            }
          }
        }

        .d-flex {
          padding-bottom: 20px;
        }
      }

      .payment-history {
        .payment-history-head {
          color: $text-color;
        }

        .ft-chevron-down {
          padding: 8px;
          font-size: 18px;
          color: $text-color;

          &:hover {
            font-weight: 500;
          }
        }

        .ft-chevron-right {
          padding: 8px;
          font-size: 18px;
          color: $text-color;

          &:hover {
            font-weight: 500;
          }
        }

        .ft-check {
          padding: 8px;
          font-size: 18px;
          color: #60b555;
        }

        .ft-x {
          padding: 8px;
          font-size: 18px;
          color: #ee0d08;
        }

        .payment-info {
          color: #ababab;
        }
      }
    }
  }

  .support-page {
    .title-container {
      padding: 0 14.5px;
    }

    .title {
      h5 {
        font-size: 24px;
        padding: 5px 0px 0px 0px;
        font-weight: 500;
      }

      margin-bottom: 60px;
    }

    .team {
      padding: 35px;
      text-align: center;
      background: $white;
      width: 148px;
      padding: 35px;
      border-radius: 6px;
    }

    .get-in-touch {
      padding-top: 58px;

      .title {
        padding: 0;
        border-bottom: 0;

        h2 {
          font-size: 24px;
          font-weight: 500;
        }
      }

      .icon {
        i {
          padding: 20px;
          background: white;
          color: $primary;
          border-radius: 8px;
        }
      }

      h6 {
        font-size: 14px;
        font-weight: 500;
      }

      p {
        font-size: 13px;
      }
    }

    border-bottom: 1px solid $border-color;
    margin-bottom: 60px;
    padding-bottom: 60px;

    .login-box {
      min-width: 480px;

      .login {
        min-width: 480px;

        .card-content {
          .card-body {
            .title {
              border-bottom: 0;
              margin-bottom: 0;
              padding: 16px 0 20px;

              h2 {
                font-size: 24px;
                font-weight: 500;
              }

              p {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .faq {
      h6 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      p {
        font-size: 13px;
        margin-bottom: 30px;
      }
    }

    .p-accordion {
      .p-accordion-header {
        .p-accordion-header-link {
          padding: 1.25rem;
          color: #6c757d;
          background: $border-color;
          font-weight: 700;
          border-radius: 6px;
          transition: box-shadow 0.2s;
        }

        &:hover {
          background: $border-color;
        }
      }
    }
  }
}

.p-paginator {
  background: none;
  color: #6c757d;
  border: 0px;
  border-width: 0;
  padding: 1rem 1rem;
  border-radius: 6px;

  .p-paginator-pages {
    .p-paginator-page {
      min-width: 2rem !important;
      height: 2rem !important;

      &.p-highlight {
        background: $btn-gray !important;
        color: rgba($color: $black, $alpha: 1.0) !important;
        min-width: 2rem !important;
        height: 2rem !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
}

///

.maps-area {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
  padding: 12px;

  h2 {
    font-size: 22px;
    font-weight: 500;
    color: #3b4555;
    padding-left: 8px;
  }

  img {
    width: 100%;
    padding: 10px;
  }
}

.interest-objects {
  // align-items: end;

  label {
    color: #3b4555 !important;
    font-size: 16px !important;
  }

  input {
    height: 42px;
    width: 650px;
    border-radius: 8px !important;
    border: 1px solid rgb(215, 215, 206);
  }

  .p-dropdown,
  .p-multiselect {
    max-width: 100% !important;
    border: 1px solid rgb(215, 215, 206);
    height: 40px !important;
    border-radius: 8px !important;
    margin-bottom: 6px;
  }

}

.p-multiselect {
  max-width: 100% !important;
  border: 1px solid rgb(215, 215, 206);
  height: 40px !important;
  border-radius: 8px !important;
  margin-bottom: 6px;
}

///

.number-input {
  width: 100% !important;

  .p-inputnumber {
    width: 100% !important;
  }
}

.customer {
  .perfect-scrollbar {
    border-radius: 6px 0px 0px 6px;
  }
}

.scrollable-content {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8d8282;
    border-radius: 20px;
  }
}

.bigdata {
  min-height: 17px;
  max-height: 100px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8d8282;
    border-radius: 20px;
  }
}

.profile-picture {
  height: 27px;
  width: 27px;
  object-fit: cover;
  margin-top: -3px;
  margin-right: 5px;

  &.fa-user {
    margin-right: 0.5rem;
    height: 25px;
    width: 25px;
    border: solid 1px #999;
    border-radius: 50%;
    color: #999;
    text-align: center;
    line-height: 23px;
  }
}

.fields-col {
  .customer-add {
    background: $brand-color;
    color: white;
    padding: 10px 16px;
    position: sticky;
    top: 0;
    border-radius: 8px 8px 0px 0px;
  }

  .semantic-dependent {
    padding: 8px 26px 15px 36px;
    width: 100%;
  }

  .add-semantic-dependent {
    height: 440px;
  }
}

.add-new-button {
  margin-top: 13px;
  color: $primary;
  font-size: 14px;
}

.p-datatable .p-datatable-tbody>tr:focus {
  outline: none;
}

.p-accordion {
  .p-accordion-content {
    padding: 1.25rem;
    border: 1px solid #dee2e6;
    background: transparent;
    color: $text-color;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 20px !important;
  background: none;
  background-color: #6e7881 !important;
  color: #fff;
  font-size: 1em;

  &:focus {
    box-shadow: none !important;
  }
}

.form-builder {
  h1 {
    text-align: center;
    font-size: 30px;
    color: $text-color;
    margin-bottom: 24px;
  }
}

.dynamic-form {
  .dependent-add {
    padding-top: 9px;
  }

  .dependent-field {
    margin-bottom: 10px;

    .dependent-filed-pills {
      margin-bottom: 10px;
    }
  }

  .btn-small {
    margin-left: 18px;
    border-radius: 20px !important;
  }

  .btn-test {
    background-color: transparent !important;
    color: $text-color;
    border: none;
    margin-left: 10px;

    i {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }

  label {
    color: $text-color;
    padding-top: 9px;
  }

  .form-group {
    .newStrategy {
      padding: 8px 0px 0px 13px !important;
      display: flex !important;
      align-items: center;
      font-family: $font-family-base;

      i {
        color: $primary;
      }

      .ft-settings {
        padding: 3px 4px !important;
        font-size: 18px;
      }
    }
  }
}

.second-step {
  margin-right: 83px;
}

.prev-next {
  margin-top: 5px;

  a {
    color: $text-color;
  }
}

.sample-data {
  display: flex;

  p:first-child {
    border-radius: 6px 0px 0px 6px;
  }

  p:last-child {
    border-radius: 0px 6px 6px 0px;
  }

  p {
    background: white;
    padding: 10px 15px;
    border: 1px solid #dadada;
  }

  .sampleLength {
    display: flex;
    width: 680px;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $white;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 2px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8d8282;
      border-radius: 20px;
    }
  }
}

.container-box {
  position: relative;
  height: 120px;
  overflow: hidden;
}

.text-line {
  font-family: $font-family-base;
  font-weight: 500;
  position: absolute;
  font-size: 16px;
  left: 0;
  right: 0;
  text-align: center;
  color: $primary;
}

.none-project-selected {
  padding: 0 15px !important;

  .tect-col {
    padding: 20px 0px;
    background: white;
    text-align: center;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 8px 8px;
  }
}

.top {
  animation: slide-up-1 0.4s ease-in-out forwards;
}

.bottom {
  animation: slide-up-2 0.8s ease-in-out forwards;
}

.third {
  animation: slide-up-3 1.2s ease-in-out forwards;
}

@keyframes slide-up-1 {
  0% {
    transform: translateY(100%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up-2 {
  0% {
    transform: translateY(700%);
  }

  50% {
    transform: translateY(400%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes slide-up-3 {
  0% {
    transform: translateY(2000%);
  }

  50% {
    transform: translateY(2000%);
  }

  100% {
    transform: translateY(200%);
  }
}

.loader-text {
  font-size: 24px;
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.before-selecting {
  p {
    margin-top: 78px;
    margin-left: 44px;
    text-align: center;
  }
}

.confirm-data-title {
  h3 {
    padding-bottom: 20px;
    font-size: 30px;
    color: #0c1d37;
  }
}

/* .field-length {
  line-height: 4 !important;
} */

.forgot-password-container {
  // height: 100vh;

  .login-box {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // margin: auto;
  }
}

.thirdtestpop {
  &.p-dialog {
    min-width: 768px;
    min-height: 400px;
    max-width: 97% !important;
  }
}

.p-dialog {
  min-height: 500px;
  max-width: 1000px;
  background: $white;

  .p-dialog-content {
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #ebebeb;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cfcfcf;
      border-radius: 10px;
      border: 0px solid $white;
    }
  }

  .form-control {
    max-width: 100%;
  }

  .p-dropdown {
    max-width: 100%;
  }
}

.test-popup {
  .p-dialog-content {
    padding: 10px 50px 10px !important;
  }
}

.p-dialog-content {
  padding: 10px 100px 60px !important;

  .p-dialog-titlebar-close {
    position: absolute;
    top: 29px;
    right: 25px;
    border: none;
    background: none;

    span {
      font-size: 30px;
      color: $text-color;
    }
  }

  h5 {
    font-size: 14px;
    color: $text-color;
    font-weight: bold;
  }

  textarea {
    height: 100px !important;
    min-height: 100px;
  }
}

.p-dialog-header-icon {
  display: none !important;
  z-index: 9999 !important;

  .pi-times {
    font-size: 30px !important;
    display: none;

    &:enabled {
      &:hover {
        background: none !important;
      }
    }
  }

  &:focus {
    box-shadow: none !important;
  }
}

.mendatory {
  color: $primary;
}

.underline {
  text-decoration: underline !important;
  color: #666 !important;

  &:hover {
    color: $primary !important;
    text-decoration: underline !important;
  }
}

.back-icon {
  color: $text-color !important;
}

.invalid-feedback {
  display: block;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
}

.is-invalid {
  border-color: #dc3545 !important;

  .p-dropdown {
    border: 1px solid #dc3545 !important;
  }
}

.swal2-styled.swal2-confirm {
  border: 0 !important;
  border-radius: 20px !important;
  background: initial !important;
  background-color: $primary !important;
  font-size: 1em !important;

  &:hover {
    background-color: $brand-color !important;
    border-color: $brand-color !important;
    color: $white !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.swal2-styled.swal2-deny {
  border: 0 !important;
  background: none !important;
  background-color: none !important;
  color: $primary !important;
  font-size: 1em !important;

  &:focus {
    box-shadow: none !important;
  }
}

.swal2-container.swal2-center>.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  padding: 40px 42px 50px;
  justify-self: center;
  width: 41em;

  .swal2-title {
    font-size: 24px;
  }

  .swal2-icon {
    margin: 0.5em auto 0.6em;
  }

  .swal2-actions {
    margin-top: 19px !important;
  }
}

.load-loading {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-top-color: #a05;
  border-bottom-color: #a05;
  border-radius: 50%;
  animation: rotate 3s linear infinite;
}

.load-loading:after,
.load-loading:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #a05;
}

.load-loading:after {
  top: 1px;
  right: -7px;
  transform: rotate(135deg);
}

.load-loading:before {
  top: 11px;
  left: -7px;
  transform: rotate(-45deg);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.login-box {
  .login {
    .icon-cross {
      position: absolute;
      top: 24px;
      right: 29px;
      font-size: 24px;

      &:hover {
        cursor: pointer;
      }
    }

    .card-content {
      .card-body {
        padding: 0px !important;
        width: 100%;
        margin: auto;

        h2 {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 40px;
          text-align: center;
        }

        .login-btn {
          width: 100%;
          border-radius: 27px;
          height: 56px;
          font-size: 18px;
          margin-top: 18px;
          font-size: 16px;

          /*  &:hover {
            color: $white;
          } */

          &.outline {
            border: 1px solid #6b6f82;
          }
        }
      }

      .p-fluid {
        .p-dropdown {
          padding-right: 1rem;
          display: flex !important;
          box-shadow: none;
          border-radius: 8px;
          position: unset !important;
          border: 1px solid #dec2ab !important;
          height: 40px;

          .p-dropdown-panel {
            top: 73px !important;

            .p-dropdown-items-wrapper {
              height: 97px !important;
            }

            .p-dropdown-items {
              height: 97px;
            }
          }
        }
      }

      textarea {
        width: 100%;
        height: 100px !important;
        padding: 8px;
        border: 1px solid #dec2ab;
        border-radius: 8px;
      }
    }
  }

  .invite-members {
    background-color: #f8f8f8;

    .invite-members-link {
      padding: 12px 0px 0px 5px;

      h5 {
        font-weight: 500;
      }

      p {
        color: #ababab;
      }
    }

    .invite-members-btn {
      padding: 18px 5px 0px 0px;

      button {
        padding: 8px 12px;
        font-size: 14px;
        background-color: #f8f0eb;
        border-radius: 8px;
        color: $text-color;
      }

      i {
        color: #ababab;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none !important;
  }
}

.p-datatable-scrollable {
  .p-datatable-wrapper {
    overflow: visible !important;
  }
}

.vertical-form {
  .dynamic-form {
    .col-5 {
      min-width: 100%;
    }

    .col-7 {
      min-width: 100%;
    }
  }
}

.dynamic-form {
  .p-checkbox {
    padding-top: 10px;
  }
}

.nofuture-data {
  margin-top: 12px;
  height: 16px;
  width: 16px;
}

.tab-highlighted {
  color: rgb(100, 99, 99) !important;
}

.p-datatable-scrollable {
  .p-datatable-tbody>tr {
    /*  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.08) !important;
    border-radius: 0px 0px 8px 8px; */

    &:last-child {
      >td {
        &:first-child {
          border-radius: 0px 0px 0px 8px;
        }

        &:last-child {
          border-radius: 0px 0px 8px 0px;
        }
      }
    }
  }
}

.columnlets-data {
  margin-bottom: 20px;

  .columnlet-name {
    text-transform: capitalize;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  border-color: transparent;
  color: #343a40;
}

.p-paginator {

  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-first,
  .p-paginator-last {
    min-width: 2rem !important;
    height: 2rem !important;

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: transparent;
      color: #343a40;
    }
  }
}

.tabsborder {
  border-bottom: none !important;
}

:host ::ng-deep .layout_space {
  padding: 0%;
}

:host ::ng-deep .nav.nav-tabs.nav-underline .nav-item a.nav-link {
  padding: 0.5rem 0rem;
}

:host ::ng-deep .layout_space {
  padding: 0%;
}

:host ::ng-deep .btn-outline-info .active {
  background: rgba(0, 0, 0, 0.05);
}

fieldset {
  legend {
    display: none;
  }
}

.copyright {
  font-size: 14px;
}

.breadcrumb-wrapper {
  .breadcrumb-item {
    a {
      font-size: 16px;
      text-decoration-color: #dadada;

      &:hover {
        color: $primary;
        cursor: pointer;
      }
    }

    &:before {
      font-size: 20px;
      margin: 0px 5px 0px 0px;
      line-height: 1.5;
    }
  }
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}

.circle__item {
  // width: 70px;
  height: 70px;

  .circle__spinner {
    width: 70px;
    height: 70px;

    & .path {
      stroke: $primary;
      stroke-linecap: round;
      animation: dash 0.6s linear infinite;
    }
  }
}

.first-step-button {
  .cancel-btn {
    margin-left: 100px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 130, 150;
    stroke-dashoffset: 0;
  }
}

.hide-empty-message {
  .p-dataview-emptymessage {
    display: none;
  }
}

.main-container {
  padding-top: 20px;

  &:focus-visible {
    outline: 0px;
  }
}

/* Default styles for the switch */
.form-switch {
  input {
    height: 0;
    width: 0;
    // visibility: hidden;
    opacity: 0;

    &:checked {
      +label {
        background: $primary;

        &::after {
          left: calc(100% - 2px);
          transform: translateX(-100%);
        }
      }
    }

    :disabled {
      +label {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: $brand-color;
    display: inline-block;
    border-radius: 20px;
    position: relative;
    background-image: linear-gradient(20deg, $brand-blue01, $brand-blue2);

    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      background: white;
      border-radius: 16px;
      transition: 0.3s;
    }
  }
}

.add-rule-popup {
  h3 {
    padding-top: 9px !important;
    font-size: 16px;
    color: $text-color;
    font-weight: 400;
  }
}

.scroll-box {
  box-shadow: -3px 1px 10px rgba(0, 0, 0, 0.2);
}

// .add-ptn-pop {
//   height: 800px;
// }

.show-semantic {
  min-height: 441px;
  background: $white;
  padding: 50px 80px 50px 80px;
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); */
  border-radius: 0 16px 16px 0px;
  border: solid 1px $border-color;
}

.gen-btn {
  padding: 10px 19px;
  border-radius: 30px !important;
  margin-top: 6px;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

canvas {
  height: 352px !important;
  width: 352px !important;
  margin: 0 auto;
}

.download-pdf {
  float: right;

  .refresh-btn {
    position: fixed;
    right: 28px;
    bottom: 37px;
    background: white;

    &:hover {
      background: $primary;
    }
  }
}

.report-text {
  position: relative;
  display: block;
  bottom: 188px;
  text-align: right;
  right: 190px;
  text-transform: capitalize;
}

.p-tabview {
  .p-tabview-nav {
    li {
      max-width: 280px !important;
      min-width: fit-content !important;

      &:first-child {
        .p-tabview-nav-link {
          padding-left: 0;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          .p-tabview-title {
            font-weight: 500;
          }

          background: $white;
          border-color: $primary;
          color: $primary;
          transition: none;

          &:not(.p-disabled):focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }

      .p-tabview-nav-link {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        color: $gray-666;

        &:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }

        .p-tabview-title {
          font-weight: 500;
        }
      }
    }
  }

  .p-tabview-panels {
    padding: 1.25rem 0;

    p {
      // text-transform: capitalize;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        &.upload-list {
          list-style: none;
          padding: 0.75rem 2rem;
          line-height: 0px;
        }
      }
    }
  }
}

.skip-link {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip-link:focus,
.skip-link:hover {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
}

.pop-ttl {
  p {
    font-size: 18px !important;
  }
}

.spacer-h2 {
  display: block;
  height: 24px;
  font-size: 20px;
}

.popup-title {
  margin: 0 auto 50px;
  font-size: 26px !important;
  font-weight: 300 !important;
  color: $text-color;
}

.p-paginator {
  .p-paginator-current {
    cursor: default;
  }
}

.ngbTooltip {
  position: absolute;
  right: -5px;
  top: 39px;
  margin: auto;
}

.tooltip-inner {
  max-width: 350px;
  padding: 0.25rem 0.5rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  text-align: left;
  border: solid 1px #ccc;

  p {
    font-size: 13px !important;
    color: rgba(0, 0, 0, 1) !important;
    line-height: 16px;
    margin-bottom: 4px !important;
  }
}

.bs-tooltip-end {
  .tooltip-arrow {
    &:before {
      border-right-color: #ccc;
    }
  }
}

.font-13 {
  font-size: 13px !important;
}

.a {
  color: $primary !important;

  &:hover {
    color: rgba(0, 0, 0, 1) !important;
  }
}

.transparent {
  color: transparent !important;
}

.tokenize-cheeck {
  margin-top: 2px;
}

.newStrategy {
  &.new-connection {
    position: absolute;
    bottom: -23px;
    font-size: 14px;
  }
}

.loader-wrapper {
  &.spinners {
    min-height: 100px;
    margin: 50px auto;
  }

  &.circular-spinners {
    min-height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .pi-spinner {
      font-size: 2rem;
    }
  }
}

.confirm-job-title {
  font-size: 20px;
}

.confirm-job-row {
  padding-left: 15px;
  padding-right: 15px;
}

.max-300 {
  max-width: 300px;
}

.mw-100p {
  max-width: 100%;
}

.w-auto-btn {
  width: auto !important;
  padding: 0 40px;
}

.label-title {
  text-transform: uppercase;
  font-size: 16px;
  color: $gray-666;
}

h4 {
  &.label-title {
    text-transform: uppercase;
    font-size: 16px;
    color: $gray-666;
  }
}

h5 {
  &.label-title {
    text-transform: uppercase;
    font-size: 14px;
    color: $gray-666;
    font-weight: 500;
  }
}

.width-70p {
  width: 70%;
}

.main-menu.menu-light {
  .navigation {
    >li {
      &:hover {
        >a {
          background-color: $primary;
        }
      }
    }
  }
}

.margin-auto {
  margin: 0 auto;
}

.side-panel-logo {
  margin: 0 0 23px;
  text-align: center;
  min-height: 60px;
  max-height: 60px;

  .navbar-brand {
    margin: 20px 0 0;
    padding: 0;

    .brand-logo {
      transition: width 2s;
      width: 100%;
      max-width: 100px;
      margin: auto;
    }
  }
}

.help {
  &.a {
    font-size: 16px;
    line-height: 22px;

    em {
      font-size: 20px;
      font-weight: 900;
    }
  }

  &:disabled {
    em {
      color: rgb(255, 255, 255);
    }
  }
}

.add-rules-form {
  .set-job {
    button {
      &.submit {
        border: none;
        padding: 5px 5px;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        background: #0073ff;
        border-radius: 0.5rem;
        height: 35px;
        width: 35px;
        position: absolute;
        right: 1rem;
        bottom: 1.5rem;
      }
    }

    .when-block {
      background: $white;
      padding: 13px 20px;
      border-radius: 6px;
      border: solid 1px $border-color;

      .title-box {
        border-bottom: 1px solid $border-color;
        padding: 0 0 6px;
        margin-bottom: 7px;
      }
    }

    .then-block {
      padding: 13px 20px;

      .then-block-title {
        border-bottom: 1px solid $border-color;
        padding: 0 0 6px;
        margin-bottom: 7px;
      }
    }
  }

  .remove-action {
    position: absolute;
    top: 5px;
  }
}

.create-rule-model {
  .form-group {
    label {
      color: $text-color !important;
      font-size: 16px !important;
    }
  }
}

.workflows-page {
  .workflow-btns {
    .btn {
      border-radius: 16px;
      border: solid 1px $gray-100;
      text-align: left;
      margin-right: 20px;
      padding: 0.75rem 1rem;

      &:last-child {
        margin-right: 0px;
      }

      em {
        font-size: 50px;
        color: rgba($color: $gray-300, $alpha: 0.6);
        margin-right: 15px;
      }

      .btn-title {
        font-size: 18px;
        font-weight: 700;
        color: rgba($color: $brand-blue2, $alpha: 1.0);
        margin-bottom: 10px;
      }

      .dic {
        font-size: 14px;
        color: $gray-400;
        font-weight: 500;
      }
    }
  }
}

.animate__animated {
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}

body.vertical-layout {
  &.vertical-menu-modern {
    .toggle-icon {
      display: none;
    }
  }
}

.automl-handler {
  .field-radiobutton {
    display: flex;
    align-items: center;

    label {
      margin: 0px;
      padding: 10px;
      // font-weight: 500;
      // font-size: 18px;
    }
  }

  .btn-discription {
    padding-left: 35px;
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 14px !important;

    label {
      // margin: 0px;
      // padding: 10px 0px;
      // font-weight: 500;
    }
  }
}

.pro-version-side {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 1px solid rgb(222, 226, 230);
  padding: 0 20px 25px;

  &.version-main {
    position: static;
    padding-left: 0;
    border-bottom: 0px;
  }

  h5 {
    font-size: 14px;
    line-height: 1.25rem;
    font-weight: 500;
    color: rgba(19, 52, 59, 1);
  }

  p {
    color: rgba(100, 100, 95, 0.7);
    font-size: 12px !important;
    line-height: 1.15rem;
    font-weight: normal;
  }

  button {
    padding: 0.75rem 0.75rem;
    color: rgba(9, 52, 59, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25rem;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    height: 2rem;
    display: inline-flex;
    position: relative;
    border-radius: 5px;
    animation-timing-function: cubic-bezier(.4, 0, .2, 1);
    animation-duration: .3s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .3s;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

    .svg-inline--fa {
      width: 1.25em;
      font-size: 1em;
      height: 1em;
      vertical-align: -0.125em;
      display: inline-block;
      font-weight: 500;
      color: rgba(19, 52, 59, 0.7);
      margin: 2px 5px 0 0px;
    }

    .learn-more {
      color: rgba(19, 52, 59, 0.7);
      font-weight: 500;
      border-radius: 5px;
    }

    &:hover {

      .svg-inline--fa,
      .learn-more {
        color: $white;
      }
    }
  }
}

.subscription {
  .p-dialog-content {
    padding: 10px 50px 60px !important;

    .subscription-plans {
      &.info-cards {
        height: 50vh;
        overflow: auto;
      }

      h3 {
        font-size: 16px;
        font-weight: 500;
      }

      .subscription-item {
        padding: 8px 0px 0px 16px;
      }

      .subscription-cards {
        border: solid 1px rgb(215, 215, 206);
        border-radius: 8px;
        padding: 14px;

        .head {
          color: $brand-color;
        }

        .btn {
          border-radius: 4px;
          width: 100%;
        }
      }
    }
  }
}

// .model-hub {
.model-card {
  padding: 28px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  // height: 100%;
  height: 260px;

  &.agent {
    height: fit-content;

    .pi-trash {
      padding: 6px;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.5s;
      width: fit-content;
      height: fit-content;

      &:hover {
        background: #dddddd;
        font-size: 13px;
      }
    }
  }

  &.agents-lib {
    height: fit-content;

    .model-info {
      h4 {
        width: 40%;
      }

      p {
        width: 60%;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgb(204, 204, 204);
    ;
  }

  h4 {
    &.header {
      font-size: 1.25em;
      font-weight: 500;
      line-height: 1.24em;
      transition: 0.3s;

      &.link {
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }


    }
  }

  .model-info {
    // display: flex;
    // margin-bottom: 20px;
    // align-items: baseline;

    h4 {
      display: inline-block;
      width: 35%;
      font-size: 14px;
    }

    p {
      display: inline-block;
      width: 65%;
      text-transform: capitalize;
      // padding-left: 10px;
    }
  }

  .model-footer {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 20px;
    align-items: center;

    button {
      padding: 8px 20px;
    }

    .las {
      font-size: 24px;
      padding: 4px;
    }
  }
}

// }

.avatars {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 2px solid $white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(1) {
        background: rgba($color: $brand-dark-blue, $alpha: 1.0);
        z-index: 9;
      }

      &:nth-child(2) {
        background: rgba($color: $brand-dark-blue, $alpha: 1.0);
        z-index: 8;
      }

      &:nth-child(3) {
        background: rgba($color: $brand-dark-blue, $alpha: 1.0);
        z-index: 7;
      }

      &:nth-child(4) {
        background: rgba($color: $brand-dark-blue, $alpha: 1.0);
        z-index: 6;
      }

      &:nth-child(5) {
        background: rgba($color: $brand-dark-blue, $alpha: 1.0);
        z-index: 5;
      }

      &:nth-child(5) {
        background: rgba($color: $brand-dark-blue, $alpha: 1.0);
        z-index: 5;
      }

      &:last-child {
        margin-left: 22px !important;
        background-color: $brand-gray !important;
        border: 1px solid rgba($color: $black, $alpha: 0.1) !important;

        em {
          font-size: 20px;
        }

        &:hover {
          em {
            color: rgba($color: $brand-dark-blue, $alpha: 1.0) !important;
          }
        }


        P {
          color: black !important;
        }
      }

      &:nth-child(n+2) {
        margin-left: -8px;
      }

      p {
        font-weight: 400;
        font-size: 18px !important;
        color: $white;

        &.dropdown-toggle::after {
          display: none !important;
        }
      }
    }
  }
}

.view-content-mob {
  display: none;
}

.upcomming-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40vh;

  i {
    font-size: 3rem
  }

  p {
    font-size: 16px !important;
    margin-top: 2rem;
    font-weight: 500;
  }
}

.magnifying-load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
      /* Initial scale */
    }

    50% {
      transform: scale(1.2);
      /* Zoom in scale */
    }

    100% {
      transform: scale(1);
      /* Return to original scale */
    }
  }

  span {
    .pi-search {
      font-size: 2.2rem;
      font-weight: 500;
      color: #0073ff;
      display: inline-block;
      animation: zoomInOut 1.8s ease-in-out infinite;
    }

    p {
      color: #0073ff;
      font-weight: 500 !important;
    }
  }

  // .pi-search {
  //   display: inline-block;
  //   animation: zoomInOut 1.8s ease-in-out infinite;
  //   /* Adjust the animation duration as needed */
  // }

}

.inSights,
.summary {
  h4 {
    &.header {
      font-size: 1.25em;
      font-weight: 500;
      line-height: 1.24em;
    }
  }

  p {
    margin-bottom: 16px !important;
    line-height: 30px;
  }
}

.p-panel {
  .p-panel-header {
    .p-panel-title {
      font-weight: 600;
      font-size: 18px;
    }
  }
}

// .test-table {
//   .p-datatable-scrollable-table {
//     overflow-y: scroll;
//     overflow-x: auto;

//     &::-webkit-scrollbar {
//       width: 6px;
//       background: #fff;
//       border-radius: 12px;
//     }

//     &::-webkit-scrollbar-thumb {
//       background: #63377e;
//       border-radius: 12px;
//     }
//   }
// }

.analysis-report {
  .graph-info {
    margin-bottom: 6px !important;
    font-size: 16px !important;
    font-weight: 500 !important;

    .pi-eye-slash {
      &.cancel {
        color: #0068e6;
        padding: 6px 6px;
        border-radius: 100%;
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
        transition: 0.05s;

        &:hover {
          background-color: #ededed;
        }
      }
    }
  }

  .btn-secondary {
    &.chart {
      padding: 6px 12px;
      margin: 0px 6px 0px 0px;
    }
  }

  .pi-eye-slash {
    .cancel {
      color: #0068e6;
      padding: 6px 6px;
      border-radius: 100%;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      transition: 0.05s;
    }
  }

  a {
    color: #0068e6 !important;
    font-weight: 500;
    font-size: 15px;
  }
}

////
.border-bot {
  font-size: 40px;
}

.border-bot:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

///


.loadingsss {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: fixed;
  width: 100%;
}


.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: #005ccc;
  display: inline-block;
  height: 5px;
  border-radius: 30px;
  margin: 35px 3px 15px 4px;
  width: 5px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}


@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }

  50% {
    background-color: #005ccc;
    transform: scale(1.3);
  }

  100% {
    background-color: grey;
    transform: scale(1);
  }
}


///

.analytix-box {
  padding: 40px;
  border-radius: 8px;
  min-height: 40vh;
  box-shadow: 0px 4px 15px 0px #00000026;
}

.multi-upload {
  .p-button .p-button-label {
    margin-left: 6px;
  }

  &.upload {
    &.p-fileupload {
      .p-fileupload-content {
        .p-fileupload-files {
          .p-fileupload-row {
            display: none;
          }
        }
      }
    }
  }
}

// .p-fileupload {
//   .p-fileupload-buttonbar{
//     background: transparent;
//     padding: 0px;
//     border: 0px;
//   }
// }

.p-fileupload {
  .p-fileupload-buttonbar {
    background: transparent;
    padding: 0px;
    border: 0px;
  }

  .p-fileupload-content {
    padding: 1rem 1rem 1rem 0.4rem;
    border: 0px;

    .p-progressbar {
      display: none;
    }

    .p-fileupload-files {
      .p-fileupload-row {
        height: 50px;

        .p-fileupload-filename {
          padding: 1rem 1rem 1rem 0;
          width: 75%;
        }

        div {
          width: fit-content;

          .p-button {
            color: #000000;
            background: transparent;
            border: 0px solid #3B82F6;
            width: fit-content;
          }

          &:first-child {
            display: none;
          }

          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}

.analytix-box {
  // canvas {
  //   height: 500px !important;
  //   width: 540px !important;
  //   margin: 0 0px;
  // }


  .p-tabview .p-tabview-panels {
    .p-fluid {
      display: flex;
      justify-content: center;

      canvas {
        height: 400px !important;
        width: 400px !important;
      }
    }
  }
}

/////////
// @keyframes zoomInOut {
//   0% {
//       transform: scale(1); /* Initial scale */
//   }
//   50% {
//       transform: scale(1.2); /* Zoom in scale */
//   }
//   100% {
//       transform: scale(1); /* Return to original scale */
//   }
// }

// .pi-search {
// font-size: 50px;
//   display: inline-block;
//   animation: zoomInOut 2s ease-in-out infinite; /* Adjust the animation duration as needed */
// }

/////
/// 


.dashboard-info {
  .dashboard-card {
    border: 1px solid #c5c5c5;
    width: 20%;
    border-radius: 6px;
    padding: 16px 12px;
    text-align: center;
    margin-right: 11px;

    &.details {
      width: 25%;
    }

    &.side-card {
      width: 190px;
      margin-left: 11px;
      margin-right: 0px;
      height: fit-content;
    }

    &:last-child {
      margin-right: 0px;
    }



    h5 {
      font-size: 17px;
      font-weight: 500;
    }

    p {
      margin-bottom: 0px !important;
    }
  }

  .dashboard-filter {
    width: 19%;
    border-radius: 6px;
    padding: 16px 0px;
    margin-right: 13px;
  }
}

.chart-area-full {
  display: inline-block;
  width: 100%;
  overflow: auto;
  // height: 600px;
  // margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #c6c6c6;
  padding: 16px;
  vertical-align: top;
  min-height: fit-content;
  max-height: 600px;

  &.half {
    width: 83.2%;
  }

  &.chart {
    max-height: fit-content;

    canvas {
      width: 98% !important;
      height: 1500px !important;
      margin: 0 0px;
    }
  }

  canvas {
    width: 98% !important;
    height: 500px !important;
    margin: 0 0px;
  }

  h5 {
    font-size: 20px;
    margin-bottom: 20px;

  }

  .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    word-break: keep-all;
  }

  .p-datatable .p-datatable-thead>tr>th {
    padding: 1rem 1rem;
  }
}



.chart-area {
  display: inline-block;
  // width: 49%;
  width: 49.45%;
  // height: 600px;
  // margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #c6c6c6;
  padding: 16px;
  vertical-align: top;
  min-height: 600px;
  max-height: 600px;
  overflow: auto;

  canvas {
    height: 500px !important;
    width: 540px !important;
    margin: 0 0px;
  }



  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 1rem 8rem;
  }

  .p-tabview .p-tabview-nav li {
    width: 50%;
  }

  h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px !important;
    // margin-bottom: 18px !important;
    // font-size: 14px !important;
    margin: 22px 22px !important;

    &.head {
      font-weight: 500;
      text-transform: uppercase;
    }

    &.sub-head {
      color: #777f87;
    }
  }

  &.table {
    width: 99%;
    min-height: fit-content;
    max-height: 600px;

    .p-treetable-scrollable-body {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $white;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc3c3;
        border-radius: 20px;
      }
    }

    &.static {
      overflow: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $white;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc3c3;
        border-radius: 20px;
      }

      .p-datatable .p-datatable-tbody>tr>td {
        &:first-child {
          font-weight: 500;
          text-transform: uppercase;
        }
      }

      .p-datatable .p-datatable-thead>tr>th {
        background: #dee2e6;
        color: #202124;
        padding: 1.2rem 1.2rem 1.2rem;
      }

      .p-datatable .p-datatable-tbody>tr>td {
        background: #fff;
        padding: 0.8rem 1.2rem;
        word-break: auto-phrase;
      }
    }
  }
}

.chart-area:nth-child(odd) {
  margin: 0px 12px 0px 0px;
}

.skeleton-load {
  height: 1.4rem;
  border-radius: 12px;

  .p-skeleton {
    background-color: #c6c6c642;
  }
}

pre {
  &.response {
    background-color: transparent;
    font-size: 14px;
    margin: 10px 0px 0px;
    white-space: pre-line;

  }
}

.msg-icon {
  font-size: 24px;
  margin-right: 10px;
  font-weight: 500;
  border-radius: 30px;
  padding: 8px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    color: #066ff3;
    background-color: #eeeeee;
  }
}

.slider-input {
  width: 20%;
  border: 1px solid #d7d7ce;
  height: 36px;
  border-radius: 8px;
  padding: 10px;
}

.bulb-light {
  transition: 0.5s;
  padding: 2px;

  &:hover {
    padding: 4px;
    background-color: #dcd8d891;
    border-radius: 30px;
  }
}

.pi-spin {
  animation: fa-spin 0.8s infinite linear;
}

.test-model-loader {
  display: grid;
  align-items: center;
  height: 20vh;
  text-align: center;

  .pi {
    font-size: 2rem
  }
}

.selected-tool {
  border: 1px solid #dadada;
  border-radius: 8px;
  min-height: calc(1.25em + 1.5rem + 58px);
  max-height: calc(1.25em + 1.5rem + 58px);
  overflow: auto;
  padding: 12px 10px;


  .selected-tool-text {
    display: inline-block;
    border: 1px solid #c4c4c4;
    padding: 4px 10px;
    border-radius: 30px;
    background-color: #e6e6e6;
    font-size: 14px;
    margin-right: 6px;
    margin-bottom: 6px;


    i {
      font-size: 10px;
      font-weight: 600;
      margin-left: 2px;
    }
  }
}

.pi-chevron-down {
  &::after {
    display: none;
  }
}

.config-agents-btn {
  .click-btn {
    padding: 0.75rem 1.1rem;

    .pi-plus {
      font-size: 12px;
    }
  }

  .dropdown-btn {
    padding: 0.75rem 1rem 0.75rem 0rem;
    border-left: 1px solid #dedede;

    .pi-chevron-down {
      font-size: 12px;
    }
  }
}

.agent-container {
  margin: 0px 190px;

  .agent-content {
    height: 80vh;

    .agent-chat {
      height: 72vh;
      overflow: auto;

      img {
        height: 27px;
        width: 27px;
        object-fit: cover;
        margin-right: 6px;
      }

      .query {
        padding: 10px 0px;
        font-size: 18px !important;
      }

      .response {
        background: #D4E2F3;
        border-radius: 7px;
        padding: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .message-input-holder {
    border: 1px solid #d4d4d4;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 0px 10px;
    position: absolute;
    bottom: 0px;
    width: 62%;

    textarea {
      border: 0px;
      background-color: transparent;
      width: 100%;
      height: 50px !important;
      outline: transparent;
      padding: 12px 12px 12px 12px;
      resize: none;
    }

    .submit {
      border: 1px transparent solid;
      padding: 0px 0px;
      cursor: pointer;
      color: #fff;
      margin: 3px -5px;
      font-size: 16px;
      background: #0073ff;
      border-radius: 5rem;
      height: 44px;
      width: 44px;
      outline: 0px !important;
    }
  }


}


// textarea{
//   outline: transparent;
// }

.ddd-cont {
  margin: 20px;

  h2 {
    margin-bottom: 10px;
  }

  textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  ::ng-deep .p-table {
    margin-bottom: 20px;
  }

  .submit-button {
    float: right;
    margin-top: 20px;
  }
}



// ... existing code ...

.warehouse-box {
  background: white;
  border-radius: 8px !important;
  border: 1px solid #e0e0e0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 16px !important;
  margin-bottom: 16px;

  h6 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;

  }

  p {
    // margin-bottom: 8px;
    font-size: 14px;
    color: #333;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      color: #666;
      font-weight: 500;
      margin-right: 8px;
    }
  }

  .stock-value {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin: 0px
  }
}

// ... existing code ...


.forecast-card {
  background: white;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  padding: 16px;
}

.forecast-title {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
}

.forecast-value {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.forecast-unit {
  font-size: 14px;
  color: #666;
  margin-left: 4px;
}

.forecast-chart {
  canvas {
    height: auto !important;
    width: 100% !important;
  }
}

// ... existing code ...

// Pinnacle Component Styles
.pinnacle-heading {
  font-size: 22px;
  margin-bottom: 14px;
}

.original-product-container {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.original-product-brand {
  color: #666;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recommendation-box {
  background: white;
  border-radius: 12px;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #c5c5c5;
  // height: 300px;
  overflow: auto;
}

.details-label {
  color: #666;
  font-size: 14px;
}

.details-value {
  color: #666;
  font-size: 18px;
  font-weight: 600;

}

.details-description {
  color: #333;
  font-size: 16px;
  max-height: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-icon {
  font-size: 2rem;
}


// ... existing code ...

// Pinnacle Component Styles
// .pinnacle-component {
.metric-item-container {
  width: 20%;
  padding: 0px 10px 0px 0px;
}

.warehouse-heading {
  font-size: 22px;
  margin-bottom: 14px;
}

.warehouse-id {
  font-size: 16px;
  font-weight: 600;
}

.supplier-heading {
  font-size: 22px;
  margin-bottom: 14px;
}

.forecast-chart-container {
  .chart-header {
    font-size: 22px;
    margin-bottom: 14px;
  }
}

.forecast-table-heading {
  font-size: 22px;
  margin-bottom: 14px;
}


.supplier-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  // box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid #c5c5c5;
  margin-bottom: 2rem;
}

.supplier-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.stock-metrics {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.metric-box {
  background: #FFEBD6;
  padding: 0.75rem;
  border-radius: 6px;
  text-align: center;
  flex: 1;
}

.metric-label {
  display: block;
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.metric-value {
  display: block;
  font-size: 1.25rem;
  color: #1C03FF;
  font-weight: 600;
}

.lead-time-section {
  margin-bottom: 1.5rem;
}

.lead-time-section h6 {
  color: #495057;
  margin-bottom: 0.75rem;
}

.lead-time-metrics {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.lead-time-metrics .label {
  display: block;
  font-size: 14px;
  color: #6c757d;
}

.lead-time-metrics .value {
  display: block;
  font-weight: 700;
  font-size: 18px;
  color: #495057;
}

.cost-section,
.order-section {
  margin-bottom: 1rem;
}

.cost-item,
.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.cost-item .label,
.order-item .label {
  color: #6c757d;
  // font-size: 0.875rem;
  font-size: 14px;
}

.cost-item .value,
.order-item .value {
  font-weight: 700;
  font-size: 18px;
  color: #495057;
}

hr{
  border: 1px solid rgb(0 0 0 / 46%) !important
}

.p-radiobutton-label{
  margin-bottom: 0px !important;
}
// }

.validation-error-container {
  margin: 1rem 0;
  
  .alert {
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .error-message {
      padding-left: 2.5rem;
  }

  .error-details {
      opacity: 0.9;
      font-size: 0.95rem;
      border-top-color: rgba(255,255,255,0.2) !important;
  }

  i {
      font-size: 24px;
      margin-right: 10px;
  }

  h4 {
      font-size: 1.2rem;
      font-weight: 500;
  }

  p {
      line-height: 1.5;
  }
}

.validation-content {
  padding: 1rem;
  
  h2 {
      color: #2c3e50;
      font-size: 1.5rem;
      font-weight: 500;
  }

  .text-muted {
      color: #6c757d;
  }
}

.p-checkbox-label{
  margin-bottom: 0px !important;
}