/* Custom Loader.CSS */
.loader-container {
	position: absolute;
	top: 50%;
	left: 50%;
}

// Double Bounce
.double-bounce {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 40px auto;
	.child {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: #333;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		animation: doubleBounce 2s infinite ease-in-out;
	}
	.double-bounce2 {
		animation-delay: -1s;
	}
}

@-webkit-keyframes doubleBounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

@keyframes doubleBounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

// Chasing Dots
.chasing-dots {
	// margin: 40px auto;
	width: 40px;
	height: 40px;
	position: relative;
	text-align: center;
	animation: chasingDotsRotate 2s infinite linear;
	.child {
		width: 60%;
		height: 60%;
		display: inline-block;
		position: absolute;
		top: 0;
		background-color: $primary;
		border-radius: 100%;
		animation: chasingDotsBounce 2s infinite ease-in-out;
	}
	.dot2 {
		top: auto;
		bottom: 0;
		animation-delay: -1s;
	}
}

@-webkit-keyframes chasingDotsRotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chasingDotsRotate {
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes chasingDotsBounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

@keyframes chasingDotsBounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

// Cube Grid
/*
 * Spinner positions
 * 1 2 3
 * 4 5 6
 * 7 8 9
 */
.cube-grid {
	width: 40px;
	height: 40px;
	margin: 40px auto;
	.cube {
		width: 33.33%;
		height: 33.33%;
		background-color: #333;
		float: left;
		animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
	}
	.cube1 {
		animation-delay: 0.2s;
	}
	.cube2 {
		animation-delay: 0.3s;
	}
	.cube3 {
		animation-delay: 0.4s;
	}
	.cube4 {
		animation-delay: 0.1s;
	}
	.cube5 {
		animation-delay: 0.2s;
	}
	.cube6 {
		animation-delay: 0.3s;
	}
	.cube7 {
		animation-delay: 0s;
	}
	.cube8 {
		animation-delay: 0.1s;
	}
	.cube9 {
		animation-delay: 0.2s;
	}
}

@-webkit-keyframes cubeGridScaleDelay {
	0%,
	70%,
	100% {
		transform: scale3D(1, 1, 1);
	}
	35% {
		transform: scale3D(0, 0, 1);
	}
}

@keyframes cubeGridScaleDelay {
	0%,
	70%,
	100% {
		transform: scale3D(1, 1, 1);
	}
	35% {
		transform: scale3D(0, 0, 1);
	}
}

// Fading Circle
.fading-circle {
	margin: 40px auto;
	width: 40px;
	height: 40px;
	position: relative;
	.circle {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		&:before {
			content: "";
			display: block;
			margin: 0 auto;
			width: 15%;
			height: 15%;
			background-color: #333;
			border-radius: 100%;
			animation: circleFadeDelay 1.2s infinite ease-in-out both;
		}
	}
	.circle2 {
		transform: rotate(30deg);
		&:before {
			animation-delay: -1.1s;
		}
	}
	.circle3 {
		transform: rotate(60deg);
		&:before {
			animation-delay: -1s;
		}
	}
	.circle4 {
		transform: rotate(90deg);
		&:before {
			animation-delay: -0.9s;
		}
	}
	.circle5 {
		transform: rotate(120deg);
		&:before {
			animation-delay: -0.8s;
		}
	}
	.circle6 {
		transform: rotate(150deg);
		&:before {
			animation-delay: -0.7s;
		}
	}
	.circle7 {
		transform: rotate(180deg);
		&:before {
			animation-delay: -0.6s;
		}
	}
	.circle8 {
		transform: rotate(210deg);
		&:before {
			animation-delay: -0.5s;
		}
	}
	.circle9 {
		transform: rotate(240deg);
		&:before {
			animation-delay: -0.4s;
		}
	}
	.circle10 {
		transform: rotate(270deg);
		&:before {
			animation-delay: -0.3s;
		}
	}
	.circle11 {
		transform: rotate(300deg);
		&:before {
			animation-delay: -0.2s;
		}
	}
	.circle12 {
		transform: rotate(330deg);
		&:before {
			animation-delay: -0.1s;
		}
	}
}

@-webkit-keyframes circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

@keyframes circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

// Folding Cube
.folding-cube {
	// margin: 40px auto;
	width: 40px;
	height: 40px;
	position: relative;
	transform: rotateZ(45deg);
	.cube {
		float: left;
		width: 50%;
		height: 50%;
		position: relative;
		transform: scale(1.1);
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #333;
			animation: foldCubeAngle 2.4s infinite linear both;
			transform-origin: 100% 100%;
		}
	}
	.cube2 {
		transform: scale(1.1) rotateZ(90deg);
		&:before {
			animation-delay: 0.3s;
		}
	}
	.cube3 {
		transform: scale(1.1) rotateZ(180deg);
		&:before {
			animation-delay: 0.6s;
		}
	}
	.cube4 {
		transform: scale(1.1) rotateZ(270deg);
		&:before {
			animation-delay: 0.9s;
		}
	}
}
@-webkit-keyframes foldCubeAngle {
	0%,
	10% {
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
	75% {
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}
	90%,
	100% {
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}

@keyframes foldCubeAngle {
	0%,
	10% {
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
	75% {
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}
	90%,
	100% {
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}
