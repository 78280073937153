// Core variables and mixins
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../variables/bootstrap-variables";
@import "../../../bootstrap/variables";

@import "../../variables/components-variables";

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";

//  Vertical menu
//=========================
body.vertical-layout {
   &.vertical-menu-modern {
      .main-menu {
         .navigation {
            >li>a>i {
               font-size: 1.6rem;
            }
         }
      }

      .toggle-icon {
         margin-right: 1rem;
      }

      // Flipped menu expanded
      &.menu-flipped {
         @include media-breakpoint-up(sm) {
            .main-menu {
               right: 0;
            }
         }
      }

      .navbar {
         .navbar-brand {
            white-space: nowrap;
         }

         .navbar-header {
            .nav-toggle {
               position: absolute;
               right: 0;
            }
         }
      }

      .main-menu {
         transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
         transform: translate3d(0, 0, 0);
         backface-visibility: hidden;

         li {
            a {
               span {
                  animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
               }
            }
         }
      }

      //Vertical menu [Expanded]
      &.menu-expanded {

         //Navbar expanded
         .navbar {
            .navbar-header {
               @include menu-navbar-width($menu-expanded-width);
            }

            &.navbar-brand-center {
               .navbar-header {
                  @include menu-navbar-width(auto);
               }
            }

            .navbar-container {
               @include main-menu-width($menu-expanded-width);
            }

            .navbar-brand {
               .brand-text {
                  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
               }
            }
         }

         // Main menu expanded
         .main-menu {
            width: $menu-expanded-width;

            .navigation {
               .navigation-header {

                  .la-ellipsis-h,
                  .nav-menu-icon {
                     display: none;
                  }
               }

               >li {
                  >a {
                     >i {
                        margin-right: 5px;
                        float: left;

                        &:before {
                           font-size: 18px;
                        }
                     }

                     >span {
                        &.badge {
                           position: absolute;
                           right: 20px;
                        }
                     }
                  }
               }

               li {
                  @include vertical-menu-has-sub-arrow(1rem);
               }
            }

            .main-menu-footer {
               width: $menu-expanded-width;
            }
         }

         //Content expanded
         .content,
         .footer {
            @include main-menu-width($menu-expanded-width);
         }

         //Brand center content expanded
         .navbar-brand-center {

            .content,
            .footer {
               @include main-menu-width(0);
            }
         }
      }

      //Vertical menu [Collapsed]
      &.menu-collapsed {

         //Navbar collapsed
         .navbar {
            .navbar-header {
               /* @include menu-navbar-width($menu-collapsed-width); */
               width: 150px;

               .modern-nav-toggle {
                  display: none;
               }

               &.expanded {
                  width: $menu-expanded-width;
                  z-index: 1000;

                  .modern-nav-toggle {
                     display: block;
                  }

                  .navbar-brand {
                     .brand-text {
                        animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
                     }
                  }
               }

               .navbar-brand {
                  .brand-text {
                     animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
                  }
               }
            }

            &.navbar-brand-center {
               .navbar-header {
                  @include menu-navbar-width(auto);
               }

               .navbar-brand {
                  .brand-text {
                     animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
                  }
               }
            }

            .navbar-brand {
               padding: 0px 0px 0;
            }

            .navbar-container {
               @include main-menu-width($menu-collapsed-width);
            }
         }

         // Main menu collapsed
         .main-menu {
            width: $menu-collapsed-width;
            transition: 300ms ease all;
            backface-visibility: hidden;
            transform: translate3d(0, 0, 0);

            .main-menu-footer {
               width: $menu-collapsed-width;
            }

            .main-menu-footer,
            .main-menu-header .media-body .media-heading,
            .main-menu-header .media-body .text-muted,
            .main-menu-header .media-right {
               display: none;
            }

            .main-menu-header .media-body {
               opacity: 0;
            }

            .user-content {
               padding: 20px 10px;
            }

            //Navigation collapsed
            .navigation {
               overflow: visible;

               >li.navigation-header {
                  .ft-minus {
                     display: block;
                     font-size: 1.2rem;
                  }

                  span {
                     display: none;
                  }
               }

               >li {
                  white-space: inherit;

                  >a {
                     text-overflow: inherit;
                     text-align: center;
                     line-height: 16px;
                     font-size: 14px;

                     >i {
                        animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
                        display: none;
                     }

                     >span {
                        animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
                        display: none;

                        &.badge {
                           float: none !important;
                        }
                     }

                     >em {
                        animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
                        display: block;
                        opacity: 1;
                        color: rgba($font-color, 0.4);
                        font-size: 30px;
                     }

                     >span {
                        &.compact-span {
                           animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
                           display: block;
                           opacity: 1;

                        }
                     }

                     i {
                        margin-right: 12px;
                        float: left;

                        &:before {
                           font-size: 1.6rem;
                        }
                     }
                  }
               }
            }

            .mTSWrapper {
               overflow: visible;
            }

            &.expanded {
               width: $menu-expanded-width;

               .navigation {
                  li {
                     white-space: nowrap;
                  }

                  >li.navigation-header {
                     .la-ellipsis-h {
                        display: none;
                     }

                     span {
                        display: block;
                     }
                  }

                  >li {
                     >a {
                        text-align: left;
                        font-size: 18px;
                        font-weight: 200;
                        line-height: 26px;
                        margin-bottom: 15px;

                        >i {
                           animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
                           display: inline-block;
                        }

                        >span {
                           animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
                           display: inline-block;

                           &.badge {
                              position: absolute;
                              right: 20px;
                           }
                        }

                        >em {
                           animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
                           display: none;
                           opacity: 0;
                           transition: visibility 0s, opacity 0.5s linear;
                        }

                        >span {
                           &.compact-span {
                              animation: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeinout;
                              display: none;
                              opacity: 0;
                              transition: visibility 0s, opacity 0.5s linear;
                           }
                        }
                     }
                  }

                  li {
                     @include vertical-menu-has-sub-arrow(1rem);
                  }
               }
            }
         }

         //Brand center content collapsed
         .content,
         .footer {
            @include main-menu-width($menu-collapsed-width);
         }

         .navbar-brand-center {

            .content,
            .footer {
               @include main-menu-width(0);
            }
         }

         // Flipped menu collapsed
         &.menu-flipped {

            //Content collapsed flipped
            .content {
               @include vertical-flipped-menu($menu-collapsed-width);
            }

            @include media-breakpoint-up(sm) {
               .main-menu {
                  right: 0;

                  span.menu-title {
                     right: $menu-collapsed-width;
                  }

                  ul.menu-content {
                     right: $menu-collapsed-width;
                     left: inherit;
                  }
               }
            }

            //Navbar collapsed flipped
            .navbar {
               .navbar-header {
                  float: right;
               }

               .navbar-container {
                  @include vertical-flipped-menu($menu-collapsed-width);
               }
            }

            .footer {
               @include vertical-flipped-menu($menu-collapsed-width);
            }
         }
      }
   }
}

// Collapsed menu RTL
[data-textdirection="rtl"] body.vertical-layout {
   &.vertical-menu-modern.menu-collapsed {
      .main-menu {
         .navigation {
            >li>a {
               padding: 14px 16px !important;
            }

            >li.navigation-header .la-ellipsis-h {
               padding: 12px 0px;
            }
         }
      }
   }
}

// Initially menu & content width for lg and up screen
@include media-breakpoint-up(lg) {
   body.vertical-layout {
      &.vertical-menu-modern {
         .main-menu {
            width: $menu-expanded-width;

            .navigation {
               li.navigation-header .ft-minus {
                  display: none;
               }
            }
         }

         .navbar {
            .navbar-header {
               width: $menu-expanded-width;
            }
         }

         .content,
         .footer {
            @include main-menu-width($menu-expanded-width);
         }

         &.menu-flipped {

            //Content expanded flipped
            .content {
               @include vertical-flipped-menu($menu-expanded-width);
            }

            //Navbar expanded flipped
            .navbar {
               .navbar-header {
                  float: right;
               }

               .navbar-container {
                  @include vertical-flipped-menu($menu-expanded-width);
               }
            }

            .footer {
               @include vertical-flipped-menu($menu-expanded-width);
            }
         }
      }
   }
}

// Initially menu & content width for md and down screen
@include media-breakpoint-down(md) {
   body.vertical-layout {
      &.vertical-menu-modern {
         .main-menu {
            width: 0;
         }

         .navbar {
            .navbar-header {
               width: 0;
            }
         }

         .content,
         .footer {
            @include main-menu-width(0);
         }
      }
   }
}

// Initially menu & content width for sm and down screen
@include media-breakpoint-down(sm) {
   body.vertical-layout {
      &.vertical-menu-modern {
         &.vertical-menu-modern {
            .main-menu {
               width: 0;
            }

            .navbar {
               .navbar-header {
                  width: 0;
               }
            }

            .content,
            .footer {
               @include main-menu-width(0);
            }
         }

         &.menu-expanded {
            .navbar .navbar-container {
               margin-left: 0px !important;
            }

            .content,
            .footer {
               margin-left: 0px !important;
            }
         }
      }
   }

   html.loading .main-menu {
      opacity: 0;
      transform: translate3d(0px, 0, 0);
   }
}

@keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@keyframes fadeout {
   from {
      opacity: 1;
   }

   to {
      opacity: 0;
   }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
   .vertical-overlay-menu {
      &[data-menu="vertical-menu-modern"] {
         #navbar-mobile {
            .search-input.open {
               .input {
                  border-bottom: 1px solid #2c303b;
               }
            }
         }
      }
   }
}

// Import vertical-overlay-menu.scss for small screen support
@import "vertical-overlay-menu.scss";