// Stick footer to the bottom of the page
footer {
  &.footer {
    padding: 0.8rem;
  }

  //shadow & border options
  &.navbar-shadow {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
  }

  &.navbar-border {
    border-top: 1px solid #e4e7ed;
  }

  // Footer color options
  &.footer-transparent {
    border: none;
  }

  &.footer-light {
    background: $footer-color-light-bg;
    width: 100%;
  }

  &.footer-dark {
    background: $footer-color-dark-bg;

    span {
      color: #fff;
    }
  }

  .ft-heart:before {
    padding-left: 0.3rem;
  }
}
.footers {
  position: absolute;
  bottom: 0;
  width: 100%;
}
