// Mixins for the menu and navbar position, margin and width change
@mixin main-menu-width($menu-width) {
    margin-left: $menu-width;
}

@mixin menu-navbar-width($menu-width) {
    float: left;
    width: $menu-width;
    // z-index: 999;
}

// Mixins for the menu show hide based on width
@mixin vertical-menu-transform-show($transform-width) {
    transform: translate3d($transform-width, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
}

@mixin vertical-menu-transform-hide() {
    transform: translate3d(0, 0, 0);
}

// Mixins for the menu flipped menu
@mixin vertical-flipped-menu($menu-width) {
    margin: 0;
    margin-right: $menu-width;
}

// Mixins for the sub menu arrow
@mixin vertical-menu-has-sub-arrow($arrow-font-size) {
    &.has-sub {
        >a:not(.mm-next):after {
            content: "\f112";
            font-family: "LineAwesome";
            font-size: $arrow-font-size;
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 14px;
            transform: rotate(0deg);
            transition: -webkit-transform 0.2s ease-in-out;
        }

        .has-sub {
            >a:not(.mm-next):after {
                top: 8px;
            }
        }
    }

    &.open {
        >a:not(.mm-next):after {
            transform: rotate(90deg);
        }
    }
}

// Light & Dark menu mixin
// $theme-color : Menu text color
// $theme-bg 	: Menu background color
// ================================================
@mixin menu-theme($theme-color, $theme-bg) {
    $main-menu-top-hover-bg: darken($theme-bg, 4%); //$info
    $main-menu-top-hover-color: darken($theme-color, 10%);

    $main-menu-top-open-color: darken($theme-color, 10%);
    $main-menu-top-open-bg: darken($theme-bg, 4%);
    $main-menu-top-open-hover-bg: darken($theme-bg, 6%);
    $main-menu-top-open-head-bg: darken($theme-bg, 4%);

    $main-menu-active-color: lighten($theme-color, 10%);
    $main-menu-active-bg: darken($theme-bg, 4%);

    $main-menu-top-active-bg: darken($theme-bg, 4%); //$info
    $main-menu-top-active-color: $white;
    $main-menu-top-active-head-bg: darken($theme-bg, 4%); //$info

    $main-menu-sub-bg: darken($theme-bg, 4%);
    $main-menu-sub-color: $theme-color;
    $main-menu-sub-hover-bg: darken($theme-bg, 4%);
    $main-menu-sub-hover-color: lighten($theme-color, 4%);
    $main-menu-sub-active-bg: rgba(0, 0, 0, 0.06);

    $main-menu-sub-open-bg: darken($theme-bg, 4%);
    $main-menu-sub-open-color: $theme-color;
    $main-menu-sub-open-hover-bg: darken($theme-bg, 4%);

    color: $theme-color;
    background: $theme-bg;

    .main-menu-header {
        padding: 20px;

        .menu-search {
            background: darken($theme-bg, 10%);
            padding: 0.5rem 1rem;
            color: $main-menu-top-hover-color;

            &:focus {
                border-color: darken($theme-bg, 15%);
            }
        }
    }

    .navigation {
        background: $transparent;

        .navigation-header {
            color: $font-color;
            padding: 30px 20px 8px 20px;

            span {
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        li {
            a {
                color: rgba(29, 41, 57, 1);
                font-weight: 500;
                transition: .5s ease-out;

                i,
                span {
                    transition: all 0.2s ease;
                }

                span {
                    display: inline-block;
                }

                span.menu-sub-title {
                    color: lighten($font-color, 30%);
                }
            }

            &.hover,
            &.open,
            &.active {
                >a {
                    color: $font-color;
                }
            }
        }

        >li {
            padding: 5px 0;
            margin: 0px 10px;

            // border-left: 4px solid transparent;
            >a {
                padding: $menu-padding;

                .label {
                    margin-top: 4px;
                    margin-right: 5px;
                }

                i {
                    position: relative;
                    top: 0px;
                }
            }

            &.open {
                >ul {
                    // background: $main-menu-top-open-bg;
                }

                >a {
                    color: $main-menu-top-open-color;
                    background: $main-menu-top-open-head-bg;
                    border-right: 4px solid #0073ff;
                }

                .hover {
                    >a {
                        // background: $main-menu-top-open-hover-bg;
                        transform: translateX(-4px);
                    }
                }
            }

            &:not(.open) {
                >ul {
                    display: none;
                }
            }

            &.hover,
            &:hover {
                margin: 0 10px;

                >a {
                    color: $font-color;
                    background-color: rgb(222, 226, 230);
                    padding: 5px 18px;
                    border-radius: 8px;

                    /*  >i {
                        transform: translateX(6px);
                    }

                    >span {
                        transform: translateX(4px);
                    } */

                    &.mm-next {
                        background-color: inherit;
                    }
                }
            }

            &.active {
                margin: 0 10px;

                >a {
                    color: rgba(29, 41, 57, 1);
                    font-weight: 500;
                    background-color: rgba(222, 226, 230, 1);
                    padding: 5px 18px;
                    border-radius: 8px;

                    >i {
                        color: $brand-blue1;
                    }
                }
            }

            .active {
                background: $main-menu-top-open-hover-bg;

                >a {
                    color: lighten($font-color, 10%);
                    font-weight: 500;
                    background: $main-menu-top-open-hover-bg;
                }

                .hover {
                    >a {
                        background: $main-menu-top-active-head-bg;
                    }
                }
            }

            ul {
                padding: 0;
                margin: 0;
                font-size: 1rem;

                li {
                    color: $main-menu-sub-color;
                    background: transparent;

                    >a {
                        padding: $menu-second-level-padding;
                    }
                }

                .has-sub {
                    &:not(.open) {
                        >ul {
                            display: none;
                        }
                    }
                }

                .open {
                    // background: $main-menu-sub-open-bg;

                    >a {
                        color: $main-menu-sub-open-color;
                    }

                    //for 2rd level menu
                    >ul {
                        display: block;

                        // background: $main-menu-sub-open-bg;
                        li>a {
                            padding: $menu-third-level-padding;
                        }

                        //for 3rd level menu
                        .open {
                            //background: darken($main-menu-sub-open-bg,5%);

                            >ul {
                                display: block;

                                // background: darken($main-menu-sub-open-bg,5%);
                                li>a {
                                    padding: $menu-forth-level-padding;
                                }
                            }
                        }
                    }

                    .hover {
                        >a {
                            // background: $main-menu-sub-open-hover-bg;
                        }
                    }
                }

                .hover,
                &:hover {
                    >a {
                        color: $main-menu-sub-hover-color;
                        // background-color: $main-menu-sub-hover-bg;
                    }
                }

                .active {
                    background: transparent;

                    >a {
                        color: lighten($theme-color, 10%);
                        // background-color: $main-menu-active-bg;
                        // border-left: 4px solid $info;
                        font-weight: 700;
                    }

                    .hover {
                        >a {
                            background-color: transparent;
                        }
                    }
                }
            }

            >ul {
                background: $theme-bg;
            }
        }
    }

    //For popout menu
    ul.menu-popout {
        background: $theme-bg;

        li {
            a {
                color: $theme-color;

                span.menu-sub-title {
                    color: lighten($theme-color, 30%);
                }
            }

            &.hover,
            &:hover,
            &.open {
                >a {
                    // background:$main-menu-top-open-hover-bg;
                }
            }
        }

        .has-sub {
            &:not(.open) {
                >ul {
                    display: none;
                }
            }

            &.active {
                font-weight: normal;
            }
        }

        .open {

            // background: $main-menu-sub-open-bg;
            >a {
                color: $main-menu-sub-open-color;
            }

            //for 2rd level menu
            >ul {
                display: block;

                // background: $main-menu-sub-open-bg;
                //for 3rd level menu
                .open {
                    background: darken($main-menu-sub-open-bg, 5%);

                    >ul {
                        display: block;
                        background: darken($main-menu-sub-open-bg, 5%);
                    }
                }
            }

            li.hover,
            li:hover {
                >a {
                    // background: $main-menu-sub-open-hover-bg;
                }
            }
        }

        .hover,
        &:hover {
            >a {
                color: $main-menu-sub-hover-color;
                // background-color: $main-menu-sub-hover-bg;
            }
        }

        .active {
            // background: $main-menu-sub-active-bg;
            font-weight: 700;

            >a {
                color: $main-menu-active-color;
                // background-color: $main-menu-active-bg;
            }

            .hover,
            :hover {
                >a {
                    background-color: transparent;
                }
            }
        }
    }

    //For pop
}

// Menucontent display mixin
// $menu-expanded-width : Expanded menu width
// $menu-collapsed-width: Collapsed menu width
// ================================================
@mixin menu-content($menu-expanded-width, $menu-collapsed-width, $theme-color, $theme-bg) {
    .main-menu-content {

        >span.menu-title,
        a.menu-title {
            right: -($menu-expanded-width);
            width: $menu-expanded-width;
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
            text-align: left;
            background-color: $info;
            border-color: $info;
            padding: 14px 20px;
        }

        >ul.menu-content {
            left: $menu-collapsed-width;
            width: $menu-expanded-width;
            transition:
                visibility 0.25s,
                opacity 0.25s;
            box-shadow: 25px 5px 75px 2px rgba(64, 70, 74, 0.2);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            border-left: 1px solid rgba(0, 0, 0, 0.02);

            li {
                white-space: nowrap;
                position: relative;

                a {
                    display: block;
                    padding: 8px 20px 8px 20px;
                    transition: all 0.2s ease;
                }

                @include vertical-menu-has-sub-arrow(1rem);

                &:hover,
                &.hover {
                    >a {
                        transform: translateX(4px);

                        >a {
                            transform: translateX(-4px);
                        }
                    }
                }
            }
        }
    }
}