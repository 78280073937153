.card-group,
.card-deck {
    margin-bottom: 0.75rem;
}

.workflows-page {
    .card {
        border: solid 1px $border-color;
        border-radius: 16px;
        padding: 10px;
        height: auto !important;

        .btn {
            padding: 5px 20px;
        }

        .solutions-head {
            height: 90px;
        }

        .img-box {
            /* width: 80px;
            border: solid 1px rgba($black, 0.15);
            text-align: center;
            border-radius: 5px;
            line-height: 80px; */
        }

        .solutions-info {
            // height: 36vh;
            overflow: auto;

            p {
                &.card-text {
                    font-size: 16px !important;
                    height: 200px;
                }
            }
        }
    }

    .card-img-top {
        max-height: 150px;
        max-width: 60px;
        margin: 10px 0 0 14px;
        opacity: 0.6;
    }

    .page-sub-title {
        font-size: 20px;
    }
}

.card {
    // box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.875rem;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    background-color: $white;

    .card {
        box-shadow: none !important;
    }

    .card-title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
    }

    .card-bordered {
        border: $card-border-width solid $card-border-color;
    }

    //fullscreen card
    &.card-fullscreen {
        display: block;
        z-index: 9999;
        position: fixed;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: auto;
    }

    .card-body[class*="border-bottom-"] {
        border-bottom-width: 2px !important;
    }

    .card-img-overlay {
        &.bg-overlay {
            background: rgba(0, 0, 0, 0.45);
        }

        .text-muted {
            color: $gray-800 !important;
        }
    }

    &.card-minimal {
        border: none;
        box-shadow: none;
    }

    &.crypto-card-3 {
        .card-body:before {
            font-size: 10rem;
            color: rgba($white, 0.15);
            position: absolute;
            bottom: -32px;
            right: 0px;
        }
    }
}

// If card moved
.card-moved {

    .card,
    .card-header {
        background-color: $warning;
        color: $white;
    }
}

.handle,
.titleArea {
    cursor: move;
}

.card-header {
    border-bottom: none;

    .card-title {
        margin-bottom: 0;
    }

    .heading-elements,
    .heading-elements-toggle {
        background-color: inherit;
        position: absolute;
        top: 20px;
        right: 20px;

        &.heading-top-elements {

            // top: 0px;
            .page-item {
                display: inline-block;
            }
        }

        a {
            padding: 0 8px;

            &.btn {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}

.card-footer {
    border-top: 1px solid #eeeeee;
}

.text-white {
    .card-text {
        color: rgba(255, 255, 255, 1);
    }

    .card-img-overlay {
        .text-muted {
            color: #fff !important;
        }
    }

    code {
        background-color: rgba(255, 255, 255, 1);
    }

    .heading-elements {
        i {
            color: #fff;
        }
    }
}

.card-head-inverse {
    .heading-elements {
        i {
            color: #fff;
        }
    }

    color: #fff;
}

.card-transparent {
    background-color: transparent;
}

// Card Ecommerce
.card-image-position-right {
    margin-left: 50px;
    position: absolute;
    top: -27px;
}

.card-image-position-right-mid {
    position: absolute;
    top: auto;
    right: 0px;
    width: 250px;
}

.card-font-style-black {
    font-size: 90px;
    font-weight: 900;
    color: #ffffff52;
    margin-top: -12px;
}

.card-font-style-white {
    font-size: 72px;
    font-weight: 900;
    margin-top: -10px;
}

.background-round {
    background-color: rgba(0, 0, 0, 0.18);
    padding: 8px;
    border-radius: 50%;
}

@include media-breakpoint-down(md) {
    .heading-elements {
        // text-align: center;

        .list-inline {
            display: none;
        }

        &.visible {
            .list-inline {
                display: block;
            }

            display: block;
            margin-top: 0;
            top: 100%;
            top: 37px;
            height: auto;
            left: 0px;
            padding: 10px;
            text-align: right;
            // border: 1px solid #eee;
            z-index: 997;
            position: absolute;
            width: 100%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .heading-elements-toggle {
        display: none;
    }
}