.oto60-chat {
    #frame {
        background-color: $transparent;

        .message-input {
            background-color: $transparent;
        }

        .content {
            padding: 0 0px 200px 0;
        }
    }

    .first-step-block,
    .third-step-block,
    .second-step-block {
        display: block;
    }

    .first-step-none,
    .third-step-none,
    .second-step-none {
        display: none;
    }
}

.nav-org-block {
    position: absolute;
    bottom: 8px;
    width: 100%;

    .line {
        font-size: .875rem;
        padding: 5px 10px;

        &:first-child {
            display: none;
        }

        /* &:last-child {
            border-bottom: solid 1px #e3e3dd;

            a {
                text-transform: uppercase;
                font-weight: 700;
            }
        } */

        a {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: rgb(19 52 59);
            padding: 7px 10px;

            &:hover {
                background-color: #e8e8e4;
                border-radius: 50px;
                color: #333;
            }

            i {
                margin-top: 3px;
            }
        }
    }
}